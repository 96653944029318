import { React, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AddAboutUs from "./AddAboutUs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Base_URL } from "../../utils/serverUrl";
import { useEffect } from "react";
import axios from "axios";
import ReactReadMoreReadLess from "react-read-more-read-less";

const AboutUS = () => {
  const [modalShow, setModalShow] = useState(false);

  const [aboutus, setAboutus] = useState([]);
  const [aboutid, setAboutId] = useState("");
  // console.log("aboutus......", aboutus);

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showallaboustus = () => {
    // console.log("About is Working........");
    var config = {
      method: "get",
      url: `${Base_URL}admin/get-about`,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
        setAboutus(response.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    showallaboustus();
  }, []);

  return (
    <>
      <div className="categorylist_page">
        <div className="text-end me-2 py-2">
          <Button
            className="categorylist_btn"
            onClick={() => (setAboutId(""), setModalShow(true))}
          >
            Add
          </Button>
        </div>
        <h1>About Us</h1>
      </div>

      {/* ////////////////////////////////ABOUT US LIST//////////////////////////////////////////// */}
      <div className="categorylist_page">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Tittle</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {aboutus?.map((item) => (
            <TableBody>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <div dangerouslySetInnerHTML={{ __html: item?.discription }} />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => (setAboutId(item.id), setModalShow(true))}
                >
                  Edit
                </Button>
              </TableCell>
            </TableBody>
          ))}
        </Table>
      </div>
      <AddAboutUs
        // showallaboustus={showallaboustus}
        aboutid={aboutid}
        show={modalShow}
        onHide={() => (setAboutId(""), setModalShow(false))}
      />
    </>
  );
};

export default AboutUS;
