import React, { useState } from "react";
import "../../Assets/Css/auth.css";
import { Form, Button } from "react-bootstrap";
import Abcd1 from "../../Assets/images/abcd1.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { async } from "@firebase/util";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import { crossicon } from "../../utils/svg.file";

const Forgotpassword = () => {
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();



    const formik = useFormik({
        initialValues: {
            email: "",

        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Please enter valid email")
                .required("This is required field"),

        }),
        onSubmit: (values) => {
            setLoading(true)
            axios
                .post(`${Base_URL}sentotp`, {
                    email: values.email,

                })
                .then((result) => {


                    setMessage(result.data.message);

                    if (result.data.status === 200) {

                        navigate(
                            '/otpverification',
                            {
                                state: result.data.data.reset_id

                            }
                        )


                    } else {
                        setError("Email Not Match");
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });



    return (
        <>
            <div className="signin">
                <div className="container">
                    <div className="signin_page">
                        <div className="position-relative">
                            <span onClick={() => navigate("/")}>{crossicon}</span>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="signin_form">
                                    <span className="signin_heading">FORGOT PASSWORD </span>
                                    <p className="signin_paragraph">
                                        OTP will send on your email id.
                                    </p>
                                    <Form onSubmit={formik.handleSubmit}>

                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className="signin_form_label">
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                className="signin_form_control"
                                                type="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}


                                            />
                                        </Form.Group>
                                        {formik.touched.email && formik.errors.email && (
                                            <p style={{ color: "red" }}>{formik.errors.email}</p>
                                        )}
                                        {message || error ?
                                            <p style={{ color: "red" }}>{message || error}</p>
                                            : ""}




                                        <div className="text-center verificationbutton mb-3">
                                            <Button
                                                variant="primary"
                                                className="signin_btn_btn"
                                                type="submit"


                                            >
                                                {loading ? "" : "Email verification"}
                                            </Button>
                                            {loading ?
                                                <div className="spinner-border text-primary loading-button" role="status">
                                                    {/* <span class="sr-only">Loading...</span> */}
                                                </div>
                                                : ""}
                                        </div>

                                        <div className="signin_account_sign">
                                            <span>
                                                Don’t have an account?
                                                <a href="/signup" className="signin_tag_anchor">
                                                    Sign Up
                                                </a>
                                            </span>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <h1 className="signin_ow_head">OW</h1>
                                    <div className="signin_img">
                                        <img src={Abcd1} alt="" className="signin_image_abcd" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forgotpassword