import React, { useState } from "react";
import "../../Assets/Css/auth.css";
import { Form, Button } from "react-bootstrap";
import Abcd1 from "../../Assets/images/abcd1.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import { crossicon } from "../../utils/svg.file";

const Changepassword = () => {
    let navigate = useNavigate();
    const { state } = useLocation();
    const [error, setError] = useState("");

    const formik = useFormik({
        initialValues: {
            password: "",
            c_password: "",

        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(5, "Password must be greter then and equal to 5 charectors")
                .required("Please fill out this field"),
            c_password: Yup.string()
                .oneOf([Yup.ref("password"), null], "Please must be match")
                .required("Confirm password  is must be  required "),

        }),
        onSubmit: (values) => {
            axios
                .post(`${Base_URL}reset-password`, {
                    password: values.password,
                    code: state.code,
                    reset_id: state.reset_id

                })
                .then((result) => {
                    if (result.data.status === 200) {
                        navigate("/signin");
                    } else {
                        setError(result.data.error.password[0]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });
    return (
        <>
            <div className="signin">
                <div className="container">
                    <div className="signin_page">
                        <div className="position-relative">
                            <span onClick={() => navigate("/")}>{crossicon}</span>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="signin_form">
                                    <span className="signin_heading">CHANGE PASSWORD </span>
                                    <p className="signin_paragraph">
                                        Please enter your details.
                                    </p>
                                    <Form onSubmit={formik.handleSubmit}>

                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className="signin_form_label">
                                                Password
                                            </Form.Label>
                                            <Form.Control
                                                className="signin_form_control"
                                                type="password"
                                                name="password"
                                                placeholder="Enter password"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}


                                            />
                                        </Form.Group>
                                        {/* <p style={{ color: "red" }}>{erroruser ? erroruser : ""}</p> */}
                                        {formik.touched.password && formik.errors.password && (
                                            <p style={{ color: "red" }}>{formik.errors.password}</p>
                                        )}
                                        <Form.Group
                                            className="mb-4"
                                            controlId="formBasicc_password"
                                        >
                                            <Form.Label className="signup_form_label">
                                                Confirm Password
                                            </Form.Label>
                                            <Form.Control
                                                className="signin_form_control"
                                                name="c_password"
                                                type="password"
                                                placeholder="Confirm Password"
                                                onChange={formik.handleChange}
                                                value={formik.values.c_password}

                                            />
                                        </Form.Group>
                                        {formik.touched.c_password && formik.errors.c_password && (
                                            <p style={{ color: "red" }}>{formik.errors.c_password}</p>
                                        )}
                                        {/* {formik.touched.email && formik.errors.email && (
                                    <p style={{ color: "red" }}>{formik.errors.email}</p>
                                )} */}
                                        {error ?
                                            <p style={{ color: "red" }}>{error}</p>
                                            : ""}




                                        <div className="text-center mb-3">
                                            <Button
                                                variant="primary"
                                                className="signin_btn_btn"
                                                type="submit"

                                            >
                                                Change Password
                                            </Button>
                                        </div>

                                        <div className="signin_account_sign">

                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <h1 className="signin_ow_head">OW</h1>
                                    <div className="signin_img">
                                        <img src={Abcd1} alt="" className="signin_image_abcd" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Changepassword