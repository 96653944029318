import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";

const Addcommunity = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [handler, setHandler] = useState("");

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const getCommunity = () => {
    // e.preventDefault();
    var data = new FormData();

    var config = {
      method: "get",
      url: `${Base_URL}admin/community/${props.communityid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        
        setTitle(response.data.data.title);
        setDescription(response.data.data.about);
        setHandler(response.data.data.community_handle);
        // props.showEvent();
        // props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getCommunity();
  }, [props.communityid]);

  const Communityupdate = () => {
    // console.log("hello");
    // e.preventDefault();
    var data = new FormData();
    data.append("title", title);
    data.append("about", description);
    data.append("community_handle", handler);

    var config = {
      method: "POST",
      url: `${Base_URL}admin/update-community/${props.communityid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response, "update event");
        props.showallcommunity();
        props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Community</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="text-center">Edit Community123</h1>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Community Handle</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="community_handle"
              value={handler}
              onChange={(e) => setHandler(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            <Button onClick={() => props.onHide()}>Cancel</Button>
            <Button
              variant="primary"
              className="ms-3"
              onClick={Communityupdate}
              //   onClick={Communityupdate}
            >
              Update
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Addcommunity;
