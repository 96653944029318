import axios from "axios";
import React, { useEffect, useState } from "react";
import { Base_URL } from "../utils/serverUrl";
import Footer from "./Layouts/Footer";
import Headers from "./Layouts/Headers";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../features/preLoaderSlice'

export default function Contact() {
  const [contactdata, setContactdata] = useState([]);
  const dispatch = useDispatch()

  const fetchContact = async () => {
    dispatch(setIsLoading(true))
    var config = {
      method: "get",
      url: `${Base_URL}getcontact`,
    };

    axios(config)
      .then(function (response) {
        setContactdata(response.data.data);
        dispatch(setIsLoading(false))

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchContact()
  }, []);
  return (
    <div>
      <Headers />
      <div className="aboutimg">
        <div className="text-center">
          <h1>CONTACT US</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="contactForm">
            {contactdata.map((items) => (


              <ul className="p-0">
                <li>Address:<span>{items.address}</span></li>
                <li>Telephone:<span>{items.phone}</span></li>
                <li>Email:<span>{items.email}</span></li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
