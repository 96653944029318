import { React, useState, useRef, useMemo, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
// import upload from "../../Assets/images/upload.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";
// import { useRef } from "react";


const Addwelcomenote = (props) => {
    const [content, setContent] = useState("");
    const editor = useRef(null);
    // console.log(props.footerId, "id");

    let tokenId = localStorage.getItem("userToken");
    let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");






    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            // ["link", "image", "video"],
            ["clean"],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];

    //____________________API_______________________________//
    const disscussion = () => {
        // console.log(content, "my content");
        let err = false;
        if (content.length === 0 || content == "<p><br></p>") {
            err = true;
            Swal.fire({
                icon: "error",
                title: "Please Enter Content ",
                // text: "Required field",
            });
        }
        if (!err) {
            var data = new FormData();

            data.append("text", content);

            var config = {
                method: "post",
                url: `${Base_URL}admin/addwelcomeText`,
                headers: {
                    Authorization: "Bearer " + isLoggedIn,
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    setContent("");
                    // console.log(response, "welcome note")
                    props.showWelcomenote()
                    props.onHide()

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };


    return (
        <>
            <Modal {...props} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h1 className="text-center">Welcome Content</h1>
                    <Form>
                        <div className="row">

                        </div>


                        <div className="update-img-icon position-relative ">
                            <div className="mb-3">
                                <label
                                    for="exampleFormControlTextarea1"
                                    className="form-label edit_profile_lebal"
                                >
                                    Description
                                </label>
                                <ReactQuill
                                    style={{ background: "#ffffff", height: "200px" }}
                                    value={content}
                                    modules={modules}
                                    formats={formats}
                                    onChange={(txt) => setContent(txt)}
                                />

                            </div>


                            <div className="text-center">
                                <Button
                                    variant="primary "
                                    //   type="submit"
                                    style={{ marginTop: "10%" }}
                                    onClick={() => {
                                        props.onHide()
                                        setContent("")
                                    }
                                    }
                                >
                                    Cancle
                                </Button>
                                <Button
                                    variant="primary ms-3"
                                    //   type="submit"
                                    style={{ marginTop: "10%" }}
                                    onClick={disscussion}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                        {/* ////////////////////////////////////////////////////////////////////// */}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Addwelcomenote