import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Base_URL } from "../utils/serverUrl";
import Footer from "./Layouts/Footer";
import Headers from "./Layouts/Headers";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../features/preLoaderSlice'

export default function About() {
  const [aboutdata, setAboutdata] = useState([]);
  const dispatch = useDispatch()
  const fetchabout = async () => {
    dispatch(setIsLoading(true))
    var config = {
      method: "get",
      url: `${Base_URL}getabout`,
    };

    axios(config)
      .then(function (response) {
        setAboutdata(response.data.data);
        dispatch(setIsLoading(false))
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchabout()
  }, []);
  return (
    <div>
      <Headers />
      <div className="aboutimg">
        <div className="text-center">
          <h1>ABOUT US</h1></div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="aboutP">
              <h3>About us</h3>
              {aboutdata.map((item) => (
                <div
                  dangerouslySetInnerHTML={{ __html: item.discription }} />
              ))}
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}
