import React, { useEffect } from "react";
import Headers from "../Layouts/Headers";
import { Button, Card } from "react-bootstrap";
import { VscEdit } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Placeholder from "../../Assets/images/placeholder.png";
import Facebook from "../../Assets/images/facebook1.svg";
import Twiter from "../../Assets/images/twitter1.svg";
import Image6 from "../../Assets/images/camera.png";
import Image7 from "../../Assets/images/image 7.png";
import Image8 from "../../Assets/images/image-8.png";
import Image9 from "../../Assets/images/image 9.png";
// import Avatar from "../../Assets/images/avatar.png";
import Instagram from "../../Assets/images/instagram1.svg";
import Linkedin from "../../Assets/images/linkedin3.svg";
import Linkedin3 from "../../Assets/images/linkedin2.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Grid } from "@mui/material";
import "../Chat/Chat.css";
import axios from "axios";
import {
  Base_URL,
  CHAPTERIMG_URL,
  COMMUNITYSMALLIMG_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Userprofile = () => {
  const [profiledata, setProfiledata] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token.replace(/^"(.+)"$/, "$1");
  const params = useParams();
  const ID = params.id;
  const navigate = useNavigate();

  const showprofile = () => {
    var config = {
      method: "get",
      url: `${Base_URL}selecteduserDetails/${ID}`,
    };

    axios(config)
      .then(function (response) {
        setLoading(true);
        setProfiledata(response.data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    showprofile();
  }, []);

  const recorduserid = (id) => {
    var data = new FormData();
    data.append("id", id);

    var config = {
      method: "post",
      url: `${Base_URL}saveuserid`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        navigate(`/chat/${id}`);
        // console.log(response.data.data, "teshhagd");
        // setActdata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // console.log(profiledata);
  const date = moment(profiledata.created_at).format(" Do MMMM YYYY ");

  return (
    <div className="editprofile_backmain">
      <div>
        <Headers />
      </div>
      {loading ? (
        <div className="container">
          <div className="profile_edit_main">
            <div className="profile_name_box">
              <div className="profileedit_nameemail">
                <div className="editprofile_name">{profiledata.name} </div>
                <div className="editprofile_email">
                  @{profiledata.user_name}
                </div>
              </div>
              <div className="profileedit_rightside">
                <div className="profileedit_rightside_online">
                  <div className="profileedit_rightside_show">
                    {profiledata.onlineuser ? (
                      <>
                        <span className="online_dot"></span>
                        <span>Online</span>
                      </>
                    ) : (
                      <>
                        <span className="offline_dot"></span>
                        <span className="offline_user">Offline</span>{" "}
                      </>
                    )}
                    <div>
                      {/* <Link to={`/chat/${profiledata.id}`}> */}
                      <Button onClick={() => recorduserid(profiledata.id)}>
                        Message
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>

                  <div className="profileedit_rightside_date">
                    Joined on {date}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="editprofile_imgbox">
            {profiledata.image ?
              <img
                src={
                  profiledata.image
                    ? `${PROFILEIMG_URL}${profiledata.image}`
                    : Image6
                }
                // {Placeholder}
                alt="profile"
                className="editprofile_img"
              /> : <Avatar sx={{ bgcolor: "#16A34A", width: 160, height: 160, fontSize: "100px" }}>{profiledata?.name?.[0]?.toUpperCase()}</Avatar>}
          </div>
          <div className="editprofile_bio">
            <p className="editprofile_bio_bio">Bio</p>
            <p className="editprofile_bio_dis">{profiledata.bio}</p>
          </div>
          <div className="col-12 editprofile_link_icon">
            <div className="editprofile_libk_text">My Links : </div>
            {profiledata?.facebook ?
              <div>
                <a
                  href={`//${profiledata?.facebook}`}

                  target="_blank"
                >
                  <img
                    src={Facebook}
                    alt="link"
                    className="editprofile_libk_img"
                  // onClick={() => window.open(profiledata.facebook)}
                  />
                </a>
              </div>
              : ""}
            {profiledata?.twitter ?
              <div>
                <a
                  href={`//${profiledata?.twitter}`}

                  target="_blank"
                >
                  <img
                    src={Twiter}
                    alt="link"
                    className="editprofile_libk_img"
                  // onClick={() => window.open(profiledata?.twitter)}
                  />
                </a>
              </div>
              : ""}
            {profiledata?.instagram ?
              <div>
                <a
                  href={`//${profiledata?.instagram}`}

                  target="_blank"
                >
                  <img
                    src={Instagram}
                    alt="link"
                    className="editprofile_libk_img"
                  // onClick={() => window.open(profiledata.instagram)}
                  />
                </a>
              </div>
              : ""}
            {profiledata?.linkedin ?
              <div>
                <a
                  href={`//${profiledata?.linkedin}`}

                  target="_blank"
                >
                  <img
                    src={Linkedin}
                    alt="link"
                    className="editprofile_libk_img"
                  // onClick={() => window.open(profiledata.linkedin)}
                  />
                </a>
              </div>
              : ""}
            {profiledata?.tiktok ?
              <div>
                <a
                  href={`//${profiledata?.tiktok}`}

                  target="_blank"
                >
                  <img
                    src={Linkedin3}
                    alt="link"
                    className="editprofile_libk_img"
                  // onClick={() => window.open(profiledata.tiktok)}
                  />
                </a>
              </div>
              : ""}
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default Userprofile;
