import React from "react";
import "../../Assets/Css/styles.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Base_URL } from "../../utils/serverUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import { Button } from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import moment from "moment";
import AddEvent from "./AddEvent";

const Event = () => {
  const [show, setShow] = useState(false);
  const [allevent, setAllEvent] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [eventid, setEventId] = useState("");
  //   const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showEvent = () => {
    var data = new FormData();
    // data.append("act_id", act_id);

    var config = {
      method: "get",
      url: `${Base_URL}admin/allevent`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response.data.data);
        setAllEvent(response.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showEvent();
  }, []);

  return (
    <>
      <div className="categorylist_page">
        <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>Image</TableCell> */}

              <TableCell>Title</TableCell>

              <TableCell>Description</TableCell>
              <TableCell>Created Date</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {allevent.map((items) => (
            <TableBody>
              <TableCell>{items.id}</TableCell>
              <TableCell>{items.event_name}</TableCell>

              <TableCell>
                <ReactReadMoreReadLess
                  readMoreClassName="readMoreClassName"
                  charLimit={50}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {items.description}
                </ReactReadMoreReadLess>
              </TableCell>

              <TableCell>
                {moment(items.created_at).format(" D MMMM YYYY")}
              </TableCell>
              <TableCell>
                <div className="editbtnss">
                  <Button
                    onClick={() => (setEventId(items.id), setModalShow(true))}
                  >
                    Edit
                  </Button>
                </div>
              </TableCell>
            </TableBody>
          ))}
        </Table>
      </div>

      <AddEvent
        showEvent={showEvent}
        eventid={eventid}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Event;
