import { configureStore } from "@reduxjs/toolkit";
import messageCountReducer from "./features/counter/messageCountSlice";
import isLoadingReducer from "./features/preLoaderSlice";

export const store = configureStore({
  reducer: {
    messageCount: messageCountReducer,
    isLoadingReducer,
  },
});
