export const crossicon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M19.848 19.9556C19.3642 20.3944 18.708 20.6409 18.0238 20.6409C17.3395 20.6409 16.6833 20.3944 16.1995 19.9556L10.4998 14.0471L4.80019 19.9536C4.56133 20.1738 4.27677 20.3489 3.96292 20.4689C3.64907 20.5888 3.31214 20.6512 2.97156 20.6525C2.63099 20.6537 2.29351 20.5938 1.97859 20.4762C1.66367 20.3586 1.37755 20.1856 1.13672 19.9671C0.895895 19.7487 0.705135 19.4892 0.575442 19.2036C0.445749 18.918 0.379691 18.6119 0.381077 18.303C0.382464 17.9941 0.451269 17.6885 0.583522 17.4038C0.715775 17.1192 0.90886 16.8611 1.15164 16.6445L7.08134 10.502L1.14949 4.35555C0.90671 4.13892 0.713625 3.88083 0.581372 3.59617C0.449119 3.31151 0.380314 3.00593 0.378928 2.69703C0.377541 2.38814 0.443599 2.08205 0.573292 1.79643C0.702985 1.5108 0.893745 1.2513 1.13457 1.03287C1.3754 0.814451 1.66152 0.641436 1.97644 0.523807C2.29136 0.406179 2.62884 0.346265 2.96941 0.347523C3.30999 0.348781 3.64692 0.411185 3.96077 0.531135C4.27462 0.651086 4.55918 0.826209 4.79804 1.0464L10.4998 6.95685L16.1995 1.0464C16.4383 0.826209 16.7229 0.651086 17.0368 0.531135C17.3506 0.411185 17.6875 0.348781 18.0281 0.347523C18.3687 0.346265 18.7062 0.406179 19.0211 0.523807C19.336 0.641436 19.6221 0.814451 19.863 1.03287C20.1038 1.2513 20.2945 1.5108 20.4242 1.79643C20.5539 2.08205 20.62 2.38814 20.6186 2.69703C20.6172 3.00593 20.5484 3.31151 20.4162 3.59617C20.2839 3.88083 20.0908 4.13892 19.848 4.35555L13.9183 10.502L19.848 16.6445C20.0879 16.8618 20.2782 17.1199 20.4081 17.4039C20.5379 17.688 20.6047 17.9925 20.6047 18.3C20.6047 18.6075 20.5379 18.912 20.4081 19.1961C20.2782 19.4802 20.0879 19.7382 19.848 19.9556Z" fill="#232323" />
  </svg>
);

export const editicons = (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <path d="M7.79169 3.33398H2.83335C2.45763 3.33398 2.0973 3.48324 1.83162 3.74892C1.56594 4.01459 1.41669 4.37493 1.41669 4.75065V14.6673C1.41669 15.043 1.56594 15.4034 1.83162 15.6691C2.0973 15.9347 2.45763 16.084 2.83335 16.084H12.75C13.1257 16.084 13.4861 15.9347 13.7518 15.6691C14.0174 15.4034 14.1667 15.043 14.1667 14.6673V9.70898" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.1042 2.27018C13.386 1.98839 13.7682 1.83008 14.1667 1.83008C14.5652 1.83008 14.9474 1.98839 15.2292 2.27018C15.511 2.55197 15.6693 2.93417 15.6693 3.33268C15.6693 3.73119 15.511 4.11339 15.2292 4.39518L8.50002 11.1243L5.66669 11.8327L6.37502 8.99935L13.1042 2.27018Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export const vectordown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
    <path d="M9.70469 1.06951C10.0984 1.49547 10.0984 2.18622 9.70469 2.61218L5.713 6.93049C5.31921 7.3565 4.68081 7.3565 4.28701 6.93049L0.295324 2.6123C0.0987901 2.3995 -5.98672e-08 2.1196 -4.76872e-08 1.84096C-3.55094e-08 1.56236 0.0986442 1.28248 0.295265 1.06968C0.68906 0.643664 1.32752 0.643604 1.72131 1.06962L3.99169 3.52568L5 4.64508L6.00832 3.52566L8.2787 1.06951C8.67249 0.643496 9.3109 0.643496 9.70469 1.06951Z" fill="white" />
  </svg>
);

export const Facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
  >
    <g filter="url(#filter0_d_67_1000)">
      <circle cx="40.5" cy="36.5" r="28.5" fill="white" />
    </g>
    <path
      d="M47.3333 38.875L48.1653 33.4457H42.963V29.9225C42.963 28.4371 43.6897 26.9893 46.0197 26.9893H48.3848V22.3668C48.3848 22.3668 46.2385 22 44.1865 22C39.9022 22 37.1017 24.6004 37.1017 29.3078V33.4457H32.3394V38.875H37.1017V52H42.963V38.875H47.3333Z"
      fill="#3935FF"
    />
    <defs>
      <filter
        id="filter0_d_67_1000"
        x="0"
        y="0"
        width="81"
        height="81"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_67_1000"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_67_1000"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
  >
    <g filter="url(#filter0_d_67_1006)">
      <circle cx="40.5" cy="36.5" r="28.5" fill="white" />
    </g>
    <g clip-path="url(#clip0_67_1006)">
      <path
        d="M57.937 27.093C56.7394 27.6195 55.4708 27.9672 54.172 28.125C55.5411 27.3033 56.566 26.0133 57.057 24.494C55.7621 25.245 54.3527 25.7784 52.885 26.073C51.8981 25.0185 50.5907 24.3191 49.1657 24.0835C47.7407 23.8479 46.2777 24.0891 45.0039 24.7698C43.73 25.4505 42.7163 26.5326 42.1202 27.8482C41.5241 29.1639 41.3789 30.6394 41.707 32.046C36.254 31.791 31.42 29.171 28.187 25.213C27.5985 26.2139 27.2907 27.3549 27.296 28.516C27.2952 29.5954 27.561 30.6582 28.0697 31.6102C28.5784 32.5622 29.3143 33.3738 30.212 33.973C29.1717 33.9415 28.154 33.6614 27.244 33.156V33.235C27.2437 34.75 27.7673 36.2186 28.7259 37.3917C29.6845 38.5649 31.0193 39.3705 32.504 39.672C31.9416 39.8223 31.3622 39.8992 30.78 39.901C30.359 39.901 29.957 39.86 29.556 39.786C29.9786 41.0897 30.7971 42.2293 31.8976 43.046C32.9981 43.8628 34.3258 44.3162 35.696 44.343C33.3745 46.1611 30.5098 47.1475 27.561 47.144C27.0388 47.1461 26.5168 47.1167 25.998 47.056C29.0061 48.9835 32.5044 50.0067 36.077 50.004C48.144 50.004 54.738 40.009 54.738 31.353C54.738 31.077 54.738 30.796 54.717 30.514C56.0069 29.5895 57.1186 28.4389 57.998 27.118L57.937 27.093Z"
        fill="#438EFF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_67_1006"
        x="0"
        y="0"
        width="81"
        height="81"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_67_1006"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_67_1006"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_67_1006">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(26 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const video = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" fill="none">
    <g clip-path="url(#clip0_8_450)">
      <path
        d="M13.347 43.9897H33.3469C34.2669 43.9897 35.0135 44.7364 35.0135 45.6563V52.3229C35.0135 53.2429 34.2669 53.9895 33.3469 53.9895H13.347C12.4271 53.9895 11.6804 53.2429 11.6804 52.3229V45.6563C11.6802 44.7364 12.4269 43.9897 13.347 43.9897ZM26.6802 50.6563H30.0136C30.9352 50.6563 31.6802 49.9114 31.6802 48.9897C31.6802 48.0681 30.9352 47.3231 30.0136 47.3231H26.6802C25.7585 47.3231 25.0136 48.0681 25.0136 48.9897C25.0136 49.9114 25.7587 50.6563 26.6802 50.6563ZM16.6802 50.6563H20.0136C20.9353 50.6563 21.6802 49.9114 21.6802 48.9897C21.6802 48.0681 20.9353 47.3231 20.0136 47.3231H16.6802C15.7585 47.3231 15.0136 48.0681 15.0136 48.9897C15.0136 49.9114 15.7587 50.6563 16.6802 50.6563Z"
        fill="currentColor"
      />
      <path
        d="M3.91869 32.453L10.1886 19.9114C10.472 19.3464 11.0485 18.9897 11.6802 18.9897H48.3469C48.9786 18.9897 49.5553 19.3464 49.8385 19.9114L56.0436 32.3231H65.0135C67.7701 32.3231 70.0135 34.5665 70.0135 37.3231V37.7499L83.3468 41.7499V66.2299L70.0135 70.2299V70.6566C70.0135 73.4133 67.7701 75.6566 65.0135 75.6566H61.6801V80.6566C61.6801 81.5783 60.9352 82.3232 60.0135 82.3232H6.6802C5.75852 82.3232 5.0136 81.5783 5.0136 80.6566V75.6562C2.25696 75.6562 0.0136108 73.4129 0.0136108 70.6562V37.3231C0.0136108 34.943 1.68861 32.9548 3.91869 32.453ZM33.3469 65.6563C33.3469 64.7363 32.6003 63.9897 31.6803 63.9897C30.7604 63.9897 30.0138 64.7363 30.0138 65.6563C30.0138 66.5762 30.7604 67.3229 31.6803 67.3229C32.6003 67.3229 33.3469 66.5764 33.3469 65.6563ZM25.0136 65.6563C25.0136 64.7363 24.2669 63.9897 23.347 63.9897C22.427 63.9897 21.6804 64.7363 21.6804 65.6563C21.6804 66.5762 22.427 67.3229 23.347 67.3229C24.2669 67.3231 25.0136 66.5764 25.0136 65.6563ZM16.6802 65.6563C16.6802 64.7363 15.9335 63.9897 15.0136 63.9897C14.0937 63.9897 13.347 64.7363 13.347 65.6563C13.347 66.5762 14.0937 67.3229 15.0136 67.3229C15.9335 67.3229 16.6802 66.5764 16.6802 65.6563ZM8.34699 52.3231C8.34699 55.0797 10.5903 57.3231 13.347 57.3231H33.3469C36.1036 57.3231 38.3469 55.0797 38.3469 52.3231V45.6563C38.3469 42.8997 36.1036 40.6563 33.3469 40.6563H13.347C10.5903 40.6563 8.34699 42.8997 8.34699 45.6563V52.3231ZM43.9836 32.3231L42.317 28.9897H17.7104L16.0439 32.3231H43.9836ZM12.3169 32.3231L15.1886 26.5782C15.472 26.0132 16.0485 25.6565 16.6802 25.6565H43.3469C43.9786 25.6565 44.5553 26.0132 44.8385 26.5782L47.7102 32.3231H52.3168L47.3168 22.3231H12.7103L7.71027 32.3231H12.3169Z"
        fill="currentColor"
      />
      <path
        d="M99.0353 30.8061C99.6302 31.076 100.014 31.6694 100.014 32.3227V75.656C100.014 76.3111 99.6302 76.9027 99.0353 77.1726C98.8154 77.2744 98.5802 77.3226 98.347 77.3226C97.9521 77.3226 97.5587 77.181 97.2503 76.9109L86.6804 67.6627V40.316L97.2503 31.0676C97.7404 30.6393 98.4419 30.5344 99.0353 30.8061Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_450">
        <rect
          width="100"
          height="100"
          fill="white"
          transform="matrix(-1 0 0 1 100.014 0.656372)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const noteBook = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" fill="none">
    <g clip-path="url(#clip0_8_472)">
      <path
        d="M9.33331 101.679C4.50058 101.179 1 97.0127 1 92.1788V54.6789C1 53.0118 2.33348 51.512 4.16696 51.512H19.3339V92.5118C19.3339 97.8446 14.6672 102.178 9.33331 101.679V101.679ZM101 34.5129V94.0127C101 100.179 95.9994 105.179 89.8331 105.179L10.1666 105.178C17.1666 105.178 22.833 99.5119 22.833 92.5119V34.512C22.833 32.5124 24.5001 31.0115 26.3336 31.0115H97.3334C99.333 30.8452 101 32.5112 101 34.512L101 34.5129ZM73.6665 89.0128H36.1666V93.3458H73.6665V89.0128ZM87.4998 79.0134H36.1666V83.3464H87.4998V79.0134ZM87.4998 69.1804H36.1666V73.5134H87.4998V69.1804ZM87.4998 42.0135H36.1666V59.6798H87.4998V42.0135Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_472">
        <rect
          width="100"
          height="100"
          fill="currentColor"
          transform="translate(0.981445 0.834717)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const book = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" fill="none">
    <g clip-path="url(#clip0_8_477)">
      <path
        d="M99.5514 6.37747C98.7648 5.75694 97.7373 5.53155 96.7627 5.76371L50.797 16.7589L4.8313 5.76392C3.85687 5.53155 2.8292 5.75694 2.04263 6.37769C1.25606 6.99843 0.796997 7.94515 0.796997 8.94707V81.7999C0.796997 83.3141 1.83579 84.6306 3.30856 84.983C3.79752 85.1 49.8082 96.1073 50.0885 96.1695C50.4352 96.2466 50.8033 96.2498 50.7968 96.2498H50.797C51.0427 96.2498 51.3545 96.2088 51.5583 96.1601L98.2857 84.983C99.7584 84.6306 100.797 83.3143 100.797 81.7999V8.94707C100.797 7.94515 100.338 6.99822 99.5514 6.37747ZM47.088 70.4657C46.6676 72.2234 44.902 73.3075 43.1436 72.8875L10.2005 65.0075C8.44253 64.5868 7.35835 62.821 7.7788 61.0631C8.19925 59.3051 9.96505 58.2216 11.7232 58.6412L44.6663 66.521C46.4243 66.9417 47.5085 68.7077 47.088 70.4657ZM47.088 56.1743C46.6676 57.9321 44.902 59.0162 43.1436 58.5962L10.2005 50.7161C8.44253 50.2955 7.35835 48.5297 7.7788 46.7717C8.19925 45.0138 9.96505 43.9303 11.7232 44.3498L44.6663 52.2297C46.4243 52.6504 47.5085 54.4164 47.088 56.1743ZM47.088 41.883C46.6676 43.6407 44.902 44.7249 43.1436 44.3049L10.2005 36.4248C8.44253 36.0041 7.35835 34.2384 7.7788 32.4804C8.19925 30.7225 9.96505 29.6389 11.7232 30.0585L44.6663 37.9384C46.4243 38.3591 47.5085 40.1251 47.088 41.883ZM91.5768 65.0075L58.6337 72.8873C56.8755 73.3076 55.1097 72.2236 54.6893 70.4654C54.2688 68.7075 55.353 66.9417 57.1109 66.521L90.0541 58.6412C91.8124 58.2214 93.578 59.3049 93.9985 61.0631C94.4189 62.821 93.3347 64.5868 91.5768 65.0075ZM91.5768 50.7161L58.6337 58.596C56.8755 59.0162 55.1097 57.9323 54.6893 56.1741C54.2688 54.4162 55.353 52.6504 57.1109 52.2297L90.0541 44.3498C91.8124 43.9298 93.578 45.0136 93.9985 46.7717C94.4189 48.5297 93.3347 50.2955 91.5768 50.7161ZM91.5768 36.4248L58.6337 44.3047C56.8755 44.7249 55.1097 43.641 54.6893 41.8828C54.2688 40.1249 55.353 38.3591 57.1109 37.9384L90.0541 30.0585C91.8124 29.6383 93.578 30.7222 93.9985 32.4804C94.4189 34.2384 93.3347 36.0041 91.5768 36.4248Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_477">
        <rect
          width="100"
          height="100"
          fill="currentColor"
          transform="translate(0.796997 0.962036)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const education = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" fill="none">
    <path
      d="M49.6539 52.1461C47.3462 52.3384 45.2308 51.3769 42.9231 50.6076C30.8077 46.5692 18.5 42.5307 6.38463 38.4923C5.6154 38.1076 5.03847 37.9153 4.46155 37.3384C3.69232 36.7615 3.69232 35.9923 4.46155 35.4153C5.03847 35.0307 5.80771 34.4538 6.57694 34.2615C19.6539 30.4153 32.5385 26.5692 45.6154 22.5307C48.5 21.5692 51.5769 21.5692 54.6539 22.5307C67.5385 26.3769 80.4231 30.223 93.3077 34.0692C94.0769 34.4538 94.8462 34.6461 95.6154 35.223C96.5769 35.8 96.5769 36.7615 95.6154 37.3384C95.0385 37.723 94.4616 38.1076 93.6923 38.3C80.8077 42.5307 67.9231 46.9538 54.8462 51.1846C53.1154 51.9538 51.5769 52.1461 49.6539 52.1461Z"
      fill="currentColor"
    />
    <path
      d="M24.0769 52.723C22.1539 52.3384 21.7692 53.4922 21.7692 54.4538C21.7692 59.4538 21.7692 64.2615 21.7692 69.0692C21.7692 70.7999 22.3462 71.7615 23.5 72.9153C23.8846 73.2999 24.4615 73.6845 24.8462 74.0692C27.9231 76.1845 31.5769 77.5307 35.2308 78.4922C42.5385 80.4153 49.8462 80.7999 57.3462 79.8384C62.1539 79.2615 66.9615 77.9153 71.3846 75.9922C73.3077 75.0307 75.2308 74.0692 76.5769 72.5307C77.5385 71.5692 77.9231 70.6076 77.7308 69.2615C77.9231 64.6461 77.9231 59.8384 77.9231 55.0307C77.9231 52.3384 76 52.723 75.2308 52.9153C68.5 55.223 61.3846 57.3384 54.6539 59.6461C51.1923 60.7999 47.9231 60.7999 44.6539 59.6461L24.0769 52.723Z"
      fill="currentColor"
    />
    <path
      d="M88.3077 48.3C87.7308 48.4923 87.5385 48.6846 87.5385 49.4538C87.5385 53.8769 87.5385 58.1077 87.5385 62.5307C87.5385 62.9154 87.3462 63.4923 87.1539 63.8769C86.1923 66.1846 85.2308 68.4923 84.4616 70.8C83.6923 72.9154 84.0769 75.2231 85.6154 76.7615C86 77.3384 86.7692 77.9154 87.3462 78.3C87.9231 78.8769 88.8846 79.0692 89.6539 79.2615C91 79.4538 92.1539 78.6846 93.1154 77.9154C93.5 77.5307 94.0769 77.1461 94.4615 76.5692C95.6154 75.0308 95.8077 73.1077 95.4231 71.3769C94.8462 68.6846 93.6923 66.3769 92.5385 64.0692C92.1539 63.6846 92.1539 62.9154 92.1539 62.5307C92.1539 57.7231 92.1539 53.1077 92.1539 48.3C92.1539 47.3384 91.3846 47.5308 91 47.7231L88.3077 48.3Z"
      fill="currentColor"
    />
  </svg>
);

export const legislation = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 121" fill="none">
    <path
      d="M45.6456 51.4431L62.6172 68.4123L90.9024 40.1319L73.9344 23.1543L45.6456 51.4431Z"
      fill="currentColor"
    />
    <path
      d="M99.3876 48.6159L95.1456 44.3739L66.8628 72.6543L71.1048 76.8963C74.2152 80.0067 79.3044 80.0067 82.4184 76.8963L99.3876 59.9283C102.5 56.8191 102.5 51.7251 99.3876 48.6159Z"
      fill="currentColor"
    />
    <path
      d="M69.6924 18.9123L65.4456 14.6703C62.3364 11.5611 57.2424 11.5611 54.1296 14.6703L37.1604 31.6467C34.05 34.7559 34.05 39.8415 37.1604 42.9591L41.4024 47.2011L69.6924 18.9123Z"
      fill="currentColor"
    />
    <path
      d="M45.6456 59.9282L14.5356 91.0382C12.192 93.3818 12.192 97.1786 14.5356 99.5222C16.8756 101.866 20.676 101.866 23.0196 99.5222L54.1296 68.4122L45.6456 59.9282Z"
      fill="currentColor"
    />
    <path
      d="M108.778 100.343H52.7772V108.342H108.778V100.343Z"
      fill="currentColor"
    />
    <path
      d="M60.7776 96.3422C60.7776 91.943 64.3788 88.343 68.778 88.343H92.778C97.176 88.343 100.777 91.943 100.777 96.3422H60.7776Z"
      fill="currentColor"
    />
  </svg>
);

export const business = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 81" fill="none">
    <g clip-path="url(#clip0_8_556)">
      <path
        d="M47.1607 56.0958C47.1607 57.0995 46.3471 57.914 45.3425 57.914H36.2516C35.248 57.914 34.4335 57.0995 34.4335 56.0958V49.7085L0.796997 42.0668V72.5168C0.796997 74.5251 2.42523 76.1532 4.43334 76.1532H77.1607C79.1689 76.1532 80.797 74.5249 80.797 72.5168V42.0976L47.1607 49.713V56.0958Z"
        fill="currentColor"
      />
      <path
        d="M77.1607 21.6077H4.43334C2.42523 21.6077 0.796997 23.2358 0.796997 25.244V37.4068L34.4333 45.0477V41.5504C34.4333 40.5468 35.2478 39.7322 36.2515 39.7322H45.3424C46.3469 39.7322 47.1605 40.5468 47.1605 41.5504V45.0522L80.797 37.4367V25.244C80.797 23.2358 79.1688 21.6077 77.1607 21.6077Z"
        fill="currentColor"
      />
      <path
        d="M26.7662 13.1677C26.7662 10.4513 28.9761 8.24125 31.6916 8.24125H49.9016C52.6179 8.24125 54.828 10.4512 54.828 13.1677V17.9704H59.1034V13.1677C59.1034 8.09314 54.9761 3.96582 49.9016 3.96582H31.6915C26.6179 3.96582 22.4897 8.09314 22.4897 13.1677V17.9704H26.766L26.7662 13.1677Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_556">
        <rect
          width="80"
          height="80"
          fill="currentColor"
          transform="translate(0.796997 0.0594482)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const addButton = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    style={{ marginLeft: "10px" }}
  >
    <path
      d="M11.1561 0C5.35763 0 0.656067 4.70156 0.656067 10.5C0.656067 16.2984 5.35763 21 11.1561 21C16.9545 21 21.6561 16.2984 21.6561 10.5C21.6561 4.70156 16.9545 0 11.1561 0ZM15.6561 11.0625C15.6561 11.1656 15.5717 11.25 15.4686 11.25H11.9061V14.8125C11.9061 14.9156 11.8217 15 11.7186 15H10.5936C10.4904 15 10.4061 14.9156 10.4061 14.8125V11.25H6.84357C6.74044 11.25 6.65607 11.1656 6.65607 11.0625V9.9375C6.65607 9.83438 6.74044 9.75 6.84357 9.75H10.4061V6.1875C10.4061 6.08437 10.4904 6 10.5936 6H11.7186C11.8217 6 11.9061 6.08437 11.9061 6.1875V9.75H15.4686C15.5717 9.75 15.6561 9.83438 15.6561 9.9375V11.0625Z"
      fill="#16A34A"
    />
  </svg>
);

export const FooterFacebook = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 320 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
  </svg>
);

export const FooterTwitter = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
  </svg>
);

export const Linkedin = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 448 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
  </svg>
);

export const PinterestIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 496 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"></path>
  </svg>
);

export const profileIcon = (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4615 18.6254C19.9273 17.36 19.152 16.2105 18.1789 15.2411C17.2087 14.2689 16.0595 13.4938 14.7946 12.9585C14.7833 12.9528 14.7719 12.95 14.7606 12.9443C16.525 11.6699 17.6719 9.59404 17.6719 7.25195C17.6719 3.37207 14.5284 0.228516 10.6485 0.228516C6.76863 0.228516 3.62507 3.37207 3.62507 7.25195C3.62507 9.59404 4.77204 11.6699 6.5364 12.9472C6.52507 12.9528 6.51374 12.9557 6.50242 12.9613C5.23366 13.4966 4.09519 14.2641 3.11814 15.2439C2.14593 16.2141 1.37079 17.3634 0.83552 18.6282C0.309675 19.8665 0.0260743 21.1942 7.08178e-05 22.5393C-0.000685066 22.5695 0.00461657 22.5996 0.0156635 22.6277C0.0267104 22.6558 0.043279 22.6815 0.0643931 22.7031C0.0855071 22.7248 0.110739 22.742 0.138603 22.7537C0.166467 22.7655 0.196398 22.7715 0.226633 22.7715H1.92585C2.05046 22.7715 2.14958 22.6724 2.15241 22.5506C2.20906 20.3643 3.08698 18.3167 4.63894 16.7647C6.2447 15.159 8.37722 14.2754 10.6485 14.2754C12.9198 14.2754 15.0523 15.159 16.6581 16.7647C18.21 18.3167 19.088 20.3643 19.1446 22.5506C19.1474 22.6752 19.2466 22.7715 19.3712 22.7715H21.0704C21.1006 22.7715 21.1306 22.7655 21.1584 22.7537C21.1863 22.742 21.2115 22.7248 21.2326 22.7031C21.2537 22.6815 21.2703 22.6558 21.2814 22.6277C21.2924 22.5996 21.2977 22.5695 21.2969 22.5393C21.2686 21.1855 20.9883 19.8687 20.4615 18.6254ZM10.6485 12.123C9.34861 12.123 8.12517 11.6161 7.20476 10.6957C6.28435 9.77529 5.77741 8.55186 5.77741 7.25195C5.77741 5.95205 6.28435 4.72861 7.20476 3.8082C8.12517 2.88779 9.34861 2.38086 10.6485 2.38086C11.9484 2.38086 13.1718 2.88779 14.0923 3.8082C15.0127 4.72861 15.5196 5.95205 15.5196 7.25195C15.5196 8.55186 15.0127 9.77529 14.0923 10.6957C13.1718 11.6161 11.9484 12.123 10.6485 12.123Z"
      fill="white"
    />
  </svg>
);
export const addSign = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
  >
    <path
      d="M22.7454 13.7172V22.0505M22.7454 22.0505V30.3839M22.7454 22.0505H31.0788M22.7454 22.0505H14.4121"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M22.7454 42.8839C34.2514 42.8839 43.5788 33.5565 43.5788 22.0505C43.5788 10.5446 34.2514 1.21719 22.7454 1.21719C11.2395 1.21719 1.91211 10.5446 1.91211 22.0505C1.91211 33.5565 11.2395 42.8839 22.7454 42.8839Z"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export const journalismAdd = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
  >
    <path
      d="M10.5 0.5C4.70156 0.5 0 5.20156 0 11C0 16.7984 4.70156 21.5 10.5 21.5C16.2984 21.5 21 16.7984 21 11C21 5.20156 16.2984 0.5 10.5 0.5ZM15 11.5625C15 11.6656 14.9156 11.75 14.8125 11.75H11.25V15.3125C11.25 15.4156 11.1656 15.5 11.0625 15.5H9.9375C9.83438 15.5 9.75 15.4156 9.75 15.3125V11.75H6.1875C6.08437 11.75 6 11.6656 6 11.5625V10.4375C6 10.3344 6.08437 10.25 6.1875 10.25H9.75V6.6875C9.75 6.58437 9.83438 6.5 9.9375 6.5H11.0625C11.1656 6.5 11.25 6.58437 11.25 6.6875V10.25H14.8125C14.9156 10.25 15 10.3344 15 10.4375V11.5625Z"
      fill="currentColor"
    />
  </svg>
);

export const addCalender = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M17 2.74219H15V1.74219C15 1.47697 14.8946 1.22262 14.7071 1.03508C14.5196 0.847544 14.2652 0.742188 14 0.742188C13.7348 0.742188 13.4804 0.847544 13.2929 1.03508C13.1054 1.22262 13 1.47697 13 1.74219V2.74219H7V1.74219C7 1.47697 6.89464 1.22262 6.70711 1.03508C6.51957 0.847544 6.26522 0.742188 6 0.742188C5.73478 0.742188 5.48043 0.847544 5.29289 1.03508C5.10536 1.22262 5 1.47697 5 1.74219V2.74219H3C2.20435 2.74219 1.44129 3.05826 0.87868 3.62087C0.316071 4.18348 0 4.94654 0 5.74219V17.7422C0 18.5378 0.316071 19.3009 0.87868 19.8635C1.44129 20.4261 2.20435 20.7422 3 20.7422H17C17.7956 20.7422 18.5587 20.4261 19.1213 19.8635C19.6839 19.3009 20 18.5378 20 17.7422V5.74219C20 4.94654 19.6839 4.18348 19.1213 3.62087C18.5587 3.05826 17.7956 2.74219 17 2.74219ZM18 17.7422C18 18.0074 17.8946 18.2618 17.7071 18.4493C17.5196 18.6368 17.2652 18.7422 17 18.7422H3C2.73478 18.7422 2.48043 18.6368 2.29289 18.4493C2.10536 18.2618 2 18.0074 2 17.7422V10.7422H18V17.7422ZM18 8.74219H2V5.74219C2 5.47697 2.10536 5.22262 2.29289 5.03508C2.48043 4.84754 2.73478 4.74219 3 4.74219H5V5.74219C5 6.0074 5.10536 6.26176 5.29289 6.44929C5.48043 6.63683 5.73478 6.74219 6 6.74219C6.26522 6.74219 6.51957 6.63683 6.70711 6.44929C6.89464 6.26176 7 6.0074 7 5.74219V4.74219H13V5.74219C13 6.0074 13.1054 6.26176 13.2929 6.44929C13.4804 6.63683 13.7348 6.74219 14 6.74219C14.2652 6.74219 14.5196 6.63683 14.7071 6.44929C14.8946 6.26176 15 6.0074 15 5.74219V4.74219H17C17.2652 4.74219 17.5196 4.84754 17.7071 5.03508C17.8946 5.22262 18 5.47697 18 5.74219V8.74219Z"
      fill="currentColor"
    />
  </svg>
);

export const SideRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <line
      x1="8.36328"
      y1="2.04898"
      x2="1.41372"
      y2="8.99853"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="1.41421"
      y1="8.99805"
      x2="8.36377"
      y2="15.9476"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export const SideLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
  >
    <line
      x1="8.36426"
      y1="15.951"
      x2="15.3138"
      y2="9.00147"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <line
      x1="15.3133"
      y1="9.00195"
      x2="8.36377"
      y2="2.0524"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

export const Down = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 58 58"
    fill="none"
  >
    <rect width="56" height="56" rx="29" fill="white" />
    <path
      d="M23 26L29 32L35 26"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const Up = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 58 58"
    fill="none"
  >
    <rect width="56" height="56" rx="29" fill="white" />
    <path
      d="M35 32L29 26L23 32"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const cameraIcon = (
  <svg
    width="120"
    height="90"
    viewBox="0 0 120 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.5 75C112.5 76.9891 111.71 78.8968 110.303 80.3033C108.897 81.7098 106.989 82.5 105 82.5H15C13.0109 82.5 11.1032 81.7098 9.6967 80.3033C8.29018 78.8968 7.5 76.9891 7.5 75V30C7.5 28.0109 8.29018 26.1032 9.6967 24.6967C11.1032 23.2902 13.0109 22.5 15 22.5H23.79C29.7543 22.4967 35.4733 20.1255 39.69 15.9075L45.915 9.6975C47.3175 8.29455 49.2187 7.5044 51.2025 7.5H68.7825C70.7715 7.50042 72.6788 8.29088 74.085 9.6975L80.295 15.9075C82.3847 17.9979 84.8658 19.656 87.5966 20.7872C90.3274 21.9183 93.2542 22.5004 96.21 22.5H105C106.989 22.5 108.897 23.2902 110.303 24.6967C111.71 26.1032 112.5 28.0109 112.5 30V75ZM15 15C11.0218 15 7.20644 16.5804 4.3934 19.3934C1.58035 22.2064 0 26.0218 0 30L0 75C0 78.9782 1.58035 82.7936 4.3934 85.6066C7.20644 88.4196 11.0218 90 15 90H105C108.978 90 112.794 88.4196 115.607 85.6066C118.42 82.7936 120 78.9782 120 75V30C120 26.0218 118.42 22.2064 115.607 19.3934C112.794 16.5804 108.978 15 105 15H96.21C92.2321 14.9992 88.4174 13.4182 85.605 10.605L79.395 4.395C76.5826 1.58176 72.7679 0.000849561 68.79 0H51.21C47.2321 0.000849561 43.4174 1.58176 40.605 4.395L34.395 10.605C31.5826 13.4182 27.7679 14.9992 23.79 15H15Z"
      fill="white"
    />
    <path
      d="M60 67.5C55.0272 67.5 50.2581 65.5246 46.7417 62.0083C43.2254 58.4919 41.25 53.7228 41.25 48.75C41.25 43.7772 43.2254 39.0081 46.7417 35.4917C50.2581 31.9754 55.0272 30 60 30C64.9728 30 69.7419 31.9754 73.2583 35.4917C76.7746 39.0081 78.75 43.7772 78.75 48.75C78.75 53.7228 76.7746 58.4919 73.2583 62.0083C69.7419 65.5246 64.9728 67.5 60 67.5ZM60 75C66.9619 75 73.6387 72.2344 78.5616 67.3116C83.4844 62.3887 86.25 55.7119 86.25 48.75C86.25 41.7881 83.4844 35.1113 78.5616 30.1884C73.6387 25.2656 66.9619 22.5 60 22.5C53.0381 22.5 46.3613 25.2656 41.4384 30.1884C36.5156 35.1113 33.75 41.7881 33.75 48.75C33.75 55.7119 36.5156 62.3887 41.4384 67.3116C46.3613 72.2344 53.0381 75 60 75ZM22.5 33.75C22.5 34.7446 22.1049 35.6984 21.4016 36.4016C20.6984 37.1049 19.7446 37.5 18.75 37.5C17.7554 37.5 16.8016 37.1049 16.0984 36.4016C15.3951 35.6984 15 34.7446 15 33.75C15 32.7554 15.3951 31.8016 16.0984 31.0984C16.8016 30.3951 17.7554 30 18.75 30C19.7446 30 20.6984 30.3951 21.4016 31.0984C22.1049 31.8016 22.5 32.7554 22.5 33.75Z"
      fill="white"
    />
  </svg>
);

export const editIcon = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.79169 3.33398H2.83335C2.45763 3.33398 2.0973 3.48324 1.83162 3.74892C1.56594 4.01459 1.41669 4.37493 1.41669 4.75065V14.6673C1.41669 15.043 1.56594 15.4034 1.83162 15.6691C2.0973 15.9347 2.45763 16.084 2.83335 16.084H12.75C13.1257 16.084 13.4861 15.9347 13.7518 15.6691C14.0174 15.4034 14.1667 15.043 14.1667 14.6673V9.70898"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1042 2.27018C13.386 1.98839 13.7682 1.83008 14.1667 1.83008C14.5652 1.83008 14.9474 1.98839 15.2292 2.27018C15.511 2.55197 15.6693 2.93417 15.6693 3.33268C15.6693 3.73119 15.511 4.11339 15.2292 4.39518L8.50002 11.1243L5.66669 11.8327L6.37502 8.99935L13.1042 2.27018Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const plusIcon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 13.666V21.9993M22 21.9993V30.3327M22 21.9993H30.3333M22 21.9993H13.6667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M22 42.8327C33.5059 42.8327 42.8333 33.5053 42.8333 21.9994C42.8333 10.4934 33.5059 1.16602 22 1.16602C10.4941 1.16602 1.16666 10.4934 1.16666 21.9994C1.16666 33.5053 10.4941 42.8327 22 42.8327Z"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export const uploadIcon = (
  <svg
    width="81"
    height="60"
    viewBox="0 0 81 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.0092 59.1046H50.0791H46.5973H45.8453V41.7609H51.5176C52.9561 41.7609 53.8061 40.1263 52.9561 38.9493L41.9221 23.6816C41.2192 22.7008 39.7644 22.7008 39.0615 23.6816L28.0276 38.9493C27.1776 40.1263 28.0112 41.7609 29.4661 41.7609H35.1383V59.1046H34.3864H30.9046H15.9148C7.33286 58.6305 0.5 50.6044 0.5 41.908C0.5 35.9088 3.75296 30.6779 8.57519 27.85C8.13384 26.6567 7.90499 25.3817 7.90499 24.0412C7.90499 17.9113 12.858 12.9583 18.9879 12.9583C20.312 12.9583 21.587 13.1871 22.7803 13.6285C26.3275 6.10908 33.9777 0.894531 42.8703 0.894531C54.3782 0.910878 63.8592 9.72167 64.9381 20.9517C73.7816 22.472 80.5 30.6616 80.5 39.9301C80.5 49.8361 72.7844 58.418 63.0092 59.1046Z"
      fill="#D8DEE9"
    />
  </svg>
);

export const threeDots = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
  >
    <circle cx="27.5" cy="17.5" r="2.5" fill="#5E5E5E" />
    <circle cx="27.5" cy="27.5" r="2.5" fill="#5E5E5E" />
    <circle cx="27.5" cy="37.5" r="2.5" fill="#5E5E5E" />
  </svg>
);

export const cloudIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="60"
    viewBox="0 0 81 60"
    fill="none"
  >
    <path
      d="M63.0092 59.1046H50.0791H46.5973H45.8453V41.7609H51.5176C52.9561 41.7609 53.8061 40.1263 52.9561 38.9493L41.9221 23.6816C41.2192 22.7008 39.7644 22.7008 39.0615 23.6816L28.0276 38.9493C27.1776 40.1263 28.0112 41.7609 29.4661 41.7609H35.1383V59.1046H34.3864H30.9046H15.9148C7.33286 58.6305 0.5 50.6044 0.5 41.908C0.5 35.9088 3.75296 30.6779 8.57519 27.85C8.13384 26.6567 7.90499 25.3817 7.90499 24.0412C7.90499 17.9113 12.858 12.9583 18.9879 12.9583C20.312 12.9583 21.587 13.1871 22.7803 13.6285C26.3275 6.10908 33.9777 0.894531 42.8703 0.894531C54.3782 0.910878 63.8592 9.72167 64.9381 20.9517C73.7816 22.472 80.5 30.6616 80.5 39.9301C80.5 49.8361 72.7844 58.418 63.0092 59.1046Z"
      fill="#D8DEE9"
    />
  </svg>
);

export const threeDot = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path></svg>


export const deleteIcon = <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M292.7 840h438.6l24.2-512h-487z"></path><path d="M864 256H736v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zm-504-72h304v72H360v-72zm371.3 656H292.7l-24.2-512h487l-24.2 512z"></path></svg>
