import React from "react";
import "../../Assets/Css/styles.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { Base_URL } from "../../utils/serverUrl";
import { useState } from "react";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import { Button } from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import moment from "moment";
import Addcommunity from "./Addcommunity";
import { Link } from "react-router-dom";
// import ToggleSwitch from "./components/ToggleSwitch";

const AdminCommunities = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [allcommunity, setAllcommunity] = useState([]);
  const [communityTypes, setCommunityTypes] = useState([]);
  const [communityid, setCommunityId] = useState("");

  const handleCommunityType = (data) => {
    let checkExist = communityTypes.some((el) => el.value === data.value);
    if (checkExist) {
      // edit
      let updateItemIndex = communityTypes.findIndex(
        (el) => el.value === data.value
      );
      communityTypes[updateItemIndex].type = data.type;
    } else {
      setCommunityTypes([...communityTypes, data]);
    }
  };

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  const showallcommunity = () => {
    var config = {
      method: "get",
      url: `${Base_URL}admin/alladmincommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "testing");
        setAllcommunity(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleChange = () => {
    return true;
  };
  useEffect(() => {
    showallcommunity();
  }, []);
  /////////////////////////manage Community///////////////////////////////

  const managecommunity = (id, type) => {
    var data = new FormData();
    data.append("id", id);
    data.append("status", type);
    var config = {
      method: "post",

      url: `${Base_URL}admin/managecommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        showallcommunity();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const managecommunitystatus = (id, type) => {
    var data = new FormData();
    data.append("id", id);
    data.append("status", type);
    var config = {
      method: "post",

      url: `${Base_URL}admin/updatecommunitystatus`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        showallcommunity();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /////////////////////////manage Community///////////////////////////////
  return (
    <>
      <div className="categorylist_page">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>User</TableCell>
              {/* <TableCell>Category</TableCell> */}
              <TableCell>Title</TableCell>
              <TableCell>Community Handle</TableCell>
              <TableCell>Description</TableCell>

              <TableCell>Featured</TableCell>
              <TableCell>Top</TableCell>
              <TableCell>Latest</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {allcommunity.map((item, idx) => (
            <TableBody>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>
                {moment(item.created_at).format(" D MMMM YYYY")}
              </TableCell>
              <TableCell>{item.user_name}</TableCell>
              {/* <TableCell>{item.category_name}</TableCell> */}
              <Link
                to={`/community-details/${item.id}`}
              >
                <TableCell>{item.community_title}</TableCell>
              </Link>
              <TableCell>{item.community_handle}</TableCell>
              <TableCell>
                <ReactReadMoreReadLess
                  readMoreClassName="readMoreClassName"
                  charLimit={50}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {item.description}
                </ReactReadMoreReadLess>
              </TableCell>


              <TableCell>
                <Switch
                  checked={item.status === 1}
                  onClick={() => {
                    handleCommunityType({ type: 1, value: item.id });
                    managecommunity(item.id, 1);
                  }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  checked={item.status === 2}
                  onClick={() => {
                    handleCommunityType({ type: 2, value: item.id });
                    managecommunity(item.id, 2);
                  }}
                />
              </TableCell>
              <TableCell>
                <Switch
                  checked={item.status === 3}
                  onClick={() => {
                    handleCommunityType({ type: 3, value: item.id });
                    managecommunity(item.id, 3);
                  }}
                />
              </TableCell>
              {item.disable_status === 0 ? (
                <TableCell>
                  <div className="editbtnss">
                    <Button
                      onClick={() => (
                        setCommunityId(item.id), setModalShow(true)
                      )}
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn btn-success"
                      onClick={() => managecommunitystatus(item.id, 1)}
                    >
                      Enable
                    </Button>
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="editbtnss">
                    <Button
                      onClick={() => (
                        setCommunityId(item.id), setModalShow(true)
                      )}
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn btn-danger"
                      onClick={() => managecommunitystatus(item.id, 0)}
                    >
                      Disable
                    </Button>
                  </div>
                </TableCell>
              )}
            </TableBody>
          ))}
        </Table>
      </div>

      <Addcommunity
        showallcommunity={showallcommunity}
        communityid={communityid}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default AdminCommunities;
