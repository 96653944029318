import React, { useEffect } from "react";
import closes from "../../Assets/images/closes.png";
import myavtar from "../../Assets/images/myavtar.png";
import Emoji from "../../Assets/images/Emoji.png";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Chat.css";
import { BsSearch } from "react-icons/bs";
import { BiSmile } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import Headers from "../Layouts/Headers";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Base_URL,
  PROFILEIMG_URL,
  CHATIMG_URL,
} from "../../utils/serverUrl";
import axios from "axios";
import socketIo from "socket.io-client";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setMessageCount } from "../../features/counter/messageCountSlice";
import { useRef } from "react";
import ScrollToBottom from 'react-scroll-to-bottom';
// import { setIsLoading } from '../../features/preLoaderSlice'

const Chat = () => {
  const [show, setShow] = useState(false);
  const chatRef = useRef()

  // redux dispatcher
  const dispatch = useDispatch();

  // state management
  const [emoji, setEmoji] = useState(false);
  const [close, setClose] = useState(false);
  const [emojidata, setEmojidata] = useState();
  const [users, setUsersData] = useState([]);
  const [chatdeatils, setUserchatDetails] = useState([]);
  const [usersentdetails, setUsersentDetails] = useState();
  const [selectuserprofile, setSelectUserProfile] = useState();
  const [chatmessages, setChatmessage] = useState();
  const [searchItem, setSearchedItem] = useState("");
  // const [loading, setLoading] = React.useState(false);
  const [userSelectedId, setUserSelectedId] = useState("");
  const [previewimages, setPreviewimages] = useState("");
  let tokenId = localStorage.getItem("userToken");
  let userId = localStorage.getItem("userId");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  const [updateimgprofile, setUpdateImgprofile] = useState();

  const { id } = useParams();



  const socket = socketIo("https://socket.digimonk.website:1658", {
    transports: ["websocket"],
  });

  const clickEmoji = async () => {
    setEmoji(!emoji);
  };
  const alluser = () => {
    // dispatch(setIsLoading(true))
    var config = {
      method: "get",
      url: `${Base_URL}allusers`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data,'uiserldt')
        setUsersData(response.data.data);

        // setLoading(true);
        // dispatch(setIsLoading(false))
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const searchUsers = (value) => {
    setSearchedItem(value);
  };


  const historychat = (id) => {

    // alluser()
    const found = users.find((obj) => {
      return Number(obj.id) === Number(id);
    });
    setSelectUserProfile(found);
    var data = new FormData();
    data.append("sender", id);
    data.append("receiver", userId);

    var config = {
      method: "post",
      url: `${Base_URL}chathistory`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        setUserchatDetails(response.data.data);
        setUsersentDetails(response.data.user);
        setUpdateImgprofile("");
        alluser();
        chatcount();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const fileupload = (e) => {
    var reader = new FileReader();

    reader.onloadend = function () {


      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setUpdateImgprofile(reader.result);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }

      var file = e.target.files[0];
      getBase64(file);
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const chatmessage = () => {
    const socketPayload = {
      receiver_id: `${selectuserprofile?.id}`,
      message: chatmessages,
      images: updateimgprofile,
      sender_id: userId,
      token: isLoggedIn,
      status:updateimgprofile?1:0
    };
    setUserchatDetails(prevState=> [...prevState, socketPayload]);
    socket.emit("message", socketPayload);
    setChatmessage("");
    setUpdateImgprofile("");
  };
  useEffect(() => {
    alluser();
  }, []);

  const handlepriverwimageclose = () => {
    setShow(false);
    setUpdateImgprofile("");
  };
  const handleClose = () => setShow(false);
  const handleShow = (images) => {
    setPreviewimages(images);
    setShow(true);
  };
  const chatcount = () => {
    let data = new FormData();
    let config = {
      method: "get",
      url: `${Base_URL}chatcount`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "count")
        dispatch(setMessageCount(response?.data.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    socket.on("connect", () => {
      console.log("connect", socket)
    });
   
    socket.on("sendMessage", (data) => {
      setUserchatDetails((prev) => [...prev, data.data]);
      // alluser();
      chatcount();


    });
  
    socket.on("connect_error", (err) => {

      socket.connect();
    });
  }, []);

  useEffect(()=>{
    socket.emit("addUser", userId);
  },[socket])

  return (
    <>
      <Headers />
      {/* {loading ? ( */}
      <div className="chat_main">
        <div className="container main_main">
          <div className=" search_messages">
            <span className="messages_text">Messages</span>
          </div>
          <div className="row search-area">
            <div className="col-lg-4 ">
              <div class="input-group input-group-lg">
                <span class="input-group-text" id="inputGroup-sizing-lg">
                  <BsSearch className="chat_icon_size" />
                </span>
                <input
                  type="text"
                  class="form-control chat-input"
                  placeholder="search..."
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  onChange={(e) => searchUsers(e.target.value)}
                />
              </div>

              <div className="chat-user-community-loop">
                <div className="chat1">
                  <>
                    {users
                      .filter((entry) => entry.name?.includes(searchItem))
                      .filter((el) => Number(el.id) !== Number(userId))
                      ?.map((item) => (
                        <>
                          {selectuserprofile?.id == item.id ? (

                            <>

                              <div
                                className="chat_user_community_card_loop active"
                                onClick={() => {
                                  historychat(item.id);
                                  setUserSelectedId(item.id);
                                }}
                              >
                                <div>
                                  <img
                                    src={
                                      item.image
                                        ? `${PROFILEIMG_URL}${item.image}`
                                        : myavtar
                                    }
                                    // src={
                                    //   communitydata.banner_image
                                    //     ? `${COMMUNITYBIGIMG_URL}${communitydata.banner_image}`
                                    //     : Imagedetail
                                    // }
                                    alt="chat"
                                    className="chat_image_card"
                                  />
                                </div>
                                <div className="chat_user_community_text_card">
                                  <p className="chat_user_community_text_name_card">
                                    {item.name}
                                    <span>-</span>
                                    <span className="chat_user_community_text_mail_card">
                                      {item.user_name}
                                    </span>
                                  </p>
                                  {/* <p className="chat_user_community_text_discreption">
                                      {item.description}
                                    </p> */}
                                </div>
                                {item.chat_count > 0 ? (
                                  <>
                                    <p className="chat_user_community_text_time_card">
                                      <span className="count_bg" >{item.chat_count}</span>
                                    </p>
                                  </>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <div
                              className="chat_user_community_card_loop"
                              onClick={() => {
                                historychat(item.id);
                                setUserSelectedId(item.id);
                              }}
                            >
                              <div>
                                <img
                                  src={
                                    item.image
                                      ? `${PROFILEIMG_URL}${item.image}`
                                      : myavtar
                                  }
                                  // src={
                                  //   communitydata.banner_image
                                  //     ? `${COMMUNITYBIGIMG_URL}${communitydata.banner_image}`
                                  //     : Imagedetail
                                  // }
                                  alt="chat"
                                  className="chat_image_card"
                                />
                              </div>
                              <div className="chat_user_community_text_card">
                                <p className="chat_user_community_text_name_card">
                                  {item.name}
                                  <span>-</span>
                                  <span className="chat_user_community_text_mail_card">
                                    {item.user_name}
                                  </span>
                                </p>
                                {/* <p className="chat_user_community_text_discreption">
                                    {item.description}
                                  </p> */}
                              </div>
                              {item.chat_count > 0 ? (
                                <>
                                  <p className="chat_user_community_text_time_card ">
                                    <span className="count_bg">{item.chat_count}</span>
                                  </p>
                                </>
                              ) : null}
                            </div>
                          )}
                        </>
                      ))}
                  </>
                </div>
              </div>
            </div>
            {/* {selectuserprofile ? (
              <> */}
            <div className="col-lg-8">
              {selectuserprofile ? (
                <>
                  <div className="chat_user_community">
                    <div>
                      <img
                        src={
                          selectuserprofile?.image
                            ? `${PROFILEIMG_URL}${selectuserprofile?.image}`
                            : myavtar
                        }
                        // {
                        //   item.image
                        //     ? `${PROFILEIMG_URL}${item.image}`
                        //     : Avatar
                        // }
                        alt="chat"
                        className="chat_image"
                      />
                    </div>

                    <div className="chat_user_community_text">

                      <Link to={`/userprofile/${selectuserprofile.id}`}  >
                        <p className="chat_user_community_text_name">
                          {selectuserprofile?.name}
                        </p>
                        <p className="chat_user_community_text_mail">
                          @{selectuserprofile?.user_name}
                        </p>
                      </Link>
                    </div>
                  </div>
                </>
              ) : ""}

              <div className="chat-user-community-loop">
                {selectuserprofile ? (
                  <>
                    <ScrollToBottom className="chat_room">
                      {chatdeatils.map((item, index) => {
                      
                        let isSameDay =
                          (moment(chatdeatils?.[index - 1]?.created_at).format(
                            "dddd"
                          ) || 0) !==
                          moment(chatdeatils?.[index]?.created_at).format(
                            "dddd"
                          );
                        if (index == 0) {
                          isSameDay = true
                        }


                        return (
                          <>
                            {isSameDay ? (
                              <h1 className="chat_room_date">
                                <span className="chat_date">
                                  {moment(item?.created_at).format("dddd")}
                                </span>
                              </h1>
                            ) : null}
                            <div >
                              {selectuserprofile?.id == item?.sender_id ? (
                                <>

                                  <div className="chat_user_community_chatbox">
                                    <div>

                                      <img
                                        src={
                                          selectuserprofile?.image
                                            ? `${PROFILEIMG_URL}${selectuserprofile?.image}`
                                            : myavtar
                                        }
                                        alt="chat"
                                        className="chat_image"
                                      />
                                    </div>

                                    <div className="chat_user_community_text">
                                      <Link
                                        to={`/userprofile/${selectuserprofile.id}`}
                                      >
                                        <p className="chat_user_community_text_name_chatbox">
                                          {selectuserprofile?.name}
                                        </p>
                                      </Link>
                                      {item?.status == 0 ? (
                                        <p className="chat_user_community_text_mail_chatbox_right">

                                          {item?.message}
                                        </p>
                                      ) : (
                                        <img
                                          onClick={() =>
                                            handleShow(item?.message)
                                          }
                                          src={`${CHATIMG_URL}${item?.message}`}
                                          alt="chat"
                                          className="chat_image"
                                        />
                                      )}
                                      <p>
                                        {moment(item?.created_at).format(
                                          "LT"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </>
                              ) : selectuserprofile?.id == item?.receiver_id ? (

                                <>

                                  <div className="chat_user_community_chatbox_right">
                                    <div className="chat_user_community_text_right">
                                      <Link to="/profile">
                                        <p className="chat_user_community_text_name_chatbox_right">
                                          {usersentdetails?.name}
                                        </p>
                                      </Link>
                                      {item?.status == 0 ? (
                                        <p className="chat_user_community_text_mail_chatbox_right">

                                          {item.message}
                                        </p>


                                      ) : (

                                        < img
                                          onClick={() =>
                                            handleShow(item?.message)

                                          }
                                          src={item?.images ? item?.images : `${CHATIMG_URL}${item?.message}`}
                                          alt="chat"
                                          className="chat_image"
                                        />

                                      )}
                                      <p>
                                        {moment(item?.created_at).format(
                                          "LT"
                                        )}
                                      </p>
                                    </div>
                                    <div>

                                      <img
                                        src={
                                          usersentdetails?.image
                                            ? `${PROFILEIMG_URL}${usersentdetails?.image}`
                                            : myavtar
                                        }
                                        alt="chat"
                                        className="chat_image_right"
                                      />

                                    </div>
                                  </div>
                                </>
                              ) : ""}
                            </div>
                          </>
                        );
                      })}

                      <div className="chatbox-box">
                        {updateimgprofile ? (
                          <div className="sendChats">
                            <img
                              src={closes}
                              className="closeChat"
                              onClick={() => setUpdateImgprofile(false)}
                              alt=""
                            />
                            <img src={updateimgprofile} className="w-100" alt="" />
                          </div>
                        ) : null}
                        <div className="chatbox-box_textarea">
                          {emoji ? (
                            <>
                              <div>
                                <EmojiPicker
                                  onEmojiClick={(data) =>
                                    setEmojidata(data.emoji)
                                  }
                                />
                              </div>
                            </>
                          ) : null}

                          <input
                            // defaultValue={emojidata}
                            type="text"
                            placeholder="Write a message..."
                            className="chatbox-box_input"
                            value={chatmessages}
                            onChange={(e) => setChatmessage(e.target.value)}
                            onKeyDown={(e) =>
                              e.key === "Enter" && chatmessage()
                            }
                          />
                          {/* <BiSmile className="chat_smile" onClick={clickEmoji} /> */}
                          <GrAttachment className="chat_Attachment" />
                          <div className="position-relative inputFile">
                            <input
                              onChange={fileupload}
                              accept="image/*"
                              type="file"
                            />
                          </div>

                          <FiSend
                            className="chat_Send"
                            onClick={()=>chatmessage()}
                          />
                        </div>
                      </div>
                    </ScrollToBottom>
                  </>
                ) : null}
              </div>
            </div>
            {/* </>
              ) : null} */}
          </div>
        </div>
      </div>
      {/* ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 93px)",
          }}
        >
          <CircularProgress />
        </Box>
      )} */}

      {/* preview modal start*/}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 croseds"></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src={`${CHATIMG_URL}${previewimages}`}
              className="w-100 imgChats"
              alt=""
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* preview modal end*/}
    </>
  );
};

export default Chat;
