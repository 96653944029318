import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  let userId = localStorage.getItem("userId");
  // console.log(userId, "check user id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
    if (Number(userId) !== 13) {
      navigate("/");
    }
  }, [isLoggedIn, navigate, userId]);

  return <>{children}</>;
};

export default ProtectedRoutes;
