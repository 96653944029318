import { React, useState } from "react";
import Form from "react-bootstrap/Form";
import "../../Assets/Css/styles.css";
import Modal from "react-bootstrap/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import moment from "moment";
import EditAct from "./EditAct";
import { Link } from "react-router-dom";

const Acts = () => {
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [actid, setActId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [allacts, setAllacts] = useState([]);

  let token = localStorage.getItem("userToken");

  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  const showallact = () => {
    var config = {
      method: "get",
      url: `${Base_URL}admin/actlist`,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "hhhhhhhhhhhhhhhh")

        setAllacts(response.data.list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const manageactstatus = (id, type) => {
    var data = new FormData();
    data.append("id", id);
    data.append("status", type);
    var config = {
      method: "post",

      url: `${Base_URL}admin/updateactstatus`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        showallact();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showallact();
  }, []);

  return (
    <>
      <div className="categorylist_page">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Community Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Act Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {allacts.map((item) => {
            // console.log(">>>>", item.description)
            return <TableBody>
              <TableCell>{item.id}</TableCell>
              <Link
                to={`/community-details/${item.com_id}`}
              >
                <TableCell>
                  {item.community_id}
                </TableCell>
              </Link>
              <TableCell>{item.user_id}</TableCell>
              <TableCell>{item.act_name}</TableCell>
              <TableCell>
                {item?.description ? <ReactReadMoreReadLess
                  readMoreClassName="readMoreClassName"
                  charLimit={50}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {item?.description}
                </ReactReadMoreReadLess> : ""}
              </TableCell>
              {/* <TableCell>{item.image}</TableCell> */}
              <TableCell>
                {moment(item.created_at).format(" D MMMM YYYY")}
              </TableCell>

              {item.disable_status === 0 ? (
                <TableCell>
                  <div className="editbtnss">
                    <Button
                      onClick={() => (setActId(item.id), setModalShow(true))}
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn btn-success"
                      onClick={() => manageactstatus(item.id, 1)}
                    >
                      Enable
                    </Button>
                  </div>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="editbtnss">
                    <Button
                      onClick={() => (setActId(item.id), setModalShow(true))}
                    >
                      Edit
                    </Button>
                    <Button
                      className="btn btn-danger"
                      onClick={() => manageactstatus(item.id, 0)}
                    >
                      Disable
                    </Button>
                  </div>
                </TableCell>
              )}
            </TableBody>
          })}{" "}
        </Table>
      </div>

      <EditAct
        showallact={showallact}
        actid={actid}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Acts;
