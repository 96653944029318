import { React, useState } from "react";
import "../Assets/Css/styles.css";
import { Form, Modal } from "react-bootstrap";
import axios from "axios";
import { Base_URL } from "../utils/serverUrl";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../utils/svg.file";
import Swal from "sweetalert2";

const UploadFile = styled(Button)({
  background: "rgba(216, 222, 233, 0.2)",
  border: " 2px dashed #C4C4C4",
  borderRadius: "5px",
  color: "#465BBA",
  padding: 15,
  textTransform: "capitalize",
});

const AddCategory = (props) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();

  // const fetchData =() =>{
  // const [cardArray, setcardArray] = useState([]);
  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const fetchData = () => {
    let err = false;
    if (title === "" || title === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Title ",
        text: "Required field",
      });
    }

    if (description === "" || description === undefined) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Description ",
        text: "Required field",
      });
    }
    if (!err) {
      var data = new FormData();
      data.append("name", title);
      // data.append("file", image);
      data.append("discription", description);

      var config = {
        method: "post",

        url: `${Base_URL}admin/category`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + isLoggedIn,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {

          props.onHide();
          props.category();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">Add Category</h1>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <div className="text-center py-3">
              <Button className="addcategory_btn" onClick={fetchData}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCategory;
