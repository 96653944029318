import { React, useState } from "react";
import "../Assets/Css/styles.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Form } from "react-bootstrap";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";
import { Switch } from "@mui/material";
import { Base_URL } from "../utils/serverUrl";
import axios from "axios";
import { useEffect } from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Swal from "sweetalert2";

const CategoryList = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSwitch1, setIsSwitch1] = useState(false);
  const [isSwitch2, setIsSwitch2] = useState(false);
  const [checked, setChecked] = useState(false);
  const [ischecked, setCheckedswitch] = useState(false);
  const [unchecked, setCheckedswitch1] = useState(false);
  const [allcategory, setAllcategory] = useState([]);
  const [editdata, setEditdata] = useState([]);
  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const toggleSwitch = () => {
    setIsEnabled((previousState) => !previousState);
  };
  const toggleSwitchCheck = () => {
    setIsSwitch1((previousState) => !previousState);
  };
  const toggleSwitchBox = () => {
    setIsSwitch2((previousState) => !previousState);
  };

  // const deletecategory = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete this request!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Delete",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       var data = new FormData();
  //       // data.append("id", id);
  //       var config = {
  //         method: "delete",
  //         url: `${Base_URL}admin/delete-category/${id}`,
  //         headers: {
  //           Authorization: `Bearer ${isLoggedIn}`,
  //         },
  //         data: data,
  //       };

  //       axios(config)
  //         .then(function (response) {
  //           showallcategory();
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //       Swal.fire("You have Delete this Request.");
  //     }
  //   });
  // };
  const handeledit = (id) => {
    setEditModalShow(true);
    setEditdata(id);
  };



  const showallcategory = () => {
    var config = {
      method: "get",
      url: `${Base_URL}allcategory`,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "category")
        setAllcategory(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showallcategory();
  }, []);

  const DeleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var data = new FormData();
        var config = {
          method: "delete",

          url: `${Base_URL}admin/delete-category/${id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            // console.log(response, "deleteCatogory");
            showallcategory();

          })
          .catch(function (error) {
            console.log(error);
          });
        Swal.fire("You have Delete this Category.");
      }
    });
  };




  return (
    <>
      <div className="categorylist_page">
        <div className="text-end me-2 py-2">
          <Button
            className="categorylist_btn"
            onClick={() => setModalShow(true)}
          >
            Add Category
          </Button>
        </div>

        <Table sx={{ minWidth: 660 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Category ID</TableCell>
              <TableCell>Title</TableCell>

              <TableCell>Category</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {allcategory.map((item, idx) => (
            <TableBody>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{item.name}</TableCell>

              <TableCell>
                <ReactReadMoreReadLess
                  charLimit={100}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                >
                  {item.discription}
                </ReactReadMoreReadLess>
              </TableCell>
              <TableCell>
                <div className="d-flex">
                  <Button
                    className="categorylist_btn"
                    onClick={() => {
                      handeledit(item.id);
                    }}
                  >
                    Edit
                  </Button>
                  {item.community == 0 ?
                    <Button
                      className="categorylistdelete_btn ms-2"
                      onClick={() => {
                        DeleteCategory(item.id)
                      }}
                    >
                      Delete
                    </Button>
                    : ""}
                </div>
                {/* <Button
                  className="categorylist_btn"
                  onClick={() => {
                    handeledit(item.id);
                  }}
                >
                  Edit Category
                </Button> */}
              </TableCell>
            </TableBody>
          ))}
        </Table>
      </div>
      <AddCategory
        show={modalShow}
        onHide={() => setModalShow(false)}
        category={() => showallcategory()}
      />
      <EditCategory
        show={editmodalShow}
        editdata={editdata}
        showallcategory={showallcategory}
        onHide={() => setEditModalShow(false)}
      />
    </>
  );
};

export default CategoryList;
