import React, { Fragment } from "react";
import "../Assets/Css/admin.css";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import Rectangle1511 from "../Assets/images/myavtar.png";

const Admin = ({ children }) => {
  let navigate = useNavigate();
  // console.log({ children });
  const [onClick, setOnClick] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const closeButton = () => setIsOpen(false);

  const closeSubmenu = (key) => setOnClick(key === onClick ? null : key);
  const menuItem = [
    {
      path: "/admin-dashboard",
      name: "Dashboard",
      // subItem: [
      //   {
      //     // path: "/admin-dashboard/user",
      //     // name: "Users",
      //   },
      // ]
    },
    {
      path: "/admin-dashboard/welcome",
      name: "Manage Content",
      iconOpen: <AiOutlineDown />,

      subItem: [
        // {
        //   path: "/admindashboard",
        //   name: "Dashboard",
        // },

        {
          path: "/admin-dashboard/welcome",
          name: "Welcome Note",
        },
        {
          path: "/admin-dashboard/aboutUs",
          name: "AboutUs",
        },
        {
          path: "/admin-dashboard/footer",
          name: "Show Footer",
        },
      ],
    },
    {
      path: "/admin-dashboard/communities",
      name: "Manage Communities",
      iconOpen: <AiOutlineDown />,

      subItem: [
        // {
        //   path: "/category/categorylist",
        //   name: "Category List",
        // },
        {
          path: "/admin-dashboard/communities",
          name: "Communities",
        },
        {
          path: "/admin-dashboard/acts",
          name: "Acts",
        },
        {
          path: "/admin-dashboard/chapters",
          name: "Chapters",
        },
        {
          path: "/admin-dashboard/event",
          name: "Events",
        },
        // {
      ],
    },
    {
      path: "/admin-dashboard/user",
      name: "Manage Users",
      // iconOpen: <AiOutlineDown />,

      // subItem: [
      //   {
      //     path: "/admin-dashboard/user",
      //     name: "Users",
      //   },
      // ]
    },
    // {
    //   path: "/user",
    //   name: "Manage Users",
    // },
    //  {
    //   path: "12",
    //   name: "Manage Content",
    //   iconOpen: <AiOutlineDown />,

    //   subItem: [
    //     {
    //       path: "/welcome",
    //       name: "Welcome Note",
    //     },
    //     {
    //       path: "/aboutUs",
    //       name: "AboutUs",
    //     },
    //     {
    //       path: "/footer",
    //       name: "Show Footer",
    //     },
    //   ],
    // },

    // {
    //   path: "/communities",
    //   name: "Communities",
    // },
    // {
    //   path: "/acts",
    //   name: "Acts",
    // },
    // {
    //   path: "/event",
    //   name: "Events",
    // },
    // {
    //   path: "/category/categorylist",
    //   name: "Category List",
    // },
    // {
    //   path: "/chapters",
    //   name: "Chapters",
    // },

    // {
    //   path: "/event",
    //   name: "Events",
    // },
    // {
    //   path: "/user",
    //   name: "User",
    // },

    // {
    //   path: "/aboutUs",
    //   name: "Manage AboutUs",
    // },
    // {
    //   path: "/termsofuse",
    //   name: "Terms Of Use",
    // },
    {
      path: "/admin-dashboard/contactus",
      name: "Manage Contact Us",
      // iconOpen: <AiOutlineDown />,

      // subItem: [
      //   {
      //     path: "/admin-dashboard/contactus",
      //     name: "Contact Us",
      //   },
      // ]
    },
    {
      path: "/admin-dashboard/addads",
      name: "Manage Ads",
      // iconOpen: <AiOutlineDown />,

      // subItem: [
      //   {
      //     path: "/admin-dashboard/addads",
      //     name: " Ads",
      //   },
      // ]
    },

    {
      path: "/admin-dashboard/addsettings",
      name: "Admin Settings",
      // iconOpen: <AiOutlineDown />,

      // subItem: [
      //   {
      //     path: "/admin-dashboard/addsettings",
      //     name: "Settings",
      //   },
      // ]
    },

  ];
  return (
    <>
      <div className="header">
        <div className="ms-auto d-lg-block d-md-block d-none">
          <Dropdown>
            <Dropdown.Toggle
              variant="outline"
              id="dropdown-basic"
              className="header_sidebar_dropdown"
            >
              <img src={Rectangle1511} alt="" className="Myprofileimage" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/adminprofile")}>
                Profile
              </Dropdown.Item>
              {/* <hr className="m-0" /> */}
              {/* <Dropdown.Item href="#/action-2">Forgot Password</Dropdown.Item> */}
              <hr className="m-0" />
              <Dropdown.Item
                onClick={() => {
                  localStorage.clear();
                  navigate("/admin");
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="bars" style={{ marginLeft: isOpen ? "10px" : "10px" }}>
          <FaBars onClick={toggle} className="d-lg-none d-md-none d-block" />
        </div>
      </div>

      <div className="combind">
        <div className={`sidebar ${isOpen ? "mobileview" : "destopview"}`}>
          <div className="sidebar_img_icon">
            <h1 className="sidebar_head">OW</h1>
            <AiOutlineClose
              className="sidebar_close_button"
              onClick={closeButton}
            />
          </div>
          {menuItem.map((item, index) => (
            <Fragment key={index}>
              <NavLink to={item.path} key={index} className="link">
                <div
                  className="link_text"
                  onClick={() => closeSubmenu(item.name)}
                >
                  <div className="sidebar_category">
                    {item.name}

                    <div
                      className="item_icon"
                      style={{
                        transform:
                          onClick === item.name
                            ? "rotate(360deg)"
                            : "rotate(-90deg)",
                      }}
                    >
                      {item.iconOpen}
                    </div>
                  </div>
                </div>
              </NavLink>

              <>
                <ul className="sidebar_sub_menu">
                  {item.subItem?.map((items, subindex) => {
                    return (
                      <li
                        style={{
                          display: onClick === item.name ? "block" : "none",
                        }}
                        className="submenu_li"
                        key={subindex}
                      >
                        <NavLink
                          to={items.path}
                          className="sub_test"
                          activeclassname="active"
                        >
                          {items.name}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </>
            </Fragment>
          ))}
        </div>

        <div className="contain">{children}</div>
      </div>
    </>
  );
};

export default Admin;
