import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const isLoadingSlice = createSlice({
  name: "isLoading",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsLoading } = isLoadingSlice.actions;

export default isLoadingSlice.reducer;
