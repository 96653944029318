import React, { forwardRef } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { threeDot } from "../utils/svg.file";
import Dropdown from 'react-bootstrap/Dropdown';
import Swal from "sweetalert2";
import { Base_URL } from '../utils/serverUrl';
import axios from 'axios';

const Test = forwardRef(({ item, index, img_URL, className, setcommunity, communitydata }, ref) => {

    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: item.id });

    let token = localStorage.getItem("userToken");
    let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
    let userId = localStorage.getItem("userId");
    // const [isCommunity, setIsCommunity] = React.useState("");
    const inlineStyles = {
        opacity: isDragging ? '0.5' : '1',
        transformOrigin: '50% 50%',
        borderRadius: '10px',
        cursor: isDragging ? 'grabbing' : 'grab',
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
        // backgroundColor: '#ffffff',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // boxShadow: activeId ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px' : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
        // transform: activeId ? 'scale(1.05)' : 'scale(1)',


    };
    const DeleteAct = (id) => {
        Swal.fire({
            // title: `Are you sure you want to delete this act ?`,
            text: "Are you sure you want to delete this act ? ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                var data = new FormData();
                data.append("id", id);

                var config = {
                    method: "delete",
                    url: `${Base_URL}delete-act/${id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + isLoggedIn,
                    },

                    data: data,
                };
                axios(config)
                    .then(function (response) {
                        setcommunity();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                // Swal.fire("You have left this community.");
            }
        });
    };
    // console.log(isCommunity, "id is ")
    return (
        // <div ref={setNodeRef}
        //     withOpacity={isDragging}
        //     // {...props}
        //     {...attributes}
        //     {...listeners}
        //     style={inlineStyles}
        // >

        <Card
            className={className}
            // onClick={() => {

            //     setIsCommunity(item)
            // }
            // }

            // "slidercard_card"
            key={index}

            style={{ position: "relative" }}

        >
            {/* <button className="btn text-bg-success" style={{ position: "absolute", left: "10px", top: "8px", fontSize: "12px" }}>Drag&Drop</button> */}
            {communitydata.user_id === Number(userId) ?
                <Dropdown className="deletenew">
                    <Dropdown.Toggle className="deletenew_toggle" >
                        {threeDot}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="deletenew_menu ">
                        <Dropdown.Item onClick={() => DeleteAct(item.id)} className="deletenew_item "
                        >Delete Act</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> : ""}
            <div ref={setNodeRef}
                withOpacity={isDragging}
                // {...props}
                {...attributes}
                {...listeners}
                style={inlineStyles}
            >
                <Card.Img
                    variant="top"
                    className="slider_card_img"
                    src={`${img_URL}${item.image}`}
                />
            </div >
            <Card.Body>
                <Card.Title className="slider_card_title">
                    {/* Silverfrog195 */}
                    {item.act_name}
                </Card.Title>
                <Card.Text className="slider_card_text">
                    {item.description}

                </Card.Text>
                <Button variant="primary" className="slick_slide_btn">
                    {item.count_act} Chapters
                </Button>
            </Card.Body>
        </Card>
        // </div >
    )
})

export default Test