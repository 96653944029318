import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddBanner from "./AddBanner";
import UpdateAds from "./UpdateAds";
import Plus from "../../Assets/images/plus.png";
import axios from "axios";
import { useEffect } from "react";
import { ADS_URL, Base_URL } from "../../utils/serverUrl";
import Swal from "sweetalert2";
import Addfooter from "./Addfooter";
import Updatefooter from "./Updatefooter";

const Footer = () => {
  const [modalshow, setModalShow] = useState(false);
  const [updatemodalShow, setUpdateModalShow] = useState(false);
  const [allShowfooter, setAllShowfooter] = useState([]);
  const [footerId, setFooterId] = useState([]);

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showAllfooter = () => {
    var data = new FormData();

    var config = {
      method: "get",

      url: `${Base_URL}admin/showfooter`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response.data.data, "showfooter");
        setAllShowfooter(response.data.data);
        // props.showallAdds();
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showAllfooter();
  }, []);

  const Deletefooter = (id) => {
    var data = new FormData();

    var config = {
      method: "delete",

      url: `${Base_URL}admin/deletefooter/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response, "deletefooter");
        showAllfooter();
        // setAllShowfooter(response.data.data);
        // props.showallAdds();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="text-end me-2 py-2">
        <Button className="categorylist_btn" onClick={() => setModalShow(true)}>
          Add
        </Button>
      </div>

      <div className="categorylist_page">
        <Table sx={{ minWidth: 950 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Facebook Link</TableCell>
              <TableCell>Instagram Link</TableCell>
              <TableCell>Pinterest Link</TableCell>
              <TableCell>Twitter Link</TableCell>
              <TableCell>Copy right </TableCell>
              <TableCell>Action </TableCell>
            </TableRow>
          </TableHead>

          {allShowfooter?.map((item, idx) => (
            <TableBody>
              <TableCell>{idx + 1}</TableCell>

              <TableCell>{item.text}</TableCell>
              <TableCell>{item.facebook}</TableCell>
              <TableCell>{item.instagram}</TableCell>
              <TableCell>{item.pinterest}</TableCell>
              <TableCell>{item.twitter}</TableCell>
              <TableCell>{item.copy}</TableCell>
              <TableCell>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => (
                      setFooterId(item.id), setUpdateModalShow(true)
                    )}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-2"
                    onClick={() => Deletefooter(item.id)}
                  >
                    Delete
                  </button>
                </div>
              </TableCell>
            </TableBody>
          ))}
        </Table>
        {/* </div> */}
      </div>
      {/* </div> */}
      <Updatefooter
        show={updatemodalShow}
        footerId={footerId}
        onHide={() => (setUpdateModalShow(false), setFooterId(""))}
        showAllfooter={showAllfooter}
      />
      <Addfooter
        show={modalshow}
        onHide={() => setModalShow(false)}
        showAllfooter={showAllfooter}
      />
    </>
  );
};

export default Footer;
