import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddBanner from "./AddBanner";
import UpdateAds from "./UpdateAds";
import Plus from "../../Assets/images/plus.png";
import axios from "axios";
import { useEffect } from "react";
import { ADS_URL, Base_URL } from "../../utils/serverUrl";
import Swal from "sweetalert2";

const AddAds = () => {
  const [modalShow, setModalShow] = useState(false);
  const [updatemodalShow, setUpdateModalShow] = useState(false);
  const [alladds, setAlladds] = useState([]);
  const [adsid, setADSId] = useState("");

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showallAdds = () => {
    // console.log("Adds is Working........");
    var config = {
      method: "get",
      url: `${Base_URL}admin/get-ads`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "show all adss")

        setAlladds(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    showallAdds();
  }, []);

  const addeditevent = (id) => {
    // e.preventDefault(e);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        var data = new FormData();

        var config = {
          method: "delete",

          url: `${Base_URL}admin/deleteadds/${id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            showallAdds();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div className="text-end me-2 py-2">
        <Button className="categorylist_btn" onClick={() => setModalShow(true)}>
          Add
        </Button>
      </div>

      <div className="categorylist_page">
        <Table sx={{ minWidth: 950 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {alladds?.map((item, idx) => (
            <TableBody>
              <TableCell>{idx + 1}</TableCell>

              <TableCell>
                <img src={`${ADS_URL}${item.image}`} alt="chapter img" className="addads_img_ads" />
              </TableCell>
              <TableCell>{item.link}</TableCell>
              <TableCell>
                <div className="d-flex ">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => (setADSId(item.id), setUpdateModalShow(true))}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-1"
                    onClick={() => addeditevent(item.id)}
                  >
                    Delete
                  </button>
                </div>
              </TableCell>
            </TableBody>
          ))}
        </Table>
        {/* </div> */}
      </div>
      {/* </div> */}
      <UpdateAds
        show={updatemodalShow}
        adsid={adsid}
        onHide={() => setUpdateModalShow(false)}
        showallAdds={showallAdds}
      />
      <AddBanner
        show={modalShow}
        // adsid={adsid}
        onHide={() => setModalShow(false)}
        showallAdds={showallAdds}
      />
    </>
  );
};

export default AddAds;
