import React, { useEffect } from 'react'
import Headers from '../Layouts/Headers'
import upload from "../../Assets/images/upload.png";
import { Button, Container, Stack } from 'react-bootstrap';
import camera from "../../Assets/images/myavtar.png";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
// import camera from "../../Assets/images/myavtar.png";
import FormControl from "@mui/material/FormControl";
import {
    addCalender,
    journalismAdd,
    plusIcon,
    editicons,
    deleteIcon,
} from "../../utils/svg.file";
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { Base_URL } from '../../utils/serverUrl';
import axios from 'axios';

const Preview = () => {

    let previewData = JSON.parse(localStorage.getItem("previewData"));
    let imageuser = JSON.parse(localStorage.getItem("preimg"));

    // const location = useLocation()

    // console.log('sate -- ', location.state
    // );

    // let {
    //     Categoryid,
    //     details,
    //     pest,
    //     textareavalue
    // } = previewData

    const [categoryid, setCategoryid] = React.useState(previewData?.Categoryid);
    const [detail, setDetail] = React.useState(previewData?.details.title);
    const [handle, sethandle] = React.useState(previewData?.details.subTitle);
    const [about, setAbout] = React.useState(previewData?.textareavalue);
    const [banner, setBanner] = React.useState(previewData?.pest);
    const [userimg, setUserimg] = React.useState(imageuser);

    const [inputRef, setInputRef] = React.useState(false);
    const [inputRef2, setInputRef2] = React.useState(false);
    const [inputRef3, setInputRef3] = React.useState(false);
    const [user, setUser] = React.useState("");
    const date_update = moment().format(" D MMMM YYYY");

    // const fetchData = async () => {
    //     var config = {
    //         method: "get",
    //         url: `${Base_URL}allcategory`,
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             setUser(response.data.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };

    // useEffect(() => {
    //     fetchData()
    // }, [])

    localStorage.removeItem("preimg");
    localStorage.removeItem("previewData");
    return (
        <>
            {/* <Headers /> */}

            <div
                className="headingCommunity position-relative mt-1"
                style={{
                    backgroundImage: banner ? `url(${banner})` : `url(${upload})`,

                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "300px",
                }}
            >
            </div>
            <section>
                <Container>
                    <div className="community_stack">
                        <Stack direction="horizontal" className="py-3" gap={3}>
                            <div className="rotateImg position-relative">
                                <img
                                    alt=""
                                    src={userimg || camera}
                                    className="communitydetailpage_img_radius"
                                />
                            </div>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: inputRef ? "#fff" : "transparent",
                                    }}
                                    className="communitydetailpage_heading_journalism"
                                >
                                    <input onFocus={() => setInputRef(true)}

                                        placeholder="This is the title"
                                        defaultValue={detail}
                                        type="text"
                                        className="communitydetailpage_heading_journalism"

                                        style={{
                                            width: detail
                                                ? detail.length + 1 + "ch"
                                                : "95%",
                                            fontSize: "23px",
                                            fontWeight: 700,
                                            border: inputRef ? "1px" : "none",
                                        }}

                                    />
                                </div>
                                <div
                                    style={{
                                        backgroundColor: inputRef2 ? "#fff" : "transparent",
                                        // marginTop: "11.5px",
                                        // marginBottom: "11.5px",
                                    }}
                                    className="communitydetailpage_heading_journalism_p"
                                >
                                    @
                                    <input

                                        onFocus={() => setInputRef2(true)}

                                        placeholder="This is the Community Handle"
                                        defaultValue={handle}

                                        type="text"
                                        className="communitydetailpage_heading_journalism_p"
                                        style={{
                                            // width: communitydata?.community_handle
                                            //   ? communitydata.community_handle.length +
                                            //     1 +
                                            //     "ch"
                                            //   : "24ch",
                                            width: "24ch",
                                            fontSize: "18px",
                                            fontWeight: 700,
                                            border: inputRef2 ? "1px" : "none",
                                        }}
                                    />
                                </div>
                                {/* <div className="d-block align-items-center d-lg-flex ">
                                    <FormControl sx={{ minWidth: "63%" }}>
                                        <Select

                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            defaultValue={categoryid}
                                        >
                                            {Object.values(user).map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}

                                        </Select>
                                    </FormControl>

                                </div> */}
                            </div>
                            <div className="search_result_button position-relative jpinedBtn">
                                <Button
                                    className="search_result_community_button"

                                >
                                    Owner
                                </Button>
                            </div>
                            <div className="ms-auto align-items-center">
                                <div className="d-flex justify-content-end">
                                    <i className="communitydetailpage_date_calender">
                                        {addCalender}
                                    </i>
                                    <p className="communitydetailpage_date_text">
                                        created on
                                        <span className="communitydetailpage_date_date">
                                            {/* 25 Aug 2022 */}
                                            {date_update}
                                        </span>
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <Button className="communitydetailpage_members_button">
                                        {/* {communitymember} */}
                                        10
                                        <p className="communitydetailpage_members_text">
                                            Members
                                        </p>
                                    </Button>
                                    <Button className="communitydetailpage_members_button">
                                        {/* {onlineuser.length} */}
                                        10
                                        <p className="communitydetailpage_members_text">
                                            Online
                                        </p>
                                    </Button>
                                </div>
                            </div>
                        </Stack>

                    </div>

                </Container>
            </section>
            <section>
                <div className="community_detail_page ">
                    <Container>
                        <div className="pt-4">
                            <div className="row">
                                <div className="col-lg-8 col-md-6">
                                    <div className="community_detail_page_back h-100">
                                        <h2 className="community_detail_page_heading">
                                            About Community
                                        </h2>
                                        <hr />
                                        <div
                                            style={{
                                                backgroundColor: inputRef3
                                                    ? "#fff"
                                                    : "transparent",
                                                // marginTop: "11.5px",
                                                // marginBottom: "11.5px",
                                            }}
                                            className="community_detail_page_description"
                                        >
                                            <textarea
                                                // disabled={preLoader}
                                                className="form-control"
                                                rows="8"

                                                onFocus={() => setInputRef3(true)}

                                                placeholder="This is the about"
                                                defaultValue={about}

                                                // onBlur={editcommunity}
                                                type="text"
                                                // className="community_detail_page_description"
                                                maxLength={1000}
                                                style={{
                                                    width: about
                                                        ? about.length + 1 + "ch"
                                                        : "100%",
                                                    fontSize: "18px",
                                                    fontWeight: 400,
                                                    border: inputRef3 ? "1px" : "none",
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="community_detail_page_back h-100 mobiletop-15 scrolss ">
                                        <h2 className="community_detail_page_heading">
                                            Community Members
                                        </h2>
                                        <hr />
                                    </div>
                                </div>


                            </div>
                        </div>

                    </Container>
                </div>
            </section>

        </>
    )
}

export default Preview