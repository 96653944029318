import { React, useState, useRef, useMemo, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Button from "react-bootstrap/Button";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";

const AddAboutUs = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

 
  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showaboustus = () => {
    // console.log("jkdhfjhjfhjdhfjhajfjhghsdgsghd........");
    var config = {
      method: "get",
      // url: `${Base_URL}get-about`,
      url: `${Base_URL}admin/getabout/${props.aboutid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
       
        setTitle(response.data.data.title);
        setContent(response.data.data.discription);

        // setAboutus(response.data.data);
        
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    showaboustus();
  }, [props.aboutid]);

  const fetchData = (e) => {
    // e.preventDefault(e)
    // console.log("w Add about");
    var data = new FormData();
    data.append("title", title);
    data.append("discription", content);

    var config = {
      method: "post",

      url: `${Base_URL}admin/addabout`,
      headers: {
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
       
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  
  const UpdateData = () => {
   
    var data = new FormData();
    data.append("title", title);
    data.append("discription", content);
    data.append("id", props.aboutid);

    var config = {
      method: "post",

      url: `${Base_URL}admin/updateabout`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.aboutid ? "Update" : "Add"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">
            {" "}
            {props.aboutid ? "Update" : "Add About us"}
          </h1>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label edit_profile_lebal"
                >
                  Bio
                </label>

                <JoditEditor
                  ref={editor}
                  value={content}
                  // onChange={newContent => setContent(e.target.value)}
                  onChange={(newContent) => setContent(newContent)}
                />
              </div>
            </Form.Group>

            <div className="text-center">
              <Button
                onClick={() => (props.onHide(), setContent(""), setTitle(""))}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ms-3"
                onClick={props.aboutid ? UpdateData : fetchData}
              >
                {props.aboutid !== "" ? "update" : "add"}
              </Button>
            </div>
          </Form>
          {/* {content} */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAboutUs;
