import { React, useEffect, useState } from "react";
import "../Assets/Css/styles.css";
import { Form, Modal } from "react-bootstrap";
import axios from "axios";
import { Base_URL } from "../utils/serverUrl";
import { Button } from "@mui/material";
import { PulseLoader } from "react-spinners";
import { styled } from "@mui/material/styles";
import { uploadIcon } from "../utils/svg.file";

const EditCategory = (props) => {
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  let [loading, setLoading] = useState(true);

  let localtoken = localStorage.getItem("userToken");
  let isLoggedIn = localtoken.replace(/^"(.+)"$/, "$1");

  const editfetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",
      url: `${Base_URL}admin/get-category/${props.editdata}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
        setTitle(response.data.data.name);
        setDescription(response.data.data.discription);
        setLoading(false);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const fetchData = () => {
    var data = new FormData();
    data.append("id", props.editdata);
    data.append("name", title);
    data.append("discription", description);

    var config = {
      method: "post",

      url: `${Base_URL}admin/update-category`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        props.showallcategory();
        props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    editfetchdata();
  }, [props.editdata]);

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Category
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h1 className="text-center">Edit Category</h1>

          {loading ? (
            <div
              className="sweet-loading loder_loader"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PulseLoader
                color="#36d7b7"
                size={100}
                speedMultiplier={2}
                loading={loading}
              />
            </div>
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Enter Title"
                  Value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center p-1">
              <Button className="addcategory_btn " onClick={fetchData}>
                Submit
              </Button>
            </div>
            <div className="text-center p-1">
              <Button
                className="addcategory_btn "
                onClick={() => {
                  props.onHide();
                  setTitle("");
                  setDescription("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCategory;
