import React, { useEffect } from "react";
import {
  Avatar,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "../../Assets/Css/communitydetailpage.css";
import Headers from "../../Components/Layouts/Headers";
import Footer from "../../Components/Layouts/Footer";
import Imagedetail from "../../Assets/images/communitydetailpage.png";
import {
  Button,
  Card,
  Container,
  Nav,
  Stack,
  Tab,
  Modal,
} from "react-bootstrap";
import upload from "../../Assets/images/upload.png";
import {
  addCalender,
  journalismAdd,
  plusIcon,
  editicons,
  deleteIcon,
  editIcon,
} from "../../utils/svg.file";
import Rectangle8 from "../../Assets/images/rectangle 1508.png";
import Rectangle10 from "../../Assets/images/rectangle 1510.png";
import Rectangle11 from "../../Assets/images/rectangle 1511.png";
import Rectangle9 from "../../Assets/images/rectangle1509.png";
import SlickSlide from "../../Components/SlickSlide";
import TextEditor from "../editor/TextEditor";
import ApprovedChapter from "./ApprovedChapter";
import Community6 from "./Community6";
import Community7 from "./Community7";
import Community8 from "./Community8";
import Upbutton from "../../Assets/images/upbutton.png";
import Downbutton from "../../Assets/images/downbutton.png";
import { Link, useParams } from "react-router-dom";
import bi_camera from "../../Assets/images/bi_camera.png";
import axios from "axios";
import Abcd1 from "../../Assets/images/camera.png";
import Delete from "../../Assets/images/delete.png";
import {
  Base_URL,
  CHAPTERIMG_URL,
  COMMUNITYBIGIMG_URL,
  COMMUNITYSMALLIMG_URL,
  img_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import moment from "moment";
import EditChapter from "../create-edit-communit/modal/editchapter";
import { useState } from "react";
import ViewModal from "../create-edit-communit/modal";
import RemoveChapter from "../create-edit-communit/modal/remove-chapter";
import AddChapter from "../create-edit-communit/modal/add-chapter";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import camera from "../../Assets/images/myavtar.png";
import FormControl from "@mui/material/FormControl";
import Communitybaner from "./Communitybaner";
import { PulseLoader } from "react-spinners";
import AddNewAct from "../create-edit-communit/modal/add-new-act";
import Greenplus from "../../Assets/images/greenplus.png";
import { useNavigate } from "react-router-dom";
import { number } from "yup/lib/locale";
import Swal from "sweetalert2";
import AddMember from "../create-edit-communit/modal/add-member";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddEvents from "../Event-Modal/AddEvents";
import { threeDot } from "../../utils/svg.file";
import Dropdown from "react-bootstrap/Dropdown";

// import {myavtar} from  "../../Assets/images/myavtar.png";
// import moment from "moment";
const CommunityDetailPage = (props) => {
  const [isCommunity, setIsCommunity] = React.useState(true);
  const [communitydata, setCommunitydata] = React.useState([]);
  const [communitycat, setCommunitycat] = React.useState([]);
  const [communityact, setCommunityact] = React.useState([]);
  const [communitymember, setCommunitymember] = React.useState([]);
  const [discussiondetail, setDiscussiondetail] = React.useState([]);
  const [joinuserlist, setJoinuserlist] = React.useState([]);
  const [isViewAll, setIsViewAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [chapterId, setChapterId] = React.useState("");
  const [fileselect, setFileselect] = React.useState("");
  const [imghome, setImghome] = React.useState("");
  const [inputRef, setInputRef] = React.useState(false);
  const [inputRef2, setInputRef2] = React.useState(false);
  const [inputRef3, setInputRef3] = React.useState(false);
  const [age, setAge] = React.useState("Select Category");
  const [user, setUser] = React.useState("Select Category");
  const [imghome2, setImghome2] = React.useState("");
  const [imghome3, setImghome3] = React.useState("");
  const [creaters, setCreaters] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [onlineuser, setOnlineuser] = React.useState("");
  const [filtermember, setFiltermember] = React.useState([]);
  const [pendingchapters, setPendingchapter] = React.useState([]);
  const [isCommunityData, setIsCommunityData] = React.useState(true);
  const [joinstatus, setJoinstatus] = useState([]);
  const [checkJoinuser, setCheckJoinuser] = useState([]);
  const [showJoinUserStatus, setShowJoinUserStatus] = useState(false);
  const [showChkjoinUser, setShowChkjoinUser] = useState(false);
  const [showChkjoinFounder, setShowChkjoinFounder] = useState(false);
  const [userlistshow, setUserListShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState("");
  const [joinId, SetjoinId] = React.useState("");
  const [username, SetUsername] = React.useState("");
  const [title, SetTitle] = React.useState(communitydata?.title);
  const [handle, Sethandle] = React.useState(communitydata?.community_handle);
  const [about, SetAbout] = React.useState(communitydata?.about);
  const [showmem, setShowmembers] = React.useState([]);
  const [showcreater, setShowcreater] = React.useState([]);
  const [actid, setActId] = React.useState("");
  const [ischapter, setIschapter] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingchap, setLoadingChap] = useState(false);
  const [loadingevent, setLoadingEvent] = useState(false);
  const params = useParams();
  const [onsave, setOnsave] = React.useState(false);
  const [eventshow, setEventshow] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [upVote, setUpVote] = React.useState({});
  const [downVote, setDownVote] = React.useState({});
  const [checkmembers, setCheckMembers] = React.useState(false);
  const community_id = params.id;
  const edit_type = params.type;
  localStorage.setItem("community_id", community_id);

  let token = localStorage.getItem("userToken");
  let userId = localStorage.getItem("userId");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  // let act_id = localStorage?.getItem("act_id");

  const [open, setOpen] = React.useState();
  let navigate = useNavigate();
  // console.log(actId, "check id");

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowBackIcon />
        </button>
      </div>
    );
  };
  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowForwardIcon />
        </button>
      </div>
    );
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const showmembers = () => {
    var data = new FormData();
    data.append("id", community_id);

    var config = {
      method: "post",
      url: `${Base_URL}showmember`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setShowmembers(response.data.data);
        setShowcreater(response.data.creator);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showmembers();
  }, [community_id]);

  const eventlist = () => {
    setLoadingEvent(true);
    var data = new FormData();
    data.append("chapter_id", chapterId);
    data.append("act_id", actid);

    var config = {
      method: "post",
      url: `${Base_URL}showevent`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Authorization: "Bearer " + isLoggedIn,
      // },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "pendinghhhhhhhhhhhhhhhhhh");
        // setEventshow(response.data.data);
        // setLoadingEvent(false);
        showcount(response.data.data);
        // setUsername(response.data.name);
        // setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showcount = (eventData) => {
    var data = new FormData();
    data.append("chapter_id", chapterId);

    var config = {
      method: "post",
      url: `${Base_URL}show_event_count`,
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "check up");
        const upVotes = response.data.data.result_up;
        const downVotes = response.data.data.result_down;

        setUpVote(upVotes);
        setDownVote(downVotes);

        const sortedData = eventData.map((item) => {
          return {
            ...item,
            voteDiff:
              (upVotes.find((vote) => vote.event_id === item.id)?.total || 0) -
              (downVotes.find((vote) => vote.event_id === item.id)?.total || 0),
          };
        });

        setEventshow(
          [...sortedData].sort((a, b) => {
            return b.voteDiff - a.voteDiff;
          })
        );
        setLoadingEvent(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    eventlist();
  }, [chapterId, actid]);

  const counter = (param, params_down, id) => {
    var data = new FormData();
    data.append("community_id", community_id);
    data.append("act_id", actid);
    data.append("status", param);
    data.append("chapter_id", chapterId);
    data.append("event_id", id);
    // data.append("status", param);
    data.append("counter_down", params_down);

    var config = {
      method: "post",
      url: `${Base_URL}update_event_counter`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "event data");
        showcount(eventshow);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   // eventlist();
  //   showcount();
  // }, [chapterId]);

  const handleClickOpen = (type, id) => {
    setOpen(type);
    // if (id) {
    //   setChapterId(id);
    // }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowDeleteModal = (id, name) => {
    SetjoinId(id);
    SetUsername(name);

    setShowDeleteModal(true);
  };

  const showdiscussion = () => {
    var data = new FormData();
    data.append("community_id", community_id);

    var config = {
      method: "post",
      url: `${Base_URL}showdiscussion`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        setDiscussiondetail(response.data.data);
        const date_current = moment().format(" D MMMM YYYY");
        const date1 = moment(setDiscussiondetail.created_at).format(
          " D MMMM YYYY"
        );
        let subtract = date_current.subtract(date1);
        let format = moment(subtract).format("D MMMM YYYY");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setcommunity = () => {
    setIsCommunityData(true);
    var data = new FormData();
    data.append("id", params.id);

    var config = {
      method: "post",
      url: `${Base_URL}showcommunitydetails`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "detailsjhjhjhjfhjdfhjdhfjhfjh");
        setCommunitydata(response.data.data);
        setCommunityact(response.data.act);
        setOnlineuser(response.data.onlineusers);
        setCommunitycat(response.data.cat_name?.id);
        setCommunitymember(response.data.member + 1);
        setFiltermember(response.data.joinmember);

        setIsCommunityData(true);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsCommunityData(false);
      });
  };

  const onhandleCommunity = (actId) => {
    setActId(actId);
    // localStorage.setItem("act_id", actId);
    // setIsCommunity(!isCommunity);
  };
  const communitydetailpage = [
    {
      images: Rectangle8,
      name: "Dr Cameron Williamson",
      title: "Creator",
    },
    {
      images: Rectangle9,
      name: "Dianne Russell",
      title: "Founder",
    },
    {
      images: Rectangle10,
      name: "Ralph Edwards",
      title: "Member",
    },
    {
      images: Rectangle11,
      name: "Savannah Nguyen",
      title: "Creator",
    },
  ];

  const date_update = moment(communitydata.created_at).format(" D MMMM YYYY");
  // _____________img croprer________
  const [fileList, setFileList] = React.useState([]);
  useEffect(() => {
    setFileList([
      {
        uid: "-1",
        url: communitydata.image
          ? `${COMMUNITYSMALLIMG_URL}${communitydata.image}`
          : `https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${communitydata.title?.[0]?.toUpperCase()}`,
      },
    ]);
  }, [communitydata]);
  const onChange = ({ fileList }) => {
    setOnsave(true);
    setFileList(fileList || []);
    setImghome(fileList[0]?.originFileObj);
    if (imghome) {
      // editcommunity();
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const joineduserlist = () => {
    var data = new FormData();
    data.append("id", params.id);

    var config = {
      method: "post",
      url: `${Base_URL}detailsshowmember`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "members data")
        setJoinuserlist(response.data.data);

        setCreaters(response.data.creator);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editcommunity = (jai, imageData) => {
    var data = new FormData();
    data.append("community_id", params.id);
    data.append("image", imghome);
    data.append("banner_image", imageData || imghome3);
    data.append("title", communitydata.title);
    data.append("category_id", "1");
    data.append("about", communitydata.about);
    data.append("discussion", "1");
    data.append("community_handle", communitydata.community_handle);

    var config = {
      method: "post",
      url: `${Base_URL}update-community`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function (response) {
        setcommunity();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchData = async () => {
    var config = {
      method: "get",
      url: `${Base_URL}allcategory`,
    };

    axios(config)
      .then(function (response) {
        setUser(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    setAge(event.target.value);
    setCommunitycat(event.target.value);
    setOnsave(true);
  };

  const pendingchapter = () => {
    setLoadingChap(true);
    var data = new FormData();
    data.append("community_id", community_id);
    data.append("act_id", actid);

    var config = {
      method: "post",
      url: `${Base_URL}chapterpending-list`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "chapters check")
        setPendingchapter(response.data.data);
        setCheckJoinuser(response.data.joinUser);
        setLoadingChap(false);

        // setUsername(response.data.name);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // React.useEffect(() => {
  //   setOnsave(true)
  // }, [imghome3])

  const handletitle = (e) => {
    setOnsave(true);
    setCommunitydata((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };
  const handlecommunityhandle = (e) => {
    setOnsave(true);
    setCommunitydata((prev) => ({
      ...prev,
      community_handle: e.target.value,
    }));
  };
  const handleabout = (e) => {
    setOnsave(true);
    setCommunitydata((prev) => ({
      ...prev,
      about: e.target.value,
    }));
  };
  useEffect(() => {
    pendingchapter();
    // showcount();
  }, [actid]);

  // console.log(communitydata.title, communitydata.community_handle, communitydata.about, communitycat, imghome3, "hdhdhdhdhd", imghome, "hello title")

  const ChkUser = () => {
    const object = checkJoinuser?.some(
      (obj) => Number(obj.sent_user_id) === Number(userId)
    );
    if (object) {
      setShowJoinUserStatus(true);
    } else {
      setShowJoinUserStatus(false);
    }
  };
  const ChkjoinUser = () => {
    const object = joinuserlist?.some(
      (obj) =>
        Number(obj.id) === Number(userId) &&
        Number(obj.role) === 2 &&
        Number(obj.status) === 2
    );

    if (object) {
      setShowChkjoinUser(true);
    } else {
      setShowChkjoinUser(false);
    }
  };
  const Chkmembers = () => {
    const object = joinuserlist?.some(
      (obj) =>
        Number(obj.id) === Number(userId) &&
        Number(obj.role) === 1 &&
        Number(obj.status) === 2 || Number(obj.id) === Number(userId) &&
        Number(obj.role) === 2 &&
        Number(obj.status) === 2
    );

    if (object) {
      setCheckMembers(true);
    } else {
      setCheckMembers(false);
    }
  };

  useEffect(() => {
    ChkUser();
  }, [checkJoinuser]);
  useEffect(() => {
    ChkjoinUser();
    Chkmembers();
  }, [joinuserlist]);

  useEffect(() => {
    fetchData();
  }, [age]);

  const userlist = () => {
    const object = joinuserlist?.some(
      (obj) => Number(obj.id) === Number(userId)
    );

    if (object) {
      setUserListShow(true);
    } else {
      setUserListShow(false);
    }
  };
  useEffect(() => {
    userlist();
  }, [joinuserlist]);

  useEffect(() => {
    setcommunity();
    showdiscussion();
    joineduserlist();
  }, [isSubmitting]);

  const object = filtermember.find((obj) => obj.join === Number(userId));
  console.log(checkmembers, "test value");

  // const joined = (id, title, user_id) => {
  //   Swal.fire({
  //     title: `Would You like to join ${title}?`,
  //     // text: "you feel like Great !",
  //     // icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, join it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       var data = new FormData();
  //       data.append("community_id", id);
  //       data.append("user_id", user_id);
  //       data.append("state", "join");

  //       var config = {
  //         method: "post",
  //         url: `${Base_URL}joinuser`,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer " + isLoggedIn,
  //         },
  //         data: data,
  //       };

  //       axios(config)
  //         .then(function (response) {
  //           setJoinstatus([...joinstatus, id]);
  //           joineduserlist();
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //       Swal.fire("Joined!", "Welcome to this community", "success");
  //     }
  //   });
  // };

  const showrequest = () => {
    var config = {
      method: "post",
      url: `${Base_URL}showrequest`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        const comunity_id = response.data.data;
        const doubled = comunity_id.map(
          (comunity_id) => comunity_id.community_id
        );

        setJoinstatus(doubled);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showrequest();
  }, []);
  const handleRemove = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to leave this community!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Leave",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newPeople = joinstatus.filter((joinstatus) => joinstatus !== id);
        setJoinstatus(newPeople);
        // alert(id);
        var data = new FormData();
        data.append("id", id);

        var config = {
          method: "post",
          url: `${Base_URL}deleterequest`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            joineduserlist();
          })
          .catch(function (error) {
            console.log(error);
          });
        Swal.fire("You have left this community.");
      }
    });
  };
  const deleteMember = () => {
    var config = {
      method: "delete",
      url: `${Base_URL}delete-joinuser/${joinId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };
    axios(config)
      .then(function (response) {
        joineduserlist();
        setShowDeleteModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onhandleChapter = (id) => {
    setChapterId(id);
    // setIschapter(!ischapter);
  };

  useEffect(() => {
    setChapterId(pendingchapters?.[0]?.id);
    // console.log(pendingchapters?.[0]?.id, "1323654587489")
  }, [pendingchapters]);

  const handleDeleteCommunity = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this community!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var config = {
          method: "delete",
          url: `${Base_URL}delete-community/${community_id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
        };

        axios(config)
          .then(function (response) {
            Swal.fire("You Delete this community.");
            navigate("/mycommunities");
            // setShowDeleteModal(false)
            // showcommunites()
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };
  const DeleteComunityimage = () => {
    Swal.fire({
      title: `Do you want to delete Image?`,
      // text: "you feel like Great !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var config = {
          method: "post",
          url: `${Base_URL}updateCommunityImage/${community_id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
        };

        axios(config)
          .then(function (response) {
            setcommunity();
          })
          .catch(function (error) {
            console.log(error);
          });
        Swal.fire("Image Deleted");
      }
    });
  };
  const DeleteChapter = (id) => {
    Swal.fire({
      // title: `Are you sure you want to delete this act ?`,
      text: "Are you sure you want to delete this Chapter? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var data = new FormData();
        data.append("id", id);

        var config = {
          method: "delete",
          url: `${Base_URL}delete-chapter/${id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },

          data: data,
        };
        axios(config)
          .then(function (response) {
            pendingchapter();
          })
          .catch(function (error) {
            console.log(error);
          });
        // Swal.fire("You have left this community.");
      }
    });
  };

  return (
    <>
      <Headers />
      {loading ? (
        <>
          <div
            className="sweet-loading loder_loader"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PulseLoader
              color="#36d7b7"
              size={100}
              speedMultiplier={2}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <>
          {isCommunityData ? (
            <>
              {communitydata.banner_image ? (
                <div
                  className="headingCommunity position-relative"
                  style={{
                    backgroundImage: `url(${imghome3
                      ? imghome3
                      : COMMUNITYBIGIMG_URL + communitydata.banner_image
                      })`,

                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "300px",
                  }}
                >
                  {communitydata.user_id === Number(userId) && edit_type ? (
                    <div className="editIcons">
                      <button
                        className="btn btnedits"
                        onClick={() => setModalShow(true)}
                      >
                        <span>{editicons}</span>Update
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div
                  className="headingCommunity position-relative"
                  style={{
                    backgroundImage: `url(${upload})`,

                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "300px",
                  }}
                >
                  {communitydata.user_id === Number(userId) && edit_type ? (
                    <div className="editIcons">
                      <button
                        className="btn btnedits"
                        onClick={() => setModalShow(true)}
                      >
                        <span>{editicons}</span>Edit
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
              <section>
                <div className="text-center pt-2">
                  {communitydata.user_id === Number(userId) && edit_type ? (
                    <button
                      className="btn btn-outline-success"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() =>
                        navigate(`/community-details/${community_id}`)
                      }
                    >
                      CLICK HERE TO EXIT EDIT MODE
                    </button>
                  ) : communitydata.user_id === Number(userId) ? (
                    <button
                      className="btn btn-outline-success"
                      onClick={() =>
                        navigate(`/edit-community-details/${community_id}/edit`)
                      }
                    >
                      EDIT COMMUNITY{" "}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <Container>
                  <div className="community_stack">
                    <Stack direction="horizontal" className="py-3" gap={3}>
                      <div className="rotateImg position-relative">
                        {communitydata.user_id === Number(userId) &&
                          edit_type ? (
                          <>
                            <div className="edit_profile_img_cropper1">
                              <ImgCrop rotate shape="round">
                                <Upload
                                  maxCount={1}
                                  // action="https://digimonk.co/overwritedev/public/images/communityimgs/"
                                  listType="picture-card"
                                  fileList={fileList}
                                  onChange={onChange}
                                  // onPreview={onPreview}
                                  className="rotateradius"
                                >
                                  <div className="edit_icon_comunity">
                                    {fileList.length < 1 ? (
                                      <>
                                        <img
                                          src={camera}
                                          className="w-100"
                                          alt="ima"
                                        />
                                      </>
                                    ) : (
                                      <span className="">{editIcon}</span>
                                    )}
                                  </div>
                                </Upload>
                              </ImgCrop>
                            </div>
                            {/* <Button onClick={deleteIcon}>Delete</Button> */}
                            <span
                              className="community_delete_icon"
                              onClick={DeleteComunityimage}
                            >
                              {deleteIcon}
                            </span>
                          </>
                        ) : (
                          <img
                            src={
                              communitydata.image
                                ? `${COMMUNITYSMALLIMG_URL}${communitydata.image}`
                                : `https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${communitydata.title?.[0]?.toUpperCase()}`
                            }
                            className="communitydetailpage_img_radius"
                            alt="img"
                          />
                        )}
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundColor: inputRef ? "#fff" : "transparent",
                            // marginTop: "11.5px",
                            // marginBottom: "11.5px",
                          }}
                          className="communitydetailpage_heading_journalism"
                        >
                          <input
                            disabled={
                              communitydata.user_id !== Number(userId) ||
                              !Boolean(edit_type)
                            }
                            onFocus={() => setInputRef(true)}
                            onBlur={() => {
                              setInputRef(false);
                              // editcommunity();
                            }}
                            placeholder="This is the title"
                            defaultValue={communitydata.title}
                            onChange={handletitle}
                            // onBlur={editcommunity}
                            type="text"
                            className="communitydetailpage_heading_journalism"
                            // maxLength={100}
                            style={{
                              width: communitydata?.title
                                ? communitydata.title.length + 1 + "ch"
                                : "95%",
                              fontSize: "23px",
                              fontWeight: 700,
                              border: inputRef ? "1px" : "none",
                            }}
                          />
                        </div>

                        <div
                          style={{
                            backgroundColor: inputRef2 ? "#fff" : "transparent",
                            // marginTop: "11.5px",
                            // marginBottom: "11.5px",
                          }}
                          className="communitydetailpage_heading_journalism_p"
                        >
                          @
                          <input
                            disabled={
                              communitydata.user_id !== Number(userId) ||
                              !edit_type
                            }
                            onFocus={() => setInputRef2(true)}
                            onBlur={() => {
                              setInputRef2(false);
                              // editcommunity();
                            }}
                            placeholder="This is the Community Handle"
                            defaultValue={
                              communitydata.community_handle === "null"
                                ? ""
                                : communitydata.community_handle
                            }
                            onChange={handlecommunityhandle}
                            type="text"
                            className="communitydetailpage_heading_journalism_p"
                            style={{
                              // width: communitydata?.community_handle
                              //   ? communitydata.community_handle.length +
                              //     1 +
                              //     "ch"
                              //   : "24ch",
                              width: "24ch",
                              fontSize: "18px",
                              fontWeight: 700,
                              border: inputRef2 ? "1px" : "none",
                            }}
                          />
                        </div>

                        {/* <div className="d-block align-items-center d-lg-flex "> */}
                        {/* <FormControl sx={{ minWidth: "63%" }}>
                            <Select
                              disabled={
                                communitydata.user_id !== Number(userId) ||
                                !Boolean(edit_type)
                              }
                              labelId="demo-simple-select-autowidth-label"
                              id="demo-simple-select-autowidth"
                              onChange={handleChange}
                              label="Select Category"
                              value={communitycat}
                            // onBlur={editcommunity}
                            // onBlur={props.Callapi}
                            // onBlur={() => props.Callapi}
                            >
                              {Object.values(user).map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl> */}
                        {/* <span className="communitydetailpage_Journalism_text">
                    {communitycat}
                  </span> */}
                        {/* </div> */}
                      </div>
                      <div className="search_result_button position-relative jpinedBtn">
                        {communitydata.user_id != Number(userId) ? (
                          <>
                            {/* {joinstatus.includes(communitydata.id) ? (
                              <>
                                <Button
                                  className="search_result_community_button"
                                  onClick={() => handleRemove(communitydata.id)}
                                >
                                  Joined
                                </Button>
                              </>
                            ) : (
                              <>
                                {isLoggedIn ? (
                                  <Button
                                    className="search_result_community_button"
                                    onClick={() =>
                                      joined(
                                        communitydata.id,
                                        communitydata.title,
                                        communitydata.user_id
                                      )
                                    }
                                  >
                                    Join Now
                                    <img
                                      src={Greenplus}
                                      alt="button"
                                      className="ms-2"
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    className="search_result_community_button"
                                    onClick={() => {
                                      navigate("/signin");
                                    }}
                                  >
                                    Join Now
                                    <img
                                      src={Greenplus}
                                      alt="button"
                                      className="ms-2"
                                    />
                                  </Button>
                                )}
                              </>
                            )} */}
                          </>
                        ) : (
                          <Button className="search_result_community_button">
                            Owner
                          </Button>
                        )}
                      </div>

                      <div className="ms-auto align-items-center">
                        <div className="d-flex justify-content-end">
                          <i className="communitydetailpage_date_calender">
                            {addCalender}
                          </i>
                          <p className="communitydetailpage_date_text">
                            created on
                            <span className="communitydetailpage_date_date">
                              {/* 25 Aug 2022 */}
                              {date_update}
                            </span>
                          </p>
                        </div>
                        <div className="d-flex">
                          <Button className="communitydetailpage_members_button">
                            {communitymember}
                            <p className="communitydetailpage_members_text">
                              Members
                            </p>
                          </Button>
                          <Button className="communitydetailpage_members_button">
                            {onlineuser.length}
                            <p className="communitydetailpage_members_text">
                              Online
                            </p>
                          </Button>
                        </div>
                        {communitydata.user_id === Number(userId) &&
                          edit_type ? (
                          <div className="text-center d-flex">
                            {onsave ? (
                              <button
                                className="btn btn-success  px- my-2 ms-2 "
                                onClick={() => {
                                  editcommunity();
                                  setOnsave(false);
                                }}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {" "}
                                save change{" "}
                              </button>
                            ) : (
                              <button
                                className="btn btn-outline-success px- my-2 ms-2"
                                style={{ whiteSpace: "nowrap" }}
                                disabled
                              >
                                {" "}
                                save change{" "}
                              </button>
                            )}

                            {/* <button className="btn btn-success  px-3 my-2 ms-3"
                          // onClick={() => newtab(`/preview`)}
                          >Edit Community</button> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Stack>
                  </div>
                </Container>
              </section>

              <section>
                <div className="community_detail_page ">
                  <Container>
                    <div className="pt-4">
                      <div className="row">
                        <div className="col-lg-8 col-md-6">
                          <div className="community_detail_page_back h-100">
                            <h2 className="community_detail_page_heading">
                              About Community
                            </h2>
                            <hr />
                            <div
                              style={{
                                backgroundColor: inputRef3
                                  ? "#fff"
                                  : "transparent",
                                // marginTop: "11.5px",
                                // marginBottom: "11.5px",
                              }}
                              className="community_detail_page_description"
                            >
                              <textarea
                                // disabled={preLoader}
                                className="form-control"
                                rows="8"
                                disabled={
                                  Number(communitydata.user_id) !==
                                  Number(userId) || !Boolean(edit_type)
                                }
                                onFocus={() => setInputRef3(true)}
                                onBlur={() => {
                                  setInputRef3(false);
                                  // editcommunity();
                                  // createTaskFunc();
                                }}
                                placeholder="This is the about"
                                defaultValue={
                                  communitydata.about === "null"
                                    ? ""
                                    : communitydata.about
                                }
                                onChange={handleabout}
                                // onBlur={editcommunity}
                                type="text"
                                // className="community_detail_page_description"
                                maxLength={1000}
                                style={{
                                  width: communitydata?.about
                                    ? communitydata.about.length + 1 + "ch"
                                    : "100%",
                                  fontSize: "18px",
                                  fontWeight: 400,
                                  border: inputRef3 ? "1px" : "none",
                                }}
                              />
                            </div>
                            {/* <p
                      className="community_detail_page_description"
                      contenteditable="true"
                    >
                      {communitydata.about}
                    </p> */}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="community_detail_page_back h-100 mobiletop-15 scrolss ">
                            {communitydata.user_id === Number(userId) ||
                              showChkjoinUser ? (
                              <div className="text-center">
                                <button
                                  className="btn btn-outline-success"
                                  onClick={() => handleClickOpen("addmember")}
                                >
                                  INVITE MORE MEMBERS
                                </button>
                              </div>
                            ) : (
                              <h2 className="community_detail_page_heading">
                                Community Members
                              </h2>
                            )}

                            <hr />
                            {Number(userId) === Number(creaters.id) ? (
                              <Link to={isLoggedIn ? `/profile` : ""}>
                                <Stack
                                  gap={3}
                                  direction="horizontal"
                                  className="py-2"
                                >
                                  {creaters.image !== "" ? (
                                    <div className="ms-3">
                                      <img
                                        src={`${PROFILEIMG_URL}${creaters.image}`}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    <div className="ms-3">
                                      <img
                                        src={`https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${creaters.name?.[0]?.toUpperCase()}`}
                                        alt=""
                                        className="roubdedCircle"
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    <h3 className="community_detail_page_names">
                                      <spam> {creaters.name}</spam>
                                    </h3>
                                    <spam>
                                      <p>Owner</p>
                                    </spam>
                                  </div>
                                </Stack>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  isLoggedIn
                                    ? `/userprofile/${creaters.id}`
                                    : ""
                                }
                              >
                                <Stack
                                  gap={3}
                                  direction="horizontal"
                                  className="py-2"
                                >
                                  {creaters.image !== "" ? (
                                    <div className="ms-3">
                                      <img
                                        src={`${PROFILEIMG_URL}${creaters.image}`}
                                        alt=""
                                      />
                                    </div>
                                  ) : (
                                    <div className="ms-3">
                                      <img
                                        src={`https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${creaters.name?.[0]?.toUpperCase()}`}
                                        alt=""
                                        className="roubdedCircle"
                                      />
                                    </div>
                                  )}
                                  <div className="">
                                    <h3 className="community_detail_page_names">
                                      <spam> {creaters.name}</spam>
                                    </h3>
                                    <spam>
                                      <p>Owner</p>
                                    </spam>
                                  </div>
                                </Stack>
                              </Link>
                            )}
                            {joinuserlist.length > 0 ? (
                              <>
                                {joinuserlist.map((item) => {
                                  return Number(userId) === Number(item.id) ? (
                                    <Stack
                                      gap={3}
                                      direction="horizontal"
                                      className="py-2"
                                    >
                                      <Link to={isLoggedIn ? `/profile` : ""}>
                                        {item.image !== "" ? (
                                          <div className="ms-3">
                                            <img
                                              src={`${PROFILEIMG_URL}${item.image}`}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div className="ms-3">
                                            <img
                                              src={`https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${item.name?.[0]?.toUpperCase()}`}
                                              alt=""
                                              className="roubdedCircle"
                                            />
                                          </div>
                                        )}
                                      </Link>
                                      <div className="w-100 d-flex justify-content-between">
                                        <div className="">
                                          <h3 className="community_detail_page_names">
                                            <Link
                                              to={isLoggedIn ? `/profile` : ""}
                                            >
                                              <spam> {item.name}</spam>{" "}
                                            </Link>
                                            <span className="community_detail_page_title_panding">
                                              {" "}
                                              {(item.status == 1 &&
                                                Number(userId) ===
                                                Number(item.id)) ||
                                                (item.status == 1 &&
                                                  Number(userId) ===
                                                  Number(creaters.id)) ? (
                                                <p className="m-0 community_detail_page_title pending_name">
                                                  Request Pending
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </h3>
                                          {item.role == 1 ? (
                                            <p className="m-0 community_detail_page_title">
                                              Member
                                            </p>
                                          ) : (
                                            <p className="m-0 community_detail_page_title">
                                              Founder
                                            </p>
                                          )}
                                        </div>
                                        {Number(userId) ===
                                          Number(creaters.id) ? (
                                          <span
                                            className="delete-icon me-3"
                                            onClick={() => {
                                              handleShowDeleteModal(
                                                item.join_id,
                                                item.name
                                              );
                                            }}
                                          >
                                            {deleteIcon}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Stack>
                                  ) : (
                                    <>
                                      {(isLoggedIn &&
                                        Number(userId) === Number(item.id)) ||
                                        Number(item.status) !== 1 ||
                                        Number(userId) === Number(creaters.id) ||
                                        showChkjoinUser ? (
                                        <Stack
                                          gap={3}
                                          direction="horizontal"
                                          className="py-2"
                                        >
                                          <Link
                                            to={
                                              isLoggedIn
                                                ? `/userprofile/${item.id}`
                                                : ""
                                            }
                                          >
                                            {item.image !== "" ? (
                                              <div className="ms-3">
                                                <img
                                                  src={`${PROFILEIMG_URL}${item.image}`}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              <div className="ms-3">
                                                <img
                                                  src={`https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${item.name?.[0]?.toUpperCase()}`}
                                                  alt=""
                                                  className="roubdedCircle"
                                                />
                                              </div>
                                            )}
                                          </Link>
                                          <div className="w-100 d-flex justify-content-between">
                                            <div className="">
                                              <h3 className="community_detail_page_names">
                                                <Link
                                                  to={
                                                    isLoggedIn
                                                      ? `/userprofile/${item.id}`
                                                      : ""
                                                  }
                                                >
                                                  <spam> {item.name}</spam>{" "}
                                                </Link>
                                                <span className="community_detail_page_title_panding">
                                                  {" "}
                                                  {(item.status == 1 &&
                                                    Number(userId) ===
                                                    Number(item.id)) ||
                                                    (item.status == 1 &&
                                                      Number(userId) ===
                                                      Number(creaters.id)) ||
                                                    (item.status == 1 &&
                                                      showChkjoinUser) ? (
                                                    <p className="m-0 community_detail_page_title pending_name">
                                                      Request Pending
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                              </h3>
                                              {item.role == 1 ? (
                                                <p className="m-0 community_detail_page_title">
                                                  Member
                                                </p>
                                              ) : (
                                                <p className="m-0 community_detail_page_title">
                                                  Founder
                                                </p>
                                              )}
                                            </div>
                                            {Number(userId) ===
                                              Number(creaters.id) ||
                                              (showChkjoinUser && isLoggedIn) ? (
                                              <span
                                                className="delete-icon me-3"
                                                onClick={() => {
                                                  handleShowDeleteModal(
                                                    item.join_id,
                                                    item.name
                                                  );
                                                }}
                                              >
                                                {deleteIcon}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </Stack>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <p className="ms-3 py-2"></p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </section>

              <section>
                <div className="community_detail_page">
                  <Container className="ps-3">
                    <h1 className="community_detail_page_heading_1 mb-3 pt-3">
                      <span className="community_detail_page_heading_1_span">
                        Community Discussion board
                      </span>
                    </h1>

                    <div className="border-0" style={{ background: "#fff" }}>
                      {/* {isLoggedIn ? (

                        <> */}
                      <TextEditor
                        community_id={community_id}
                        discussionclick={showdiscussion}
                        checkmembers={checkmembers}
                        // joinuserlist={joinuserlist}
                        communitydata={communitydata}
                      // showChkjoinUser={showChkjoinUser}
                      />
                      {/* </>

                      ) : null} */}
                    </div>
                    {discussiondetail?.map((item, index) => {
                      if (index > 2 && !isViewAll) {
                        return;
                      }

                      return (
                        <div>
                          <Stack
                            direction="horizontal"
                            className="py-3"
                            gap={3}
                          >
                            <div>
                              <img
                                src={
                                  item.image
                                    ? `${PROFILEIMG_URL}${item.image}`
                                    : camera
                                }
                                alt="img"
                                className="discution_board_img"
                              //  {Rectangle8}
                              />
                            </div>
                            <div>
                              <h5 className="community_detail_page_heading_2">
                                {item.name}.
                                <span className="community_detail_page_heading_2_span">
                                  {moment(item.created_at).fromNow(true)} ago
                                </span>
                              </h5>
                              <p className="m-0">
                                <a
                                  href="#"
                                  className="community_detail_page_heading_2_a_user"
                                >
                                  @{item.user_name}
                                </a>
                              </p>
                            </div>
                          </Stack>
                          <p
                            className="community_detail_page_heading_2_a"
                            dangerouslySetInnerHTML={{
                              __html: item.discussion,
                            }}
                          ></p>
                          <hr className="bg-secondary" />
                        </div>
                      );
                    })}
                  </Container>
                  <div className="text-center">
                    <Button
                      variant="outline"
                      className="community_detail_page_button_1"
                      onClick={(e) => {
                        if (isViewAll) {
                          setIsViewAll(false);
                        } else {
                          setIsViewAll(true);
                        }
                      }}
                    >
                      View All
                    </Button>
                  </div>
                </div>
              </section>

              <section className="communityAct">
                <div className="community_detail_page">
                  <h1 className="community_detail_page_heading_1">
                    <span className="community_detail_page_heading_1_span">
                      Community Acts
                    </span>
                  </h1>
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className={
                          communitydata.user_id === Number(userId) && edit_type
                            ? "col-md-9"
                            : "col-md-12 "
                        }
                      >
                        <div className="py-5 pt-0">
                          <div className="commity-slider">
                            <SlickSlide
                              onClick={(actId) => onhandleCommunity(actId)}
                              communityact={communityact}
                              isCommunity={isCommunity}
                              loadingchap={loadingchap}
                              setcommunity={setcommunity}
                              communitydata={communitydata}
                              setCommunityact={setCommunityact}
                            />
                          </div>
                        </div>
                      </div>
                      {communitydata.user_id === Number(userId) && edit_type ? (
                        <>
                          <div
                            className="col-md-3"
                            onClick={() => handleClickOpen("addnewact")}
                          >
                            <div className="add-new-act">
                              <Stack
                                direction="column"
                                spacing={1}
                                className="create-edit-member"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <IconButton
                                  // onClick={() => handleClickOpen("addnewact")}
                                  disableRipple="false"
                                  sx={{ p: 0 }}
                                >
                                  {plusIcon}
                                </IconButton>
                                <span className="add-member-text">
                                  Add New Act
                                </span>
                              </Stack>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </section>
              {isCommunity ? (
                <section className="community_detail_chapter">
                  <div className="common-heading lg-3 mt-2">
                    <span>CHAPTERS </span>
                  </div>

                  {(isLoggedIn && communitydata.user_id === Number(userId)) ||
                    showChkjoinUser ||
                    (communitydata.user_id === Number(userId) && edit_type) ? (
                    <div className="text-center pb-2">
                      {community_id ? (
                        <button
                          className="btn btn-outline-success px-5 "
                          onClick={() => handleClickOpen("addchapter")}
                        >
                          Create a New Chapter
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    container
                    spacing={2}
                    justifyContent="center"
                    className="mt-2 ps-5"
                    style={{ backgroundColor: "#e8fce8", padding: "20px 0px" }}
                  >
                    <div className="">
                      <div className="commity-slider slicksslider ">
                        {loadingchap ? (
                          <div
                            className="sweet-loading loder_loader"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PulseLoader
                              color="#36d7b7"
                              size={50}
                              speedMultiplier={2}
                              loadingchap={loadingchap}
                            />
                          </div>
                        ) : (
                          <Slider
                            {...settings}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {pendingchapters.map((item, index) => (
                              // <div className="col mb-4">
                              <Card
                                className={
                                  ischapter === true && chapterId === item.id
                                    ? "select_act_border mycommunity_card_card"
                                    : "mycommunity_card_card"
                                }
                                onClick={() => onhandleChapter(item.id)}
                                variant="false"
                                sx={{
                                  // width: "277px",
                                  borderRadius: "24px",
                                  boxShadow:
                                    "0px 9px 20px rgba(167, 167, 167, 0.4)",
                                }}
                                key={index}
                                style={{ position: "relative" }}
                              >
                                {communitydata.user_id === Number(userId) ? (
                                  <Dropdown className="deletenew">
                                    <Dropdown.Toggle className="deletenew_toggle">
                                      {threeDot}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="deletenew_menu ">
                                      <Dropdown.Item
                                        onClick={() => DeleteChapter(item.id)}
                                        className="deletenew_item chapter_menu"
                                      >
                                        Delete Chap
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  ""
                                )}
                                <CardMedia
                                  component="img"
                                  image={`${CHAPTERIMG_URL}${item.images}`}
                                  alt="green iguana"
                                  className="mycommunity_card_img"
                                />

                                <div className="p-3 act-content">
                                  <h3>{item.chapter_name}</h3>
                                  <p> @{item.user}</p>
                                  <p>{item.description}</p>
                                </div>
                                <div>
                                  <Button
                                    variant="primary"
                                    className="slick_slide_btn ms-2 "
                                  >
                                    {item?.eventCount} Events
                                  </Button>
                                </div>
                              </Card>
                              // </div>
                            ))}
                          </Slider>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
              {ischapter && isCommunity ? (
                <section className="">
                  <div className="text-center bg-white pb-3">
                    <div className=" create-event-text my-2 "> EVENTS </div>
                    {(isLoggedIn && showJoinUserStatus) ||
                      communitydata.user_id === Number(userId) ||
                      showChkjoinUser ? (
                      <div>
                        <button
                          className="btn btn-outline-success"
                          onClick={() => setShow(true)}
                        >
                          CREATE NEW EVENT
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {loadingevent ? (
                    <div
                      className="sweet-loading loder_loader"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PulseLoader
                        color="#36d7b7"
                        size={50}
                        speedMultiplier={2}
                        loadingchap={loadingchap}
                      />
                    </div>
                  ) : (
                    <div className="bg-light-green py-3">
                      {eventshow.map((item) => (
                        <Container>
                          <div className="border border-success  myevents-create  my-2  shadow">
                            <div className="row">
                              <div className="col-lg-10 ps-4">
                                <p className="pt-3">
                                  <span className="event-create-heading">
                                    {" "}
                                    EVENT NAME ::
                                  </span>{" "}
                                  <span className="event-create-content">
                                    {" "}
                                    {item.event_name}{" "}
                                  </span>
                                </p>
                                <p className="">
                                  <span className="event-create-heading">
                                    {" "}
                                    WRITTEN BY ::
                                  </span>{" "}
                                  <span className="event-create-content">
                                    {" "}
                                    {item.user}
                                  </span>
                                </p>
                                <p className="d-flex">
                                  <div className="event-create-heading">
                                    {" "}
                                    DESCRIPTION::
                                  </div>{" "}
                                  <div className="event-create-content">
                                    {item.description}{" "}
                                  </div>
                                </p>
                              </div>
                              {(isLoggedIn && showJoinUserStatus) ||
                                communitydata.user_id === Number(userId) ||
                                showChkjoinUser ? (
                                <div className="col-lg-2 main_updown">
                                  <div className="updownbutton">
                                    <div className="mt-1">
                                      <img
                                        src={Upbutton}
                                        className="updown"
                                        alt=""
                                        onClick={() =>
                                          counter("1", "0", item.id)
                                        }
                                      />
                                      <p className="text-center number_updown mb-2">
                                        {" "}
                                        {upVote.find(
                                          (el) => el.event_id === item.id
                                        )?.total || 0}
                                      </p>
                                      <img
                                        src={Downbutton}
                                        className="updown"
                                        alt=""
                                        onClick={() =>
                                          counter("0", "1", item.id)
                                        }
                                      />
                                      <p className="text-center">
                                        {" "}
                                        {downVote.find(
                                          (el) => el.event_id === item.id
                                        )?.total || 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </Container>
                      ))}
                    </div>
                  )}
                </section>
              ) : null}
            </>
          ) : (
            <div className="notFound">
              <h1>Communtity disabled please contact administrator</h1>
            </div>
          )}
        </>
      )}
      {communitydata.user_id === Number(userId) && edit_type ? (
        <section
          className="text-center delete_community_button "
          style={{ backgroundColor: "#e8fce8" }}
        >
          <button
            className="btn btn-outline-success"
            onClick={handleDeleteCommunity}
          >
            Delete Community
          </button>
        </section>
      ) : (
        ""
      )}
      <Footer />
      <ViewModal
        handleClickOpen={handleClickOpen}
        open={open}
        onCancle={handleClose}
        onSubmit={() => setIsSubmitting(true)}
        title={
          open === "addmember"
            ? "add member"
            : open === "addnewact"
              ? "Add Act"
              : open === "addchapter"
                ? "Add Chapter"
                : open === "editchapter"
                  ? "Edit Chapter"
                  : open === "removechapter"
                    ? "Remover Chapter"
                    : null
        }
        cancleText={
          open === "addmember"
            ? "Cancel"
            : open === "addnewact"
              ? "Cancel"
              : open === "addchapter"
                ? "Cancel"
                : open === "editchapter"
                  ? "Cancel"
                  : open === "removechapter"
                    ? "Cancel"
                    : null
        }
        submitText={
          open === "addmember"
            ? "Send request"
            : open === "addnewact"
              ? "Add"
              : open === "addchapter"
                ? "Add"
                : open === "editchapter"
                  ? "Submit"
                  : open === "removechapter"
                    ? "Submit"
                    : null
        }
        content={
          <>
            {/* {open === "addmember" ? (
              <AddMember
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                showmembers={showmembers}
              />

            ):  */}
            {open === "editchapter" ? (
              <EditChapter
                chapterid={chapterId}
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
              />
            ) : open === "addchapter" ? (
              <AddChapter
                handleClose={handleClose}
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                pendingchapter={pendingchapter}
                actid={actid}
              />
            ) : open === "removechapter" ? (
              <RemoveChapter
                chapterid={chapterId}
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
              />
            ) : open === "addnewact" ? (
              <AddNewAct
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                handleClose={handleClose}
                actlist={setcommunity}
              />
            ) : open === "addmember" ? (
              <AddMember
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                showmembers={showmembers}
                handleClose={handleClose}
              />
            ) : null}
          </>
        }
      />
      <Communitybaner
        show={modalShow}
        editcommunity={editcommunity}
        onHide={() => setModalShow(false)}
        imghome3={imghome3}
        setImghome3={setImghome3}
        setOnsave={setOnsave}
      />
      <AddEvents
        show={show}
        onHide={() => {
          setShow(false);
          setIsSubmitting(true);
        }}
        actid={actid}
        chapterId={chapterId}
        eventlist={eventlist}
      />
      <Modal
        centered
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        className="p-5"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <h5 className="text-center mb-3 mt-2">
            Are you sure you want to remove {username} ? They will no longer be
            able to contribute to this community ?
          </h5>
          <div className="d-flex justify-content-center mt-5">
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" className="ms-3" onClick={deleteMember}>
              Remove
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommunityDetailPage;
