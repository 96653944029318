import { React, useState, useRef, useMemo, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import upload from "../../Assets/images/upload.png";
// import { Upload } from "antd";
// import ImgCrop from "antd-img-crop";
// import "antd/dist/antd.css";
import ImgCropper from "./ImgCropper";

const AddBanner = (props) => {
  const [imghome, setImghome] = useState("");
  // const [imghomeget, setImghomeget] = useState("");
  const [title, setTitle] = useState("");
  const [mymodalShow, setMyModalShow] = useState(false);
  const [link, setLink] = useState("");

  // console.log(props.adsid, "id");

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  //____________________API_______________________________//
  const addeditevent = (e) => {
    e.preventDefault(e);

    var data = new FormData();
    data.append("title", title);
    data.append("image", imghome);
    data.append("link", link);

    var config = {
      method: "post",

      url: `${Base_URL}admin/addads`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response);
        props.showallAdds();
        props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  //____________________API_______________________________//

  //   useEffect(() => {
  //     addeditevent();
  //   }, []);

  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Ads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">
            {props.adsid ? "Update" : "Add Advertisement"}
          </h1>
          <Form>
            <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: "11px",
              }}
            >
              Title
            </label>
            <select
              className="form-select mb-4"
              aria-label="Default select example"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <option selected>Open this select menu</option>
              <option value="1">Home</option>
              <option value="2">About</option>
              <option value="3">Contact</option>
              <option value="4">Community</option>
            </select>
            {/* ///////////////////////////////IMAGE CROP/////////////////////////////////////// */}

            <div className="update-img-icon position-relative ">
              <img
                onClick={() => setMyModalShow(true)}
                src={imghome ? imghome : upload}
                // className="fitimg"
              />
              <Form.Group className="mb-2">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Enter Title"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Form.Group>
              <div className="text-center">
                <Button
                  variant="primary "
                  //   type="submit"
                  style={{ marginTop: "10%" }}
                  onClick={() => props.onHide()}
                >
                  Cancle
                </Button>
                <Button
                  variant="primary ms-3"
                  //   type="submit"
                  style={{ marginTop: "10%" }}
                  onClick={addeditevent}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/* ////////////////////////////////////////////////////////////////////// */}
          </Form>
        </Modal.Body>
      </Modal>
      <ImgCropper
        show={mymodalShow}
        imghome={imghome}
        setImghome={setImghome}
        // APICALL={APICALL}
        onHide={() => setMyModalShow(false)}
      />
    </>
  );
};

export default AddBanner;
