import React from 'react'

const AdminDashboard = () => {
    return (
        <div>
            <div className='test-center'>
                <h1> Dashboard</h1>
            </div>
        </div>
    )
}

export default AdminDashboard