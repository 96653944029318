import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Dropdown, ListGroup, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { CiSearch } from "react-icons/ci";
import { Link, NavLink } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";
import profileicon from "../../Assets/images/favicon.png";
import Chatimage from "../../Assets/images/Rectangle.png";
import { useNavigate } from "react-router-dom";
import Image8 from "../../Assets/images/image-8.png";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Base_URL,
  COMMUNITYSMALLIMG_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, google, facebook } from "../../firebase";
import { vectordown } from "../../utils/svg.file";
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../../features/counter/messageCountSlice";
import moment from "moment";
import { Avatar } from "@mui/material";

const Headers = () => {
  // redux selector

  const messageCount = useSelector((state) => state.messageCount.messageCount);
  let navigate = useNavigate();
  // const isLoggedIn = localStorage.getItem("userToken");
  const [search, setSearch] = useState("");
  const [firstcart, setFirstcart] = useState("");
  const [isSuggestedSearch, setIsSuggestedSearch] = useState(false);
  const [imgprofile, setImgprofile] = useState();
  const [searchData, setSearchData] = useState([]);
  const [counts, setChatCount] = useState("0");
  const [notifation, setnotifation] = useState([]);
  const [selectedTab, setSelectedTab] = useState("HOME");
  // console.log(selectedTab, "active");
  const notification = () => {
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}notification`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "responce hahah")
        setnotifation(response?.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSearch = (value) => {
    // console.log(value, "tesg");
    var data = new FormData();
    data.append("text", value);

    var config = {
      method: "post",
      url: `${Base_URL}searchcommunitylatter`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setSearchData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleSearch = (value) => {
    setIsSuggestedSearch(true);
    onSearch(value);
  };

  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");

  const showprofile = () => {
    var config = {
      method: "post",
      url: `${Base_URL}user-details`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.success.name, "profile_img11221111")
        setFirstcart(response.data.success.user_name)
        setImgprofile(response.data.success.image);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoggedIn) showprofile();
  }, []);

  const logout = () => {
    signOut(auth)
      .then((res) => {
        localStorage.clear();

        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const readmessage = () => {
    // let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}allreadnotification`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      // data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload(false);
        notification();

        // chatcount()
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // console.log({ user });
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // ...
    } else {
      // console.log("user SignOut");
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    notification();
  }, []);
  const showcommunity = () => {
    localStorage.removeItem("community_id");
    navigate("/create-communities");
  };
  const ref = useRef();
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsSuggestedSearch(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Navbar className="header_navbar" expand="lg">
        <Container>
          <Navbar.Brand href="/" className="header_navbar_brand">
            OW
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-lg-center align-items-start">
              <Form className="d-flex">
                <div className="header_search_input d-lg-block d-md-block d-none">
                  <Form.Control
                    type="search"
                    placeholder="Search for Communities"
                    aria-label="Search"
                    onChange={(e) => {
                      onHandleSearch(e.target.value);
                    }}
                    // onClick={onHandleSearch}
                    className="header_search_form_input"
                  />
                  <CiSearch className="header_search_icon" />
                </div>
                {isSuggestedSearch ? (
                  <>
                    <div className="searchbar_search" ref={ref}>
                      <div className="searchscroll">
                        <PerfectScrollbar>
                          {searchData.length > 0 ? (
                            <>
                              {" "}
                              {searchData.map((item, index) => (
                                <Link to={`/community-details/${item.id}`}>
                                  <Stack
                                    direction="horizontal"
                                    gap={4}
                                    className={`searchbar_underline ${item.borderNone}`}
                                  >
                                    <div>
                                      <img
                                        src={
                                          item.image
                                            ? `${COMMUNITYSMALLIMG_URL}${item.image}`
                                            : Image8
                                        }
                                        className="searchbar_search_img"
                                      />
                                    </div>
                                    <div className="">
                                      <span className="searchbar_search_title">
                                        {item.title}
                                      </span>{" "}
                                      <p className="searchbar_search_text">
                                        {item.about}
                                      </p>
                                    </div>
                                  </Stack>
                                </Link>
                              ))}
                            </>
                          ) : (
                            <h1>No Data Found</h1>
                          )}
                        </PerfectScrollbar>
                      </div>
                      <div className="text-center mb-2 border-top">
                        <button
                          className="searchbar_search_btn"
                          onClick={() => navigate("/search/1")}
                        >
                          View All
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </Form>

              <Link
                to="/home"
                // onClick={() => setSelectedTab("HOME")}
                className={`header_navbar_link_chat nav-link ${window.location.href.includes(
                  "home"
                ) && "active"} `}
              >
                HOME
              </Link>
              {isLoggedIn ? (
                <>
                  <NavDropdown
                    as="a"
                    title={
                      <>
                        Communities <span>{vectordown}</span>
                      </>
                    }
                    // onClick={() => setSelectedTab("ALLCOMMNITY")}
                    id="basic-nav-dropdown"
                    className={`header_navbar_dropdown text-decoration-none commom-space fixedbar ${window.location.href.includes(
                      "my-communities"
                    ) && "active"} `}
                  >
                    {isLoggedIn ? (
                      <NavDropdown.Item
                        // href="/my-communities"
                        onClick={() => {
                          navigate("/search/1");
                        }}
                        className="header_navbar_navdropdown_item"
                      >
                        All Communities
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {isLoggedIn ? (
                      <NavDropdown.Item
                        // href="/my-communities"
                        onClick={() => {
                          navigate("/my-communities");
                        }}
                        className="header_navbar_navdropdown_item"
                      >
                        My Communities
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                    {isLoggedIn ? (
                      <NavDropdown.Item
                        onClick={showcommunity}
                        // href="/create-communities"
                        className="header_navbar_navdropdown_item"
                      >
                        Create Communities
                      </NavDropdown.Item>
                    ) : (
                      ""
                    )}
                  </NavDropdown>
                </>
              ) : (
                <Link
                  to="/search/1"
                  className={`header_navbar_link_chat nav-link commom-space ${window.location.href.includes(
                    "search/"
                  ) && "active"}`}
                >
                  Communities
                </Link>
              )}

              {isLoggedIn ? (
                <>
                  <NavLink
                    activeClassName="active"
                    to="/chat"
                    // onClick={() => setSelectedTab("MESSAGES")}
                    className={`header_navbar_link_chat nav-link commom-space ${window.location.href.includes(
                      "chat"
                    ) && "active"}`}
                  >
                    Messages
                    {messageCount.length !== 0 ? (
                      <span className="count">{messageCount}</span>
                    ) : (
                      <span className="count">0</span>
                    )}
                  </NavLink>
                  <Link
                    to="/invits"
                    className={`header_navbar_link_chat nav-link commom-space ${window.location.href.includes(
                      "invits"
                    ) && "active"}`}
                  >
                    Invites
                  </Link>
                  <Dropdown as="a" className="commom-space">
                    <Dropdown.Toggle
                      className="notifation"
                      as="a"
                      variant="false"
                      id="dropdown-basic"
                    >
                      <FaRegBell />
                      <sup className="count">{notifation.length}</sup>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="rightsidemenu">
                      <div className="notification-ui_dd-header">
                        <h3>Notification</h3>
                        <span className="mark-read" onClick={() => {
                          readmessage()
                          // window.location.reload(false);
                        }}>
                          Mark all as read
                        </span>
                      </div>

                      <ListGroup variant="flush">
                        {notifation.map((item, index) => (
                          <ListGroup.Item
                            key={index}
                            as="a"
                            href="#"
                            className={`item-hover ${index === 0 ? "selected" : ""
                              }`}
                          >
                            <Stack
                              gap={2}
                              direction="horizontal"
                              className="align-items-start"
                            >
                              <div className="avatar">
                                <img src={profileicon} alt="" className="notification_logo" />
                              </div>
                              <div className="notification-list_detail">
                                {/* <h3>
                                  Jenny Wilson <span>2 min ago</span>
                                </h3> */}
                                <p>{item.message} on  {moment(item.created_at).format('MMMM Do YYYY, h:mm a')}</p>
                              </div>
                            </Stack>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown as="a" className="commom-space ms-lg-4">
                    <Dropdown.Toggle as="div" className="profiledropdown d-flex align-items-center ">
                      {imgprofile ?
                        <img
                          src={

                            `${PROFILEIMG_URL}${imgprofile}`

                          }
                          // {Chatimage}
                          alt=""
                          className="header_navbar_img"
                        /> : <Avatar sx={{ bgcolor: "green" }}>{firstcart?.[0]?.toUpperCase()}</Avatar>}
                      <span className="ms-2">{vectordown}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="profile-dropdown">
                      <Dropdown.Item
                        className="profile_dropdown_item"
                        // href="#"S
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        My Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="profile_dropdown_item"
                        // href="#"
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        Dashboard
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={logout}
                      // onClick={() => {
                      //   localStorage.clear();

                      //   // Cookies.remove("SID");
                      //   navigate("/");
                      //   // signOutGoogle(() => {

                      //   // console.log("LoggedOut");

                      //   // });

                      //   // window.location.reload();
                      // }}
                      >
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                  <Link
                    to="/about"
                    className={`header_navbar_link_chat nav-link commom-space ${window.location.href.includes(
                      "about"
                    ) && "active"}`}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/contact"
                    className={`header_navbar_link_chat nav-link commom-space ${window.location.href.includes(
                      "contact"
                    ) && "active"}`}
                  >
                    Contact Us
                  </Link>
                  <Link to="/signin" className="text-uppercase">
                    <Button className="header_nabvar_button">Sign In</Button>
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Headers;
