import "../../../Assets/Css/admin.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// import { async } from "emoji-mart";
import Ow from "../../../Assets/images/ow.png";

// const defaultSrc =
//   "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const Cropmodal = (props) => {
  const [image, setImage] = useState(Ow);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  //   const [data, setData] = useState();

  //   console.log(data, "hhh");
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      props.setPest(cropper.getCroppedCanvas().toDataURL());

      // await props.APICALL("", cropper.getCroppedCanvas().toDataURL());
      props.onHide();
    }
  };

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div style={{ width: "100%" }}>
            <input type="file" onChange={onChange} />
            {/* <button>Use default img</button> */}
            <br />
            <br />
            <Cropper
              style={{ height: 500, width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              aspectRatio={4 / 1}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
          <div>
            {/* <div className="box" style={{ width: "50%", float: "right" }}>
              <h1>Preview</h1>
              <div
                className="img-preview"
                style={{ width: "100%", float: "left", height: "300px" }}
              />
            </div> */}
            {/* <div
              className="box"
              style={{ width: "50%", float: "right", height: "300px" }}
            > */}
            <h1>
              <button className="btn btn-primary" onClick={getCropData}>
                Crop Image
              </button>
            </h1>
            {/* <img style={{ width: "100%" }} src={cropData} alt="cropped" /> */}
          </div>
        </div>
        <br style={{ clear: "both" }} />
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default Cropmodal;
