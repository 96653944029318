import { React, useState, useEffect } from "react";
import Headers from "../Layouts/Headers";
import "../../Assets/Css/dashboard.css";
import { Container } from "react-bootstrap";
import Layer2 from "../../Assets/images/Layer 2.png";
import ContentCards from "../contentCards";
import Footer from "../Layouts/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ADS_URL, Base_URL } from "../../utils/serverUrl";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../features/preLoaderSlice'
// import { getshowAds, getshowWelcomenote } from "../newapi/auth"

const Home = () => {

  // redux dispatch
  const dispatch = useDispatch()

  const [addimg, setADSimg] = useState([]);
  const [link, setLink] = useState("");
  const [allwelcome, setAllWelcome] = useState([]);
  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  let navigate = useNavigate();

  const showAds = () => {
    var config = {
      method: "get",
      url: `${Base_URL}getads`,

    };

    axios(config)
      .then(function (response) {
        setADSimg(response.data.data);
        setLink(response.data.data.link);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    showAds();
  }, []);


  const showWelcomenote = () => {
    dispatch(setIsLoading(true))
    var data = new FormData();

    var config = {
      method: "Get",

      url: `${Base_URL}showwelcomeText`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        setAllWelcome(response.data.data);
        dispatch(setIsLoading(false))

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showWelcomenote();
  }, []);
  // const getads = async () => {
  //   const response = await getshowAds();
  //   if (response.remote == "success") {
  //     setADSimg(response.data.data);
  //     setLink(response.data.data.link);
  //   } else {

  //     console.log(response.errors);

  //   }
  // }
  // const showWelcomenote = async () => {
  //   dispatch(setIsLoading(true))
  //   const response = await getshowWelcomenote();
  //   if (response.remote == "success") {
  //     setAllWelcome(response.data.data);
  //     dispatch(setIsLoading(false))



  //   } else {
  //     // setErrors(response.errors.errors);
  //     console.log(response.errors);
  //     // setIsLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   showWelcomenote();
  //   getads()
  // }, []);




  // const showcommunity = () => {
  //   localStorage.removeItem("community_id");
  //   navigate("/create-communities");
  // };

  return (
    <>

      <Headers />

      <section>
        <Container>
          <div className="home_home_img">
            <h1 className="home_heading ">Welcome to OverWrite!</h1>
            <div className="row">



              {allwelcome?.map((item) => (
                <>
                  <div className="col-lg-6 home_para_first"

                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }} >

                  </div>


                </>
              ))}

            </div>

          </div>
        </Container>
      </section>
      <section className="newestsection">
        <ContentCards />
      </section>



      {/* __________google adds  start ________  */}
      <section>
        <Container>
          <div>


            {addimg?.every((image) => image.title !== '1') ?
              <img
                src={Layer2}
                className="w-100 mb-4"
                alt=""
                style={{ height: "150px", objectFit: "100% 100%" }}
              /> :
              addimg?.map((item) => {
                return item.title === "1" &&
                  <a
                    href={`//${item.link ? item.link : ""}`}
                    className="footer_icon_social"
                    target="_blank"
                    rel="noreferrer"
                  >

                    <img
                      src={item.image ? `${ADS_URL}${item.image}` : Layer2}
                      className="w-100 mb-4"
                      alt=""
                      style={{ height: "150px", objectFit: "100% 100%" }}
                    />
                  </a>

              })}

          </div>
        </Container>
      </section>


      <section>
        <div>
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Home;
