exports.Socketurl = "https://digimonk.net/";

exports.Base_URL = "https://api.ovrwt.xyz/api/";
exports.img_URL =
  "https://api.ovrwt.xyz/images/communityact/";

exports.CHAPTERIMG_URL =
  "https://api.ovrwt.xyz/images/chapters/";
exports.EVENTIMG_URL = "https://api.ovrwt.xyz/images/events/";
exports.CHAPTERIMG_URL =
  "https://api.ovrwt.xyz/images/chapters/";
exports.EVENTIMG_URL = "https://api.ovrwt.xyz/images/events/";
exports.COMMUNITYSMALLIMG_URL =
  "https://api.ovrwt.xyz/images/communityimgs/";
exports.COMMUNITYBIGIMG_URL =
  "https://api.ovrwt.xyz/images/communityimgs/banners/";
exports.PROFILEIMG_URL =
  "https://api.ovrwt.xyz/images/profileimgs/";
exports.CHATIMG_URL = "https://api.ovrwt.xyz/storage/";
exports.ADS_URL = "https://api.ovrwt.xyz/images/ads/";
