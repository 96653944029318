import React from "react";
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import { Base_URL } from "../../utils/serverUrl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Swal from "sweetalert2";

const TextEditor = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const communitydata = props.communitydata;
  const community_id = props.community_id;
  const checkmembers = props.checkmembers;
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token && token.replace(/^"(.+)"$/, "$1");
  let userid = localStorage.getItem("userId");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const disscussion = () => {
    // console.log(content, "my content");
    let err = false;
    if (content.length === 0 || content === "<p><br></p>") {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter Content ",
        // text: "Required field",
      });
    }
    if (!err) {
      var data = new FormData();
      data.append("community_id", community_id);
      data.append("user_id", userid);
      data.append("discussion", content);

      var config = {
        method: "post",
        url: `${Base_URL}adddisscussion`,
        headers: {
          Authorization: "Bearer " + isLoggedIn,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setContent("");
          props.discussionclick();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };





  return (
    <>


      <ReactQuill
        style={{ background: "#ffffff", height: "200px" }}
        placeholder={(isLoggedIn) ? "" : "YOU ARE NOT PART OF THIS COMMUNITY OR LOGIN  TO  POST A COMMENT"}
        value={content}
        modules={modules}
        formats={formats}
        onChange={(txt) => setContent(txt)}
        readOnly={!(isLoggedIn && (checkmembers || communitydata.user_id === Number(userid)))}
      />


      <div className="text-end position-relative">
        {isLoggedIn && checkmembers || communitydata.user_id === Number(userid) ?
          <button className="btn btn-primary commnet" onClick={disscussion}>
            Comment
          </button>
          : null}
      </div>


    </>
  );
};
export default TextEditor;
