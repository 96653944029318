import { React, useState, useRef, useMemo, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import upload from "../../Assets/images/upload.png";

const Updatefooter = (props) => {
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [twitter, setTwitter] = useState("");
  const [description, setDescription] = useState("");
  const [copyright, setCopyright] = useState("");
  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  // console.log(props.footerId, "footer id");
  const showfooter = () => {
    var data = new FormData();
    var config = {
      method: "get",

      url: `${Base_URL}admin/showfooterid/${props.footerId}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setFacebook(response.data.data.facebook);
        setInstagram(response.data.data.instagram);
        setPinterest(response.data.data.pinterest);
        setTwitter(response.data.data.twitter);
        setDescription(response.data.data.text);
        setCopyright(response.data.data.copy);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (props.footerId) {
      showfooter();
    }
  }, [props.footerId]);

  const updatefooter = (e) => {
    var data = new FormData();
    data.append("id", props.footerId);
    data.append("text", description);
    data.append("facebook", facebook);
    data.append("instagram", instagram);
    data.append("pinterest", pinterest);
    data.append("twitter", twitter);
    data.append("copy", copyright);

    var config = {
      method: "post",

      url: `${Base_URL}admin/updatefooter`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setFacebook("");
        setInstagram("");
        setPinterest("");
        setTwitter("");
        setDescription("");
        setCopyright("");
        props.showAllfooter();
        props.onHide();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   console.log(props.footerId, "id pf footer");
  return (
    <>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">Update Footer Content</h1>
          <Form>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <label
                  for="exampleInputText"
                  class="form-label edit_profile_lebal"
                >
                  Facebook Link
                </label>
                <input
                  type="text"
                  className="form-control edit_profile_input_area"
                  id="exampleInputText"
                  aria-describedby="emailHelp"
                  placeholder="please enter link"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label
                  for="exampleInputtext"
                  className="form-label edit_profile_lebal"
                >
                  Instagram Link
                </label>
                <input
                  type="text"
                  className="form-control edit_profile_input_area"
                  id="exampleInputtext"
                  placeholder="Please enter link"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}

                // onChange={(e) => setProfilename(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label
                  for="exampleInputText"
                  class="form-label edit_profile_lebal"
                >
                  Pinterest Link
                </label>
                <input
                  type="text"
                  className="form-control edit_profile_input_area"
                  id="exampleInputText"
                  aria-describedby="emailHelp"
                  placeholder="Please enter link"
                  value={pinterest}
                  onChange={(e) => setPinterest(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label
                  for="exampleInputtext"
                  className="form-label edit_profile_lebal"
                >
                  Twitter Link
                </label>
                <input
                  type="text"
                  className="form-control edit_profile_input_area"
                  id="exampleInputtext"
                  placeholder="Please enter link"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}

                // onChange={(e) => setProfilename(e.target.value)}
                />
              </div>
            </div>

            {/* ///////////////////////////////IMAGE CROP/////////////////////////////////////// */}

            <div className="update-img-icon position-relative ">
              <div className="mb-3">
                <label
                  for="exampleFormControlTextarea1"
                  className="form-label edit_profile_lebal"
                >
                  Description
                </label>
                <textarea
                  className="form-control edit_profile_input_area"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}

                // onChange={(e) => setProfilebio(e.target.value)}
                ></textarea>
              </div>

              <Form.Group className="mb-2">
                <Form.Label>Copy Right Text </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Enter Copy Right"
                  value={copyright}
                  onChange={(e) => setCopyright(e.target.value)}
                />
              </Form.Group>
              <div className="text-center">
                <Button
                  variant="primary "
                  //   type="submit"
                  style={{ marginTop: "10%" }}
                  onClick={() => (
                    setFacebook(""),
                    setInstagram(""),
                    setPinterest(""),
                    setTwitter(""),
                    setDescription(""),
                    setCopyright(""),
                    props.onHide()
                  )}
                >
                  Cancle
                </Button>
                <Button
                  variant="primary ms-3"
                  //   type="submit"
                  style={{ marginTop: "10%" }}
                  onClick={updatefooter}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/* ////////////////////////////////////////////////////////////////////// */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Updatefooter;
