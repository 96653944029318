import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";

const EditChapter = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  const handlar = () => {
    setDescription("");
    setTitle("");
    props.onHide();
  };

  const Showchapter = () => {
    var config = {
      method: "get",
      url: `${Base_URL}admin/chapter/${props.chapterid}`,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
        // console.log(response.data.data, "chapters");
        setTitle(response.data.data.chapter_name);
        setDescription(response.data.data.description);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    Showchapter();
    // return () => {
    //   setDescription("");
    //   setTitle("");
    // };
  }, [props.chapterid]);

  const Chapterupdate = () => {
    var data = new FormData();
    data.append("chapter_name", title);
    data.append("description", description);

    var config = {
      method: "POST",
      url: `${Base_URL}admin/update-chapter/${props.chapterid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response, "update chapter");
        props.showChapter();
        props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">chapter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="text-center">Edit Chapter</h1>
        <Form>
          <Form.Group className="">
            <Form.Label>Title</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            <Button onClick={() => handlar()}>Cancel</Button>
            <Button
              variant="primary"
              className="ms-3"
              onClick={Chapterupdate}
              //   onClick={Communityupdate}
            >
              Update Chapter
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditChapter;
