import React from "react";
import { Button, Card } from "react-bootstrap";
import Slider from "react-slick";
import Silverfrog from "../Assets/images/silverfrog.png";
import "../Assets/Css/communitydetailpage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Base_URL, img_URL } from "../utils/serverUrl";
import { useEffect } from "react";
import { Delete, Height } from "@mui/icons-material";
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import axios from "axios";
import Swal from "sweetalert2";
import { threeDot } from "../utils/svg.file";
import Dropdown from 'react-bootstrap/Dropdown';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Test from "./test.jsx";
import { Grid } from "@mui/material";
import { useState } from "react";

const SlickSlide = (props) => {
  const [isCommunity, setIsCommunity] = React.useState("");
  const [items, setItems] = React.useState(props.communitydata);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const communityact = props.communityact;
  const setcommunity = props.setcommunity;
  const communitydata = props.communitydata;
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  let userId = localStorage.getItem("userId");

  // console.log(isCommunity, "valuetttttt");

  useEffect(() => {
    props.onClick(communityact?.[0]?.id)
    setIsCommunity(communityact?.[0]?.id)
  }, [])
  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowBackIcon />
        </button>
      </div>
    );
  };
  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowForwardIcon />
        </button>
      </div>
    );
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    rtl: true,
    // centerMode: true,
    accessibility: true,
    touchMove: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };



  const [activeId, setActiveId] = React.useState(null)
  const handleDragStart = React.useCallback((event) => {
    setActiveId(event.active.id);
    props.onClick(event.active.id)
  }, []);
  const handleDragEnd = React.useCallback((event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      props.setCommunityact((items) => {
        // const oldIndex = items.indexOf(active.id);
        // const newIndex = items.indexOf(over!.id);
        const oldIndex = items.findIndex((item) => item.id === active.id);
        // const newIndex = items.findIndex((item) => item.id === over!.id);
        const newIndex = items.findIndex((item) => over && item.id.toString() === over.id.toString());

        return arrayMove(items, oldIndex, newIndex);
      });
    }

  }, []);




  const handleDragCancel = React.useCallback(() => {
    setActiveId(null);
  }, []);

  const [activeCommity, setActiveCommity] = React.useState()
  useEffect(() => {

    if (activeId) {
      const found = communityact.find((item) => {
        return item.id === activeId
      })
      if (found) {
        setActiveCommity(found)
      }
    }
  }, [activeId])

  const DeleteAct = (id) => {
    Swal.fire({
      // title: `Are you sure you want to delete this act ?`,
      text: "Are you sure you want to delete this act ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var data = new FormData();
        data.append("id", id);

        var config = {
          method: "delete",
          url: `${Base_URL}delete-act/${id}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },

          data: data,
        };
        axios(config)
          .then(function (response) {
            setcommunity();
          })
          .catch(function (error) {
            console.log(error);
          });
        // Swal.fire("You have left this community.");
      }
    });
  };
  return (
    <><DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}>

      <SortableContext items={communityact} strategy={rectSortingStrategy}>
        <Slider {...settings}>
          {/* <Grid container spacing={4} > */}
          {communityact.map((item, index) => {
            return (
              <div
                onClick={() => {
                  props.onClick(item.id)
                  setIsCommunity(item.id)
                  // console.log("gaurav")
                }}
              >

                <Test item={item} index={index} img_URL={img_URL} setIsCommunity={setIsCommunity} setcommunity={setcommunity} communitydata={communitydata} className={isCommunity === item.id && props.isCommunity ? "select_act_border" : "slidercard_card"} />
              </div>
            )
          })}
          {/* </Grid> */}

        </Slider>
      </SortableContext>

      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? <>

          <div style={{
          }}>

            <Card
              className="slidercard_card"
              // "slidercard_card"

              style={{ position: "relative" }}

            >
              {/* {communitydata.user_id === Number(userId) ?
                <Dropdown className="deletenew">
                  <Dropdown.Toggle className="deletenew_toggle" >
                    {threeDot}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="deletenew_menu ">
                    <Dropdown.Item onClick={() => DeleteAct(activeId)} className="deletenew_item "
                    >Delete Act</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
                : ""} */}

              <Card.Img
                variant="top"
                className="slider_card_img"
                src={activeCommity?.image ? `${img_URL}${activeCommity.image}` : ""}
              />

              <Card.Body>
                <Card.Title className="slider_card_title">
                  {activeCommity?.act_name}
                </Card.Title>
                <Card.Text className="slider_card_text">
                  {activeCommity?.description}
                </Card.Text>
                <Button variant="primary" className="slick_slide_btn">
                  {activeCommity?.count_act}Chapters
                </Button>
              </Card.Body>
            </Card>
          </div>
        </> : null}
      </DragOverlay>
    </DndContext>

    </>
  );
};

export default SlickSlide;
