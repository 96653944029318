import React, { useState } from "react";
import "../../Assets/Css/auth.css";
import { Form, Button } from "react-bootstrap";
import Abcd1 from "../../Assets/images/abcd1.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { async } from "@firebase/util";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import { crossicon } from "../../utils/svg.file";
import OTPInput from "otp-input-react";


const Otpverification = () => {
    const { state } = useLocation();
    const [OTP, setOTP] = useState("");
    const [store, setStore] = useState(state);
    const [error, setError] = useState("");
    let navigate = useNavigate();




    const otpcheck = (event) => {
        event.preventDefault();
        var data = new FormData();
        data.append("code", OTP);

        var config = {
            method: "Post",
            url: `${Base_URL}check-otp`,

            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.status === 200) {


                    navigate(
                        '/changepassword',
                        {
                            state: {
                                code: response.data.data.code,
                                reset_id: store
                            }

                        }
                    )
                } else {
                    setError("OTP Not Match");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <>
            <div className="signin">
                <div className="container">
                    <div className="signin_page">
                        <div className="position-relative">
                            <span onClick={() => navigate("/")}>{crossicon}</span>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="signin_form">
                                    <span className="signin_heading">FORGOT PASSWORD </span>
                                    <p className="signin_paragraph pt-2">
                                        Please enter your verification code.
                                    </p>
                                    <Form

                                    >

                                        <Form.Group className="mb-4" controlId="formBasicEmail">
                                            <Form.Label className="signin_form_label pb-3">
                                                VERIFICATION CODE
                                            </Form.Label>
                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={false} className="ms-3" />


                                        </Form.Group>




                                        {error ?
                                            <p style={{ color: "red" }}>{error}</p>
                                            : ""}
                                        <div className="text-center mb-3">
                                            <Button
                                                variant="primary"
                                                className="signin_btn_btn"
                                                // type="submit"
                                                onClick={otpcheck}

                                            >
                                                CONFIRM
                                            </Button>
                                        </div>

                                        <div className="signin_account_sign">
                                            <span>
                                                Don’t have an account?
                                                <a href="/signup" className="signin_tag_anchor">
                                                    Sign Up
                                                </a>
                                            </span>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div>
                                    <h1 className="signin_ow_head">OW</h1>
                                    <div className="signin_img">
                                        <img src={Abcd1} alt="" className="signin_image_abcd" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Otpverification