import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../Assets/Css/dashboard.css";
import { Base_URL } from "../../utils/serverUrl";
import {
  FooterFacebook,
  FooterTwitter,
  Linkedin,
  PinterestIcon,
} from "../../utils/svg.file";
import { Link, useNavigate } from "react-router-dom";

const Footer = ({ setcommunity }) => {
  const [cardArray, setcardArray] = useState([]);
  const [contactdata, setContactdata] = useState([]);
  const [footertdata, setFootertdata] = useState([]);

  // let tokenId = localStorage.getItem("userToken");
  // let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  let navigate = useNavigate();
  const fetchData = async () => {
    var config = {
      method: "get",
      url: `${Base_URL}allcategory`,
    };

    axios(config)
      .then(function (response) {
        setcardArray(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const fetchContact = async () => {
    var config = {
      method: "get",
      url: `${Base_URL}getcontact`,
    };

    axios(config)
      .then(function (response) {
        setContactdata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    fetchContact();
  }, []);
  // console.log(contactdata, "test");
  const footer = [
    {
      title: "OverWrite",
      footerArray: [
        {
          home: "Home",
        },
      ],
    },
  ];
  const showfooter = () => {
    var config = {
      method: "get",
      url: `${Base_URL}showfooterdetails`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Authorization: "Bearer " + isLoggedIn,
      // },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "footer data");
        setFootertdata(response.data.data);

        // setADSimg(response.data.data.image);
        // setLink(response.data.data.link);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showfooter();
  }, []);
  return (
    <>
      <div className="footer_page">
        <Container>
          <div className="row">
            <div className="col-lg-4">
              <h1 className="footer_brand">OW</h1>
              <>
                <p className="footer_title">
                  {footertdata.text}

                </p>
                <div className="footer_social_icon">
                  <a
                    href={`//${footertdata.facebook}`}
                    className="footer_icon"
                    target="_blank"
                  >
                    {FooterFacebook}
                  </a>
                  <a
                    href={`//${footertdata.twitter}`}
                    className="footer_icon_social"
                    target="_blank"
                  >
                    {FooterTwitter}
                  </a>
                  <a
                    href={`//${footertdata.instagram}`}
                    className="footer_icon_social"
                    target="_blank"
                  >
                    {Linkedin}
                  </a>
                  <a
                    href={`//${footertdata.pinterest}`}
                    className="footer_icon_social"
                    target="_blank"
                  >
                    {PinterestIcon}
                  </a>
                </div>
              </>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div>
                    <ul className="footer_ul">
                      <li>
                        <h1 className="footer_li_title">OverWrite</h1>
                      </li>
                      <li className="footer_li_text">
                        <Link to="/" className="footer_li_text_a">
                          Home
                        </Link>
                      </li>
                      <li
                        className="footer_li_text"
                        onClick={() => navigate("/search/1")}
                      >
                        <a href="#" className="footer_li_text_a">
                          Communities
                        </a>
                      </li>
                      <li className="footer_li_text">
                        <a href="/about" className="footer_li_text_a">
                          About Us
                        </a>
                      </li>
                      <li className="footer_li_text">
                        <a href="/contact" className="footer_li_text_a">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4">
                  <div> */}
                {/* <ul className="footer_ul fixedHeights">
                      <li>
                        <h1 className="footer_li_title">Categories</h1>
                      </li>
                      {cardArray?.map((items, index) => {
                        if (index > 2) {
                          return;
                        }
                        return (
                          <li
                            className="footer_li_text"
                            onClick={() => {

                              navigate(`/search/${items.id}`)
                              setcommunity(items.id)
                            }}
                          >
                            <a href="#" className="footer_li_text_a">
                              {items.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul> */}
                {/* <div className="linkes">
                      <Link to="/search/1">View all</Link>
                    </div> */}
                {/* </div>
                </div> */}
                <div className="col-lg-4 col-md-4">
                  {/* <div>
                      {contactdata?.map((items) => (
                    <ul className="footer_ul">
                      <li>
                        <h1 className="footer_li_title">Company</h1>
                      </li>
                      <li className="footer_li_text_a">
                        Add: {items.address}
                      </li>
                      <li className="footer_li_text_a">Tel: {items.phone}</li>
                      <li className="footer_li_text_a">
                        Email: {items.email}
                      </li>
                    </ul>
                      ))}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <hr className="footer_hr" />
          <div className="footer_overwrite">
            <span className="footer_reserved_end">
              {footertdata.copy}

            </span>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
