import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Base_URL, COMMUNITYSMALLIMG_URL } from "../../utils/serverUrl";
import Image6 from "../../Assets/images/image 6.png";
import { addButton } from "../../utils/svg.file";
import axios from "axios";
import { useEffect } from "react";
import Swal from "sweetalert2";

function Cards({ cards, classnew, title, button }) {
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  let userId = localStorage.getItem("userId");
  const [joinstatus, setJoinstatus] = React.useState([]);
  // console.log("cards ---- ", cards);

  // const joined = (id, title, user_id) => {
  //   Swal.fire({
  //     title: `Would You like to join ${title}?`,
  //     // text: "you feel like Great !",
  //     // icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, join it!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       var data = new FormData();
  //       data.append("community_id", id);
  //       data.append("user_id", user_id);
  //       data.append("state", "join");

  //       var config = {
  //         method: "post",
  //         url: `${Base_URL}joinuser`,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer " + isLoggedIn,
  //         },
  //         data: data,
  //       };

  //       axios(config)
  //         .then(function (response) {
  //           setJoinstatus([...joinstatus, id]);
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //       Swal.fire("Joined!", "Welcome to this community", "success");
  //     }
  //   });
  // };
  const showrequest = () => {
    var config = {
      method: "post",
      url: `${Base_URL}showrequest`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        const comunity_id = response.data.data;
        const doubled = comunity_id.map(
          (comunity_id) => comunity_id.community_id
        );

        setJoinstatus(doubled);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showrequest();
  }, []);

  // const handleRemove = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to leave this community!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Leave",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const newPeople = joinstatus.filter((joinstatus) => joinstatus !== id);
  //       setJoinstatus(newPeople);
  //       var data = new FormData();
  //       data.append("id", id);

  //       var config = {
  //         method: "post",
  //         url: `${Base_URL}deleterequest`,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer " + isLoggedIn,
  //         },
  //         data: data,
  //       };

  //       axios(config)
  //         .then(function (response) { })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //       Swal.fire("You have left this community.");
  //     }
  //   });
  // };



  return (
    <div className={`newestcommunitiescardsImage ${classnew}`}>
      {title}
      <Container>
        <div className="row">
          {cards?.map((item, index) => {
            if (index > 3) {
              return;
            }
            return (
              <div className="col-md-3">
                <div className="categories_cards_col_div">
                  <Card className="categories_card" key={item.id}>
                    <Link to={`/community-details/${item.id}`}>
                      <Card.Img
                        variant="top"
                        className="newest_categories_card_images"
                        src={
                          item.image
                            ? `${COMMUNITYSMALLIMG_URL}${item.image}`
                            : Image6
                        }
                      // {`${COMMUNITYSMALLIMG_URL}${item.image}`}
                      />
                    </Link>
                    <Card.Body>
                      <div className="community_cards_body">
                        <Link to={`/community-details/${item.id}`}>
                          <Card.Title className="newest_card_title">
                            {item.title}
                          </Card.Title>
                        </Link>
                        <Card.Text className="newest_card_text">
                          {item.description}
                        </Card.Text>


                        <div className="text-center px-3">
                          {item.user_id != userId ? (
                            <>

                            </>
                          ) : (
                            <Button
                              className="newest_categories_button btn-outline-success btn"
                              disabled
                            >
                              Owner
                            </Button>
                          )}
                        </div>

                      </div>
                    </Card.Body>
                  </Card>

                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default Cards;
