import React from 'react'
import Footer from './Layouts/Footer'
import Headers from './Layouts/Headers'

export default function CommunityView() {
  return (
    <div>
           <Headers />
           <Footer />
    </div>
  )
}
