import { createSlice } from "@reduxjs/toolkit";
import socketIo from "socket.io-client";
const initialState = {
  messageCount: 0,
  socket: socketIo("https://socket.digimonk.website:1658", {
    transports: ["websocket"],
  }),
};

export const messageCountSlice = createSlice({
  name: "messageCountSlice",
  initialState,
  reducers: {
    setMessageCount: (state, action) => {
      state.messageCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMessageCount } = messageCountSlice.actions;

export default messageCountSlice.reducer;
