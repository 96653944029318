import {
  Avatar,
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, CSSProperties } from "react";
import { cameraIcon, editIcon } from "../../utils/svg.file";
import { styled } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SelectBox from "../selectbox";
import camera from "../../Assets/images/camera.png";
import moment from "moment";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
// import './index.css';
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
// import Cropmodal from "./modal/Cropmodal";
import Edituserprofileimg from "./Edituserprofileimg";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";

const SwitchBox = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 15,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const UserProfileHeader = (props) => {
  const { APICALL, pest, textareavalue, community_id } = props;

  const [fileselect, setFileselect] = React.useState("");
  const [imgprofile, setImgprofile] = React.useState("");
  const [Categoryid, setCatgoryid] = React.useState("");
  // const [camerapic, setCamerapic] = React.useState(imgprofile);
  const [diss, setDiss] = React.useState(false);
  const [onsave, setOnsave] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [communitymember, setCommunitymember] = React.useState();
  const [imghome, setImghome] = React.useState("");

  let [color, setColor] = React.useState("#ffffff");
  // let [loading, setLoading] = React.useState(false);
  // let [loading1, setLoading1] = React.useState(false);
  let update_date = localStorage.getItem("update_date");
  let communityid = localStorage.getItem("community_id");

  const date_update = moment(update_date).format(" D MMMM YYYY");



  const [isEditing, setIsEditing] = React.useState({
    title: false,
    subTitle: false,
    desc: false,
  });

  const [details, setDetails] = React.useState({
    title: "",
    subTitle: "",
    desc: "Select Category",
  });
  // const getcategory = (data) => {
  //   setOnsave(true)
  //   setCatgoryid(data);
  // };

  // const Callapi = (data) => {
  //   // props.APICALL(Categoryid);
  // };

  React.useEffect(() => {
    setOnsave(true)
  }, [pest, textareavalue, imgprofile])

  const handleChangeDetails = (e) => {
    setOnsave(true)
    // setLoading(true);
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeDetailshandle = (e) => {
    setOnsave(true)
    // setLoading1(true);
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };
  // const handleToggleEditing = (field) => {
  //   setIsEditing({
  //     ...isEditing,
  //     [field]: !isEditing[field],
  //   });
  // };


  const label = { inputProps: { "aria-label": "Switch demo" } };
  useEffect(() => {
    props.getcategoryid(details, diss, imgprofile);
  }, [details, diss, imgprofile]);
  // _____________img croprer________
  const [fileList, setFileList] = React.useState([

  ]);
  const onChange = ({ fileList }) => {
    setFileList(fileList);


    setImgprofile(fileList[0].originFileObj);

  };


  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  // _____________________img croper ___________________

  const setcommunity = () => {
    var data = new FormData();
    data.append("id", communityid);

    var config = {
      method: "post",
      url: `${Base_URL}showcommunitydetails`,

      data: data,
    };

    axios(config)
      .then(function (response) {

        setCommunitymember(response.data.member + 1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (communityid) setcommunity();
  }, [communityid]);

  const newtab = url => {
    window.open(url)





    localStorage.setItem("previewData", JSON.stringify({
      Categoryid, details, pest, textareavalue
    }));

    localStorage.setItem("preimg", JSON.stringify(fileList[0].thumbUrl));


  }

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={24} sm={9} md={9}>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={{ xs: 2, lg: 2 }}
            >

              <div className="update-img-icon position-relative">

                <ImgCrop rotate shape="round" className="usericonbox-edit">
                  <Upload

                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && " + upload"}
                  </Upload>
                </ImgCrop>
              </div>

              <div className="user-title">
                <p className="mb-2">
                  <Stack direction="row" spacing={1}>
                    <input
                      className="edit-field"
                      name="title"
                      onChange={handleChangeDetails}
                      value={details.title}
                      placeholder="Community Name"
                    />

                  </Stack>
                </p>

                <p>
                  <>
                    <Stack direction="row" spacing={1} className="">
                      <input
                        className="edit-field handlefield"
                        name="subTitle"
                        onChange={handleChangeDetailshandle}

                        value={details.subTitle}

                        placeholder="Communityhandle"
                      />
                      <span className="atdirate">@</span>

                    </Stack>
                  </>
                </p>
                {props.showError}
                {/* <span className="d-block mt-2">
                  {isEditing.desc ? (
                    <>
                     
                      <SelectBox getcategory={getcategory} Callapi={Callapi} />
                    </>
                  ) : (
                    <>
                      {details.desc}
                      <IconButton
                        size="small"
                        onClick={() => handleToggleEditing("desc")}
                      >
                        {editIcon}
                      </IconButton>
                    </>
                  )}
                </span> */}

                {update_date ? (
                  <div className="show-date mt-2">
                    <CalendarMonthIcon
                      className="me-2"
                      sx={{ fontSize: "14px" }}
                    />
                    Created on {date_update}
                  </div>
                ) : null}
              </div>
            </Stack>
          </Grid>
          <Grid item xs={24} sm={3} md={2}>

            <Stack direction="row" spacing={2}>
              <div className="member-count">
                <h1>{communitymember ? communitymember : "00"}</h1>
                <p>Member</p>
              </div>
              <div className="member-count">
                <h1>00</h1>
                <p>Online</p>
              </div>
            </Stack>

            <div className="text-center d-flex">
              {onsave && (textareavalue.length !== 0 || pest.length !== 0 || Categoryid.length !== 0 || details.title.length !== 0 || details.subTitle.length !== 0 || imgprofile.length !== 0) ?
                <button className="btn btn-success  px-2 my-2 " onClick={() => {
                  APICALL();
                  setOnsave(false)
                }} style={{ whiteSpace: "nowrap" }}> save change </button>
                : <button className="btn btn-outline-success px-2 my-2" style={{ whiteSpace: "nowrap" }} disabled> save change </button>}

              {details.title.length !== 0 && details.subTitle.length !== 0 ?
                <button className="btn btn-success  px-3 my-2 ms-4"
                  onClick={() => newtab(`/preview`)}
                >Preview</button>
                : ""}


            </div>
          </Grid>
        </Grid>
      </Container>
      <Edituserprofileimg
        show={modalShow}
        imgprofile={imgprofile}
        setImgprofile={setImgprofile}
        APICALL={APICALL}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
export default UserProfileHeader;
