import React, { useEffect } from "react";
// import Headers from "../../Components/Layouts/Headers";
import "../../Assets/Css/dashboard.css";
import camera from "../../Assets/images/myavtar.png";
// import Editicon from "../../Assets/images/editicon.png";
import { Button } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  Base_URL,
  COMMUNITYBIGIMG_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import { useParams } from "react-router-dom";
import moment from "moment";

const Userprofileview = () => {
  let { id } = useParams();
  // let navigate = useNavigate();
  const [profiledata, setProfiledata] = React.useState([]);
  // const [profileusername, setProfileusername] = React.useState("");
  // const [profilename, setProfilename] = React.useState("");
  // const [profilebio, setProfilebio] = React.useState("");
  // const [profileemail, setProfileemail] = React.useState("");
  // const [profilephone, setProfilephone] = React.useState("");
  // const [profiletwitter, setProfiletwitter] = React.useState("");
  // const [profilelinkedin, setProfilelinkedin] = React.useState("");
  // const [profileinstagram, setProfileinstagram] = React.useState("");
  // const [profilefacebook, setProfilefacebook] = React.useState("");
  // const [profiletiktok, setProfiletiktok] = React.useState("");
  // const [profilepassword, setProfilepassword] = React.useState("");
  // const [fileselect, setFileselect] = React.useState();
  // const [imgprofile, setImgprofile] = React.useState();
  // const [updateimgprofile, setUpdateImgprofile] = React.useState();

  let token = localStorage.getItem("userToken");
  let isLoggedIn = token.replace(/^"(.+)"$/, "$1");

  const showprofile = () => {
    var config = {
      method: "get",
      url: `${Base_URL}admin/getuserdetails/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
        setProfiledata(response.data.data);

        // setProfileusername(response.data.success.user_name);
        // setProfilename(response.data.success.name);
        // setProfilebio(response.data.success.bio);
        // setProfileemail(response.data.success.email);
        // setProfilephone(response.data.success.phone);
        // setProfiletwitter(response.data.success.twitter);
        // setProfilelinkedin(response.data.success.linkedin);
        // setProfileinstagram(response.data.success.instagram);
        // setProfilefacebook(response.data.success.facebook);
        // setProfiletiktok(response.data.success.tiktok);
        // setImgprofile(response.data.success.image);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showprofile();
  }, []);

  return (
    <>
      <div className="profile_edit_background py-2">
        <div className="container">
          <div className="profileedit_start">
            <div className="Profile_edit_header">User Details</div>
          </div>
          <div className="Edit_profile_formpage">
            <div className="edit_profile_box">
              <img
                src={
                  profiledata.image
                    ? `${PROFILEIMG_URL}${profiledata.image}`
                    : camera
                }
                alt="img"
                className="edit_profile_user"
              />
            </div>
            <div className="edit_profile_form_page ">
              <form>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputText"
                      aria-describedby="emailHelp"
                      placeholder="@anderson34"
                      value={profiledata.user_name}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label
                      for="exampleInputtext"
                      className="form-label edit_profile_lebal"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="Anderson smith"
                      defaultValue={profiledata.name}
                      readOnly
                      // onChange={(e) => setProfilename(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label
                    for="exampleFormControlTextarea1"
                    className="form-label edit_profile_lebal"
                  >
                    Bio
                  </label>
                  <textarea
                    className="form-control edit_profile_input_area"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    defaultValue={profiledata.bio}
                    readOnly
                    // onChange={(e) => setProfilebio(e.target.value)}
                  ></textarea>
                </div>
                <div className="row">
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      className="form-label edit_profile_lebal"
                    >
                      Create Date
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Anderson@smith.com"
                      value={moment(profiledata.created_at).format(
                        " D MMMM YYYY"
                      )}
                      readOnly
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      className="form-label edit_profile_lebal"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control edit_profile_input_area"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Anderson@smith.com"
                      value={profiledata.email}
                      readOnly
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      className="form-label edit_profile_lebal"
                    >
                      Phone number
                    </label>
                    <input
                      type="number"
                      className="form-control edit_profile_input_area"
                      id="exampleInputnumber"
                      placeholder="+1 9999999999"
                      defaultValue={profiledata.phone}
                      readOnly
                      //   onChange={(e) => setProfilephone(e.target.value)}
                    />
                  </div>

                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Facebook
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputText"
                      aria-describedby="emailHelp"
                      placeholder="https:\\facebook.com"
                      defaultValue={profiledata.facebook}
                      readOnly
                      //   onChange={(e) => setProfilefacebook(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      className="form-label edit_profile_lebal"
                    >
                      Twitter
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="https:\\twitter.com"
                      defaultValue={profiledata.twitter}
                      readOnly
                      //   onChange={(e) => setProfiletwitter(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      className="form-label edit_profile_lebal"
                    >
                      Instagram
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputText"
                      aria-describedby="emailHelp"
                      placeholder="https:\\instagram.com"
                      defaultValue={profiledata.instagram}
                      readOnly
                      //   onChange={(e) => setProfileinstagram(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      className="form-label edit_profile_lebal"
                    >
                      Tiktok
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="https:\\Tiktok.com"
                      defaultValue={profiledata.tiktok}
                      readOnly
                      //   onChange={(e) => setProfiletiktok(e.target.value)}
                    />
                  </div>
                  <div className="mb-5 col-lg-6">
                    <label
                      for="exampleInputtext"
                      className="form-label edit_profile_lebal"
                    >
                      Linkedin
                    </label>
                    <input
                      type="text"
                      className="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="https:\\Linkedin.com"
                      defaultValue={profiledata.linkedin}
                      readOnly
                      //   onChange={(e) => setProfilelinkedin(e.target.value)}
                    />
                  </div>
                </div>
              </form>
              <div className="text-center">
                <Link to="/user">
                  <Button className="mb-5 editprofile_savebutton">
                    Back To User List
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userprofileview;
