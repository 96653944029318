import {
  Avatar, Button, Card, CardContent, CardMedia, Grid, IconButton, Stack, Typography,
} from "@mui/material";
import React from "react";
import {
  cameraIcon,
  editIcon,
  editicons,
  plusIcon,
} from "../../utils/svg.file";
import Headers from "../Layouts/Headers";
import UserProfileHeader from "./UserProfileHeader";
import { styled } from "@mui/material/styles";
import userImg from "../../Assets/images/image-8.png";
import ViewModal from "./modal";
import AddMember from "./modal/add-member";
import AddNewAct from "./modal/add-new-act";
import ActImg from "../../Assets/images/silverfrog.png";
import ApprovedChapter from "../Community-Detail-Page/ApprovedChapter";
import Community6 from "../Community-Detail-Page/Community6";
import Community7 from "../Community-Detail-Page/Community7";
import Community8 from "../Community-Detail-Page/Community8";
import { Container, Nav, Tab } from "react-bootstrap";
import AddChapter from "../create-edit-communit/modal/add-chapter";
import EditChapter from "./modal/editchapter";
import RemoveChapter from "./modal/remove-chapter";
import { useEffect } from "react";
import axios from "axios";
// import { Base_URL } from "../../utils/serverUrl";
import SlickSlide from "../SlickSlide";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Silverfrog from "../../Assets/images/silverfrog.png";
import "../../Assets/Css/communitydetailpage.css";
import upload from "../../Assets/images/upload.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Base_URL,
  CHAPTERIMG_URL,
  COMMUNITYSMALLIMG_URL,
  img_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import { useCallback } from "react";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import Cropmodal from "./modal/Cropmodal";
import { BeatLoader } from "react-spinners";
import AddEvents from "../Event-Modal/AddEvents";

const CardBox = styled(CardContent)(() => ({
  "&.MuiCardContent-root": {
    minHeight: "300px",
    paddingBottom: "16px",
  },
}));
const Paragraph = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "20px",

    color: "#000",
    fontWeight: "400",
  },
}));

const CreateEdit = (props) => {
  const [fileselect, setFileselect] = React.useState("");
  const [imghome, setImghome] = React.useState("");
  const [textareavalue, setTextareavlaue] = React.useState("");
  const [categoryid, setCategoryid] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [subtitle, setSubtitle] = React.useState("");
  const [disscusion, setDisscusion] = React.useState("");
  const [profileimg, setProfileimg] = React.useState("");
  const [community_id, setCommunityid] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [actdata, setActdata] = React.useState([]);
  const [showmem, setShowmembers] = React.useState([]);
  const [showcreater, setShowcreater] = React.useState([]);

  const [uploading, setUploading] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);
  const [pendingchapters, setPendingchapter] = React.useState([]);

  const [modalShow, setModalShow] = React.useState(false);
  const [work, setWork] = React.useState("");
  const [pest, setPest] = React.useState("");
  const [showError, setShowError] = React.useState("");
  const [selectetdFile, setSelectedFile] = React.useState([]);
  const [fileBase64String, setFileBase64String] = React.useState("");
  const [test, setTest] = React.useState(false);
  const [isclick, setIsclick] = React.useState();
  const [ischapter, setIschapter] = React.useState(false);
  let [loading2, setLoading2] = React.useState(false);
  const [actid, setActId] = React.useState("");
  const [chapterId, setChapterId] = React.useState("");
  const [eventshow, setEventshow] = React.useState("");
  const user_Id = localStorage.getItem("userId");
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");

  let communityId = localStorage.getItem("community_id");
  let commid = communityId?.replace(/^"(.+)"$/, "$1");

  let userId = user_Id?.replace(/^"(.+)"$/, "$1");
  // const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [show, setShow] = React.useState(false);

  // let act_id = localStorage?.getItem("act_id");

  const pendingchapter = () => {
    var data = new FormData();
    data.append("community_id", community_id);
    data.append("act_id", actid);

    var config = {
      method: "post",
      url: `${Base_URL}chapterpending-list`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "data")
        setPendingchapter(response.data.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    pendingchapter();

  }, [community_id, actid]);

  const actlist = () => {
    var data = new FormData();
    data.append("community_id", community_id);

    var config = {
      method: "post",
      url: `${Base_URL}act-list`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "eneneytt")

        setActdata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const showmembers = () => {
    var data = new FormData();
    data.append("id", community_id);

    var config = {
      method: "post",
      url: `${Base_URL}showmember`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        setShowmembers(response.data.data);
        setShowcreater(response.data.creator);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showmembers();
  }, [community_id]);

  const eventlist = () => {
    var data = new FormData();
    data.append("chapter_id", chapterId);
    data.append("act_id", actid);

    var config = {
      method: "post",
      url: `${Base_URL}showevent`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Authorization: "Bearer " + isLoggedIn,
      // },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "pendinghhhhhhhhhhhhhhhhhh")
        setEventshow(response.data.data);
        // setUsername(response.data.name);
        // setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    eventlist();
  }, [chapterId, actid]);

  const fileupload = (e) => {
    var reader = new FileReader();
    setImghome(e.target.files[0]);
    reader.onloadend = function () {
      setFileselect(reader.result);
      APICALL({ bannerImage: e.target.files[0] });
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const [open, setOpen] = React.useState();

  const handleClickOpen = (type) => {
    setOpen(type);
  };
  const handleClose = () => {
    setOpen(false);
    // setIsSubmitting(false);
  };
  const [isCommunity, setIsCommunity] = React.useState(false);

  const onhandleCommunity = (id) => {
    setActId(id)
    setIsclick(id);
    setIsCommunity(!isCommunity);
  };
  const onhandleChapter = (id) => {
    setChapterId(id)
    setIschapter(!ischapter);
  };
  const getcategoryid = (value, item, img) => {

    setTitle(value.title);
    setSubtitle(value.subTitle);
    setDisscusion(item);
    setProfileimg(img);
  };


  const APICALL = (temp, value) => {

    setLoading2(true)
    setShowError("");
    var data = new FormData();
    data.append("image", profileimg);
    data.append("banner_image", value || pest);
    data.append("title", title);
    data.append("category_id", "1");
    data.append("about", textareavalue);
    data.append("discussion", disscusion);
    data.append("community_handle", subtitle);
    data.append("community_id", community_id);
    data.append("user_id", userId);

    var config = {
      method: "post",
      url: `${Base_URL}add-community`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        setLoading2(false)
        if (response.data.error) {
          setShowError(response.data.error.community_handle);
        }
        setCommunityid(response.data.data.id);
        localStorage.setItem("community_id", response.data.data.id);
        localStorage.setItem("update_date", response.data.data.updated_at);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handletextarea = (e) => {
    setTextareavlaue(e.target.value);
  };

  useEffect(() => {
    if (!isSubmitting) {
      actlist();
      handleClose();
    }
  }, [isSubmitting]);
  useEffect(() => {
    return () => {

      localStorage.removeItem("update_date");
    };
  }, []);

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowBackIcon />
        </button>
      </div>
    );
  };
  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowForwardIcon />
        </button>
      </div>
    );
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settingschpter = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 992,
        settingschpter: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  window.onbeforeunload = function (e) {
    localStorage.removeItem("community_id");
  };

  window.onbeforeunload = function (e) {
    localStorage.removeItem("community_id");
  };

  return (
    <>
      <Headers />

      <div className="banner-edit-content-color">
        <div
          className="banner-edit-content"
          style={{
            backgroundImage: `url(${pest ? pest : upload})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="position-relative w-100">
            <Stack
              direction="column"
              spacing={2}
              justifyContent="center"
              alignItems={"center"}
            >
              <div className="editIcon">
                <button
                  className="btn btnedits"
                  onClick={() => setModalShow(true)}
                >
                  <span>{editicons}</span>Update
                </button>
              </div>
            </Stack>
          </div>
        </div>
      </div>

      <div className="profile-content">
        <UserProfileHeader
          getcategoryid={getcategoryid}
          APICALL={APICALL}
          showError={showError}
          textareavalue={textareavalue}
          pest={pest}
          community_id={community_id}
        />
      </div>
      <div className="py-4 bg-light-green">
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={8} xs={12}>
              <Card variant="false" className="create-edit-card">
                <div className="d-flex"> <h5 style={{ width: "100%" }} >About Community </h5>

                </div>

                <CardBox>
                  <textarea
                    className="textareabox"
                    placeholder="about Community"
                    onChange={handletextarea}

                  ></textarea>
                </CardBox>
              </Card>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Card variant="false" className="create-edit-card scrollsbar">
                <h5>Community Members</h5>

                <CardBox>
                  {community_id ? (
                    <>
                      <Stack direction="row" spacing={2} className="mb-3">
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ width: 50, height: 50 }}
                          src={
                            showcreater
                              ? `${PROFILEIMG_URL}${showcreater.image}`
                              : userImg
                          }
                        />
                        <div className="user-edit-text">
                          <h6>{showcreater?.name}</h6>
                          <p>
                            <span> Owner</span>
                          </p>
                        </div>
                      </Stack>
                    </>
                  ) : (
                    ""
                  )}
                  {showmem.map((item) => (
                    <Stack direction="row" spacing={2} className="mb-3">
                      <Avatar
                        alt="Remy Sharp"
                        sx={{ width: 50, height: 50 }}
                        src={
                          showmem ? `${PROFILEIMG_URL}${item.image}` : userImg
                        }
                      />
                      <div className="user-edit-text">
                        <h6>{item.name}</h6>
                        <p>
                          <span>@{item.user_name} |</span>
                          {item.role === 2 ? "Founder" : "Member"}
                        </p>
                      </div>
                    </Stack>
                  ))}
                  {community_id ? (
                    <Stack
                      direction="column"
                      spacing={1}
                      className="create-edit-member mt-5"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        onClick={() => handleClickOpen("addmember")}
                        disableRipple="false"
                        sx={{ p: 0 }}
                      >
                        {plusIcon}
                      </IconButton>
                      <span
                        className="add-member-text  "
                        onClick={() => handleClickOpen("addmember")}
                      >
                        Add community members
                      </span>
                    </Stack>
                  ) : (
                    <Stack
                      direction="column"
                      spacing={1}
                      className="create-edit-member mt-5"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <span className="add-member-text">
                        Add community members
                      </span>
                    </Stack>
                  )}
                </CardBox>
              </Card>
            </Grid>

            <Grid item xs={12} lg={12}>
              <div className="common-heading mt-lg-3">
                <span>Community Acts</span>
              </div>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                className="mt-lg-5"
              >
                <Grid item lg={9} xs={12}>
                  <div
                    className="commity-slider">
                    <Slider {...settings}>
                      {actdata.map((item, index) => (
                        <Card
                          className={isCommunity == true && isclick == item.id ? "select_act_border" : "sliderbox"}
                          onClick={() => onhandleCommunity(item.id)}
                          variant="false"
                          sx={{
                            borderRadius: "24px",
                            boxShadow: "0px 5px 20px rgba(167, 167, 167, 0.4)",
                            minHeight: "384px",
                          }}
                          key={index}
                        >
                          <CardMedia
                            component="img"
                            image={`${img_URL}${item.image}`}
                            alt="green iguana"
                          />

                          <div className="p-3 act-content">
                            <h3>{item.act_name}</h3>
                            <p>{item.description}</p>
                          </div>
                        </Card>
                      ))}
                    </Slider>
                  </div>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <div className="add-new-act"
                    onClick={() => handleClickOpen("addnewact")}>
                    {community_id ? (
                      <Stack
                        direction="column"
                        spacing={1}
                        className="create-edit-member"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconButton
                          onClick={() => handleClickOpen("addnewact")}
                          disableRipple="false"
                          sx={{ p: 0 }}
                        >
                          {plusIcon}
                        </IconButton>
                        <span
                          className="add-member-text"
                          onClick={() => handleClickOpen("addnewact")}
                        >
                          Add New Act
                        </span>
                      </Stack>
                    ) : (
                      <Stack
                        direction="column"
                        spacing={1}
                        className="create-edit-member"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <span className="add-member-text">Add New Act</span>
                      </Stack>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      {isCommunity ? (
        <section className="bg-light-green">

          {/* <Container> */}
          <Grid item xs={12} lg={12}>
            <div className="common-heading mt-lg-3 bg-white ">

              <span>CHAPTERS </span>
              <div className="pb-4">
                <button className="btn btn-success " onClick={() => handleClickOpen("addchapter")}>CREATE CHAPTERS</button>
              </div>
            </div>

            <Grid
              container
              spacing={2}
              justifyContent="center"
              className="mt-lg-5 community_detail_chapter"
            >
              <Grid item lg={12} xs={12}>
                <div
                  className="commity-slider slicksslider ">
                  <Slider {...settingschpter}>
                    {pendingchapters.map((item, index) => (
                      <Card
                        className={ischapter == true && chapterId == item.id ? "select_act_border mycommunity_card_card" : "mycommunity_card_card"}
                        onClick={() => onhandleChapter(item.id)}
                        variant="false"
                        sx={{
                          borderRadius: "24px",
                          boxShadow: "0px 9px 20px rgba(167, 167, 167, 0.4)",
                        }}
                        key={index}
                      >
                        <CardMedia
                          component="img"
                          image={`${CHAPTERIMG_URL}${item.images}`}
                          alt="green iguana"
                          className="mycommunity_card_img"
                        />

                        <div className="p-3 act-content">
                          <h3>{item.chapter_name}</h3>
                          <p> @{item.user}</p>
                          <p>{item.description}</p>
                        </div>
                        {/* <button variant="primary" className="slick_slide_btn  count_event">
                           0 Event
                        </button> */}
                      </Card>
                    ))}
                  </Slider>
                </div>
              </Grid>

            </Grid>
          </Grid>


          {/* </Container> */}
        </section>
      ) : null}
      {ischapter && isCommunity ?
        <section>
          <div className="text-center bg-white pb-3">
            <div className=" create-event-text my-2 "> EVENTS </div>
            <div><button className="btn btn-success " onClick={() => setShow(true)}>CREATE NEW EVENT</button></div>
          </div>

          <div className="bg-light-green py-3">
            {eventshow.map((item) => (


              <Container>
                <div className="border border-success  myevents-create  my-2 shadow ">
                  <div className="row ">
                    <div className=" col-lg-11 ms-3 ">
                      <p className="">
                        <span className="event-create-heading"> EVENT NAME ::</span> <span className="event-create-content"> {item.event_name} </span>
                      </p>
                      <p className="">
                        <span className="event-create-heading"> WRITTEN BY ::</span> <span className="event-create-content"> {item.user}</span>
                      </p>
                      <p className="d-flex">
                        <div className="event-create-heading"> DESCRIPTION::</div> <div className="event-create-content">{item.description} </div>
                      </p>
                    </div>

                  </div>
                </div>
              </Container>
            ))}
          </div>
        </section>
        : null}
      <ViewModal
        handleClickOpen={handleClickOpen}
        open={open}
        onCancle={handleClose}
        onSubmit={() => setIsSubmitting(true)}
        title={
          open === "addmember"
            ? "add member"
            : open === "addnewact"
              ? "Add Act"
              : open === "addchapter"
                ? "Add Chapter"
                : open === "editchapter"
                  ? "Edit Chapter"
                  : open === "removechapter"
                    ? "Remover Chapter"
                    : null
        }
        cancleText={
          open === "addmember"
            ? "Cancel"
            : open === "addnewact"
              ? "Cancel"
              : open === "addchapter"
                ? "Cancel"
                : open === "editchapter"
                  ? "Cancel"
                  : open === "removechapter"
                    ? "Cancel"
                    : null
        }
        submitText={
          open === "addmember"
            ? "Send request"
            : open === "addnewact"
              ? "Add"
              : open === "addchapter"
                ? "Add"
                : open === "editchapter"
                  ? "Submit"
                  : open === "removechapter"
                    ? "Submit"
                    : null
        }
        content={
          <>
            {open === "addmember" ? (
              <AddMember
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                showmembers={showmembers}
              />
            ) : open === "addnewact" ? (
              <AddNewAct
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                actlist={actlist}
              />
            ) : open === "addchapter" ? (
              <AddChapter
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                pendingchapter={pendingchapter}
                actid={actid}
              />
            ) : open === "editchapter" ? (
              <EditChapter />
            ) : open === "removechapter" ? (
              <RemoveChapter />
            ) : null}
          </>
        }
      />
      <Cropmodal
        show={modalShow}
        APICALL={APICALL}
        onHide={() => setModalShow(false)}
        pest={pest}
        setPest={setPest}
      />
      <AddEvents
        show={show}
        onHide={() => {
          setShow(false);
          setIsSubmitting(true);
        }}
        actid={actid}
        chapterId={chapterId}
        eventlist={eventlist}
      />
    </>
  );
};
export default CreateEdit;
