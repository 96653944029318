import React, { useEffect, useState } from "react";
import "../Assets/Css/styles.css";
import Headers from "../Components/Layouts/Headers";
import Footer from "../Components/Layouts/Footer";
import { Button } from "react-bootstrap";
import Image9 from "../Assets/images/basic.png";
import Greenplus from "../Assets/images/greenplus.png";
import axios from "axios";
import { Base_URL, COMMUNITYSMALLIMG_URL } from "../utils/serverUrl";
import { Link, useParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../features/preLoaderSlice'

const SearchPage = () => {
  const [category, setcategory] = useState([]);
  const [card, setCard] = useState([]);
  const [requestdata, setRequestdata] = useState([]);
  const [joinstatus, setJoinstatus] = useState([]);
  const [allcomunity, setAllcomunity] = React.useState([]);

  const [searchbutton, setSearchbutton] = useState("");
  let tokenId = localStorage.getItem("userToken");
  let userId = localStorage.getItem("userId");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");
  const params = useParams();
  const catid = params.id;
  const [catids, setCatids] = useState(catid);
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const fetchData = async () => {
    var config = {
      method: "get",
      url: `${Base_URL}allcategory`,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "all");
        setcategory(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setcommunity = (id) => {
    setCatids(id);
    showcommunity();
  };
  const showcommunity = () => {
    var data = new FormData();
    // data.append("id", catids);

    var config = {
      method: "get",
      url: `${Base_URL}allcommunity`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        setCard(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showsearchedcommunity = (text) => {
    var data = new FormData();
    // data.append("cat_id", catids);
    data.append("text", text);

    var config = {
      method: "post",
      url: `${Base_URL}searchcommunitylatter`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        // setCard(response.data.data);
        setCard(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showrequest = () => {
    var config = {
      method: "post",
      url: `${Base_URL}showrequest`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "id");
        const comunity_id = response.data.data;
        const doubled = comunity_id.map(
          (comunity_id) => comunity_id.community_id
        );

        setJoinstatus(doubled);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const handleRemove = (id) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to leave this community!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Leave",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const newPeople = joinstatus.filter((joinstatus) => joinstatus !== id);
  //       setJoinstatus(newPeople);
  //       // alert(id);
  //       var data = new FormData();
  //       data.append("id", id);

  //       var config = {
  //         method: "post",
  //         url: `${Base_URL}deleterequest`,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: "Bearer " + isLoggedIn,
  //         },
  //         data: data,
  //       };

  //       axios(config)
  //         .then(function (response) { })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //       Swal.fire("You have left this community.");
  //     }
  //   });
  // };
  // useEffect(() => {
  //   fetchData();
  //   showrequest();
  // }, []);

  useEffect(() => {
    showcommunity();
  }, [catids]);

  const showCommunity = () => {
    dispatch(setIsLoading(true))
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}allcommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data, "show communityin  page");
        setAllcomunity(response?.data.data);
        dispatch(setIsLoading(false))
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showCommunity();
  }, []);

  const card1 = [
    {
      image: Image9,
      community: "Community 1",
      text: "Loreum epsum sit dolor ",
    },
    {
      image: Image9,
      community: "Community 1",
      text: "Loreum epsum sit dolor ",
    },
    {
      image: Image9,
      community: "Community 1",
      text: "Loreum epsum sit dolor ",
    },
    {
      image: Image9,
      community: "Community 1",
      text: "Loreum epsum sit dolor ",
    },
  ];

  const alphabets = ['A', 'B', 'C', "D", "E", "F", 'G', 'H', 'I', "J", "K", 'L', 'M', 'N', "O", "P", 'Q', 'R', 'S', "T", "U", 'V', "W", "X", 'Y', 'Z', "1", '2', "3", "4", '5', "6", "7", '8', '9', "0"]

  return (
    <>
      <Headers />

      <div className="shdaow mobileshadow ">
        <div className="container ">
          <div className="mysearch_create ">
            <h1 className="search_heading">Our Communities</h1>
            {isLoggedIn ? (
              <Link to="/create-communities">
                <button className="btn btn-outline-success mysearch_create_button ">
                  Create A New Community
                </button>
              </Link>
            ) : (
              <Link to="/signin">
                <button className="btn btn-outline-success mysearch_create_button ">
                  Create A New Community
                </button>
              </Link>
            )}
          </div>
          <p className="search_title ">
            Communities on OverWrite are homes for content creators who all
            share a similar niche. The original creator and founders of a
            community are responsible for verifying and inviting new members.
          </p>
        </div>
      </div>
      <div className="search_page">
        <div className="container">
          <h1 className="search_sub_heading">Search by characters</h1>

          <div>
            <nav class="AlphabetNav">

              {searchbutton !== "" && <a
                className={searchbutton === "" ? 'active_alphabet_reset me-3' : 'me-3'}
                href={`#`}
                onClick={() => {
                  setSearchbutton("")
                  showsearchedcommunity("");
                }}
              >
                Reset
              </a>}
              {
                alphabets.map(item =>
                  <a
                    className={searchbutton === item ? 'active_alphabet' : ''}
                    href={`#${item}`}
                    onClick={() => {
                      setSearchbutton(item)
                      // showsearchedcommunity(item.toLowerCase());
                    }}
                  >
                    {item}
                  </a>)
              }


            </nav>
          </div>
          <hr className="search_hr_line" />

          <div className="row">
            {/* <div className="col-lg-2 col-md-2">
              <div className="search_col_second_back heightfixes">
                <div className="search_category_heading">Categories</div>
                {category.map((item) => (
                  <div
                    className={
                      Number(catids) === item.id
                        ? "search_category_heading_item active_search"
                        : "search_category_heading_item"
                    }
                    onClick={() => setcommunity(item.id)}
                  >
                    {Number(catids) === item.id ? (
                      <b>{item.name}</b>
                    ) : (
                      <>{item.name}</>
                    )}
                  </div>
                ))}
              </div>
            </div> */}
            <div className="col-lg-8 col-md-6">
              <div className="search_col_second_back scrool-searchallcommunity">
                <div className="search_category_heading ">
                  <p>{card.filter(c => c.title.toUpperCase()?.startsWith(searchbutton))?.length} result found for community</p>

                </div>
                <>
                  {card.length > 0 ? (
                    <>
                      {card.filter(c => c.title.toUpperCase()?.startsWith(searchbutton)).map((items, index) => (
                        <div className="search_result_community">
                          <div className="ps-3">
                            <Link to={`/community-details/${items.id}`}>
                              <img
                                src={
                                  items.image
                                    ? `${COMMUNITYSMALLIMG_URL}${items.image}`
                                    : `https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${items.title?.[0]?.toUpperCase()}`
                                }
                                alt="img"
                                className="search_result_img"
                              />
                            </Link>
                          </div>
                          <div className="search_result_community_p">
                            <p className="search_result_community_p_p1">
                              <Link to={`/community-details/${items.id}`}>
                                {items.title}
                              </Link>
                            </p>
                            <p className="search_result_community_p_p2">
                              <Link to={`/community-details/${items.id}`}>
                                @{items.community_handle}
                              </Link>
                            </p>
                            <p className="search_result_community_p_p3">
                              <Link to={`/community-details/${items.id}`}>
                                {items.about}
                                {items.sent_user_id}
                              </Link>
                            </p>
                          </div>

                          <div className="search_result_button">

                            {items.user_id != userId ? (
                              <>
                                {/* {joinstatus.includes(items.id) ? (
                                  <>
                                    <Button
                                      className="search_result_community_button"
                                      onClick={() => handleRemove(items.id)}
                                    >
                                      Joined
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {isLoggedIn ?
                                      <Button
                                        className="search_result_community_button"
                                        onClick={() =>
                                          joined(items.id, items.title
                                            , items.user_id)
                                        }
                                      >
                                        Join Now
                                        <img
                                          src={Greenplus}
                                          alt="button"
                                          className="ms-2"
                                        />
                                      </Button> :

                                      <Button
                                        className="search_result_community_button"
                                        onClick={() => {
                                          navigate("/signin");
                                        }}
                                      >
                                        Join Now
                                        <img
                                          src={Greenplus}
                                          alt="button"
                                          className="ms-2"
                                        />
                                      </Button>
                                    }
                                  </>
                                )} */}
                              </>
                            ) : (
                              <Button
                                className="search_result_community_button btn btn-outline-success"
                                disabled
                              >
                                Owner
                              </Button>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="search_category_heading border-0">
                      <p>No Result Found</p>

                    </div>
                  )}
                </>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="search_col_second_back">
                <div className="search_category_heading">
                  <p>Featured Communities</p>
                </div>
                <div className="listscroll">
                  <PerfectScrollbar>
                    {allcomunity.map(
                      (data) =>
                        data.show_status === 1 && (
                          <div className="search_result_community_feature">
                            <div className="ps-3">
                              <Link to={`/community-details/${data.id}`}>
                                <img
                                  src={
                                    data.image
                                      ? `${COMMUNITYSMALLIMG_URL}${data.image}`
                                      : Image9
                                  }
                                  // src={data.image}
                                  alt="img"
                                  className="search_result_img"
                                />
                              </Link>
                            </div>
                            <div className="search_result_community_p">
                              <p className="search_result_community_p_feature">
                                <Link to={`/community-details/${data.id}`}>
                                  {data.title}
                                </Link>
                              </p>
                              <p className="search_result_community_p_p2">
                                <Link to={`/community-details/${data.id}`}>
                                  @{data.community_handle}
                                </Link>
                              </p>
                              <p className="search_result_community_p_feature_p2">
                                <Link to={`/community-details/${data.id}`}>
                                  {data.about === "null" ? "" : data.about}
                                </Link>
                              </p>
                            </div>
                          </div>
                        )
                    )}
                  </PerfectScrollbar>
                </div>
              </div>

              <div className="search_col_second_back">
                <div className="search_category_heading">Top Communities</div>
                <div className="listscroll">
                  <PerfectScrollbar>
                    {allcomunity.map(
                      (data) =>
                        data.show_status === 2 && (
                          <div className="search_result_community_feature">
                            <div className="ps-3">
                              <Link to={`/community-details/${data.id}`}>
                                <img
                                  src={
                                    data.image
                                      ? `${COMMUNITYSMALLIMG_URL}${data.image}`
                                      : Image9
                                  }
                                  alt="img"
                                  className="search_result_img"
                                />
                              </Link>
                            </div>
                            <div className="search_result_community_p">
                              <p className="search_result_community_p_feature">
                                <Link to={`/community-details/${data.id}`}>
                                  {data.title}
                                </Link>
                              </p>
                              <p className="search_result_community_p_p2">
                                <Link to={`/community-details/${data.id}`}>
                                  @{data.community_handle}
                                </Link>
                              </p>
                              <p className="search_result_community_p_feature_p2">
                                <Link to={`/community-details/${data.id}`}>
                                  {data.about === "null" ? "" : data.about}
                                </Link>
                              </p>
                            </div>
                          </div>
                        )
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer setcommunity={(id) => setcommunity(id)} />
    </>
  );
};

export default SearchPage;
