import React from "react";
import "../../Assets/Css/dashboard.css";

import Title from "./title";
import Cards from "./cards";
import { useEffect, useState } from "react";
import axios from "axios";
import { Base_URL } from "../../utils/serverUrl";

const ContentCards = ({ title, cards }) => {
  const [allcomunity, setAllcomunity] = useState([]);
  const [featuredcomunity, setFeaturedcomunity] = useState([]);
  const [topcomunity, setTopcomunity] = useState([]);
  const [latestcomunity, setLatestcomunity] = useState([]);
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  const showCommunity = () => {
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}allcommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        setAllcomunity(response?.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const showfeaturedCommunity = () => {
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}featuredcommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response?.data.data, "show community");
        setFeaturedcomunity(response?.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const showtopCommunity = () => {
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}topcommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        setTopcomunity(response?.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  const showlatestCommunity = () => {
    let data = new FormData();

    let config = {
      method: "get",
      url: `${Base_URL}latestescommunity`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function(response) {
        setLatestcomunity(response?.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showCommunity();
    showfeaturedCommunity();
    showtopCommunity();
    showlatestCommunity();
  }, []);
  
  return (
    <>
      {/* <Title title='Featured Communities' /> */}
      <Cards cards={featuredcomunity} classnew='bg1' button="View All" title={<><p className="featureP">Featured Communities</p></>} />
      {/* <Title title='Top Communities'  /> */}
      <Cards cards={topcomunity} classnew='bg2' button="View All"  title={<><p className="featureP">Top Communities</p></>}/>
      {/* <Title title='Newest Communities' /> */}
      <Cards cards={latestcomunity} classnew='bg3' button="View All"  title={<><p className="featureP">Newest Communities</p></>}/>
    </>
  );
};

export default ContentCards;
