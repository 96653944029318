import { React, useState } from "react";
import "../../Assets/Css/styles.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";
import { useEffect } from "react";

import moment from "moment";
import { Switch } from "@mui/material";
import { Link } from "react-router-dom";
const User = () => {
  const [alluser, setAlluser] = useState([]);

  let token = localStorage.getItem("userToken");

  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  const showalluser = () => {
    var config = {
      method: "get",
      url: `${Base_URL}admin/userlist`,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };
    axios(config)
      .then(function(response) {
        setAlluser(response.data.list);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const manageuser = (id, type) => {
    var data = new FormData();
    data.append("id", id);
    data.append("status", type);
    var config = {
      method: "post",

      url: `${Base_URL}admin/updateuserstatus`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };
    axios(config)
      .then(function(response) {
        showalluser();
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showalluser();
  }, []);

  return (
    <>
      <div className="categorylist_page">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Email</TableCell>
              {/* <TableCell>Tiktok</TableCell> */}
              {/* <TableCell>Linkedin</TableCell> */}
              {/* <TableCell>Instagram</TableCell> */}
              {/* <TableCell>Twitter</TableCell> */}
              {/* <TableCell>Facebook</TableCell> */}
              {/* <TableCell>Biography</TableCell> */}
              {/* <TableCell>Phone</TableCell> */}

              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          {alluser.map((item, idx) => (
            <TableBody>
              <TableCell>{idx + 1} </TableCell>
              <TableCell>
                {moment(item.created_at).format(" D MMMM YYYY")}
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.user_name}</TableCell>
              <TableCell>{item.email}</TableCell>
              {/* <TableCell>{item.email_verified_at}</TableCell> */}
              {/* <TableCell>{item.image}</TableCell> */}
              {/* <TableCell>{item.tiktok}</TableCell> */}
              {/* <TableCell>{item.linkedin}</TableCell> */}
              {/* <TableCell>{item.instagram}</TableCell> */}
              {/* <TableCell>{item.twitter}</TableCell> */}
              {/* <TableCell>{item.facebook}</TableCell> */}
              {/* <TableCell>{item.bio}</TableCell> */}
              {/* <TableCell>{item.phone}</TableCell> */}

              <TableCell>
                <Link to={`/userprofileview/${item.id}`}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    // onClick={() => manageuser(item.id, 1)}
                  >
                    View
                  </button>
                </Link>
                {item.status == 0 ? (
                  <button
                    type="button"
                    className="btn btn-success ms-2"
                    onClick={() => manageuser(item.id, 1)}
                  >
                    Active
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger ms-2"
                    onClick={() => manageuser(item.id, 0)}
                  >
                    Deactive
                  </button>
                )}
              </TableCell>

              {/* <TableCell>
                 
                </TableCell> */}

              {/* <TableCell>OverWright</TableCell>
              <TableCell>Category list</TableCell> */}
            </TableBody>
          ))}
        </Table>
      </div>
    </>
  );
};

export default User;
