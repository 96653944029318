import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Base_URL } from "../../utils/serverUrl";
import axios from "axios";

const AddEvent = (props) => {
  const [event, setEvent] = useState("");
  const [descreption, setDescreption] = useState("");

  let tokenId = localStorage.getItem("userToken");
  let isLoggedIn = tokenId?.replace(/^"(.+)"$/, "$1");

  const showEventOnly = () => {
    var data = new FormData();

    var config = {
      method: "get",
      url: `${Base_URL}admin/event/${props.eventid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response.data.data.description, "single event");
        setEvent(response.data.data.event_name);
        setDescreption(response.data.data.description);

        // setAllEvent(response.data.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  useEffect(() => {
    showEventOnly();
  }, [props.eventid]);

  const UpdateEvent = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("title", event);
    data.append("description", descreption);

    var config = {
      method: "POST",
      url: `${Base_URL}admin/update-event/${props.eventid}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },

      data: data,
    };

    axios(config)
      .then(function(response) {
        // console.log(response, "update event");
        props.showEvent();
        props.onHide();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1 className="text-center">Edit Event</h1>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Enter Title"
              value={event}
              onChange={(e) => setEvent(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder="Description"
              value={descreption}
              onChange={(e) => setDescreption(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            <Button
              onClick={() => (setEvent(""), setDescreption(""), props.onHide())}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-3"
              onClick={(e) => UpdateEvent(e)}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEvent;
