import React, { useEffect, useState } from "react";
import Headers from "../Layouts/Headers";
import "../../Assets/Css/dashboard.css";
import camera from "../../Assets/images/camera.png";
import myavtar from "../../Assets/images/myavtar.png";
import Editicon from "../../Assets/images/editicon.png";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Base_URL,
  COMMUNITYBIGIMG_URL,
  PROFILEIMG_URL,
} from "../../utils/serverUrl";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import { plusIcon, deleteIcon, editIcon } from "../../utils/svg.file";

const Editprofile = () => {
  let navigate = useNavigate();
  const [profiledata, setProfiledata] = React.useState([]);
  const [profileusername, setProfileusername] = React.useState("");
  const [profilename, setProfilename] = React.useState("");
  const [profilebio, setProfilebio] = React.useState("");
  const [profileemail, setProfileemail] = React.useState("");
  const [profilephone, setProfilephone] = React.useState("");
  const [profiletwitter, setProfiletwitter] = React.useState("");
  const [profilelinkedin, setProfilelinkedin] = React.useState("");
  const [profileinstagram, setProfileinstagram] = React.useState("");
  const [profilefacebook, setProfilefacebook] = React.useState("");
  const [profiletiktok, setProfiletiktok] = React.useState("");
  const [profilepassword, setProfilepassword] = React.useState("");
  const [profileConfirmpassword, setProfileConfirmpassword] = React.useState(
    ""
  );
  const [fileselect, setFileselect] = React.useState();
  const [imgprofile, setImgprofile] = React.useState();
  const [updateimgprofile, setUpdateImgprofile] = React.useState();
  const [errorPassword, setErrorPassword] = useState(false);

  let token = localStorage?.getItem("userToken");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  let userId = localStorage?.getItem("userId");
  const showprofile = () => {
    var config = {
      method: "post",
      url: `${Base_URL}user-details`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function(response) {
        setProfiledata(response.data.success);
        setProfileusername(response.data.success.user_name);
        setProfilename(response.data.success.name);
        setProfilebio(response.data.success.bio);
        setProfileemail(response.data.success.email);
        setProfilephone(response.data.success.phone);
        setProfiletwitter(response.data.success.twitter);
        setProfilelinkedin(response.data.success.linkedin);
        setProfileinstagram(response.data.success.instagram);
        setProfilefacebook(response.data.success.facebook);
        setProfiletiktok(response.data.success.tiktok);
        setImgprofile(response.data.success.image);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const updateprofile = () => {
    let err = false;
    if (profilephone.length < 10) {
      err = true;
      Swal.fire({
        icon: "error",
        title: "Please Enter valid phone number ",
        // text: "Required field",
      });
    }
    if (!err) {
      var data = new FormData();
      data.append("name", profilename);
      data.append("password", profilepassword);
      data.append("c_password", profileConfirmpassword);
      data.append("bio", profilebio);
      data.append("phone", profilephone);
      data.append("image", updateimgprofile);
      data.append("tiktok", profiletiktok);
      data.append("twitter", profiletwitter);
      data.append("linkedin", profilelinkedin);
      data.append("instagram", profileinstagram);
      data.append("facebook", profilefacebook);

      var config = {
        method: "post",
        url: `${Base_URL}updateDetails`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + isLoggedIn,
        },
        data: data,
      };

      axios(config)
        .then(function(response) {
          // console.log(JSON.stringify(response.data));
          navigate("/profile");
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    showprofile();
  }, []);
  // const fileupload = (e) => {
  //   var reader = new FileReader();

  //   reader.onloadend = function() {
  //     setFileselect(reader.result);
  //     setUpdateImgprofile(e.target.files[0]);
  //   };
  //   reader.readAsDataURL(e.target.files[0]);
  // };
  // console.log(profilephone, "immmmmmm");

  // _____________img croprer________
  useEffect(() => {
    setFileList([
      {
        //   uid: "-1",
        //   name: "image.png",
        //   status: "done",
        url: imgprofile
          ? `${PROFILEIMG_URL}${imgprofile}`
          : `https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${profileusername?.[0]?.toUpperCase()}`,
        // url: updateimgprofile
        //   ? `${PROFILEIMG_URL}${updateimgprofile}`
        //   : `${PROFILEIMG_URL}${imgprofile}`,
      },
    ]);
  }, [imgprofile]);
  const [fileList, setFileList] = React.useState([
    {
      //   uid: "-1",
      //   name: "image.png",
      //   status: "done",

      url: updateimgprofile
        ? `${PROFILEIMG_URL}${updateimgprofile}`
        : `${PROFILEIMG_URL}${imgprofile}`,
    },
  ]);
  const onChange = ({ fileList }) => {
    setFileList(fileList);
    setUpdateImgprofile(fileList[0].originFileObj);

    // console.log(fileList[0].originFileObj, "jain");
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  // _____________________img croper ___________________
  //---------- delete profile image---------------//
  const Deleteprofileimage = () => {
    Swal.fire({
      title: `Do you want to delete Image?`,
      // text: "you feel like Great !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        var config = {
          method: "post",
          url: `${Base_URL}updateUserImage/${userId}`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
        };

        axios(config)
          .then(function(response) {
            // console.log(response, "delete image");
            showprofile();
          })
          .catch(function(error) {
            console.log(error);
          });
        Swal.fire("Image Deleted");
      }
    });
  };

  // check confirm password matches password
  const checkConfirnPassword = (confirmPassword) => {
    if (profilepassword !== confirmPassword) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
  };

  return (
    <>
      <div className="profile_edit_background">
        <div>
          <Headers />
        </div>
        <div className="container">
          <div className="profileedit_start">
            <div className="Profile_edit_header">Profile Settings</div>
          </div>
          <div className="Edit_profile_formpage">
            <div
              className="edit_profile_box img_cropper"
              // style={{ backgroundImage: `url(${imgprofile})` }}
            >
              <>
                <div className="edit_profile_img_cropper">
                  <ImgCrop rotate shape="round">
                    <Upload
                      maxCount={1}
                      // action="https://digimonk.co/overwritedev/public/images/communityimgs/"
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 ? (
                        <>
                          <img src={camera} className="w-100" />
                        </>
                      ) : (
                        <span className="edit_icon_profile">{editIcon}</span>
                      )}
                    </Upload>
                  </ImgCrop>
                </div>
                <span
                  className="EditButtonClass_delete"
                  onClick={Deleteprofileimage}
                >
                  {deleteIcon}
                </span>
              </>
              {/* <Button >delete</Button> */}
              {/* {updateimgprofile ? (
                <img
                  src={
                    updateimgprofile
                      ? fileselect
                      : `${PROFILEIMG_URL}${imgprofile}`
                  }
                  alt="img"
                  className="edit_profile_user"
                />
              ) : (
                <>
                  <img src={camera} />{" "}
                </>
              )}
              <span className="editprofile_editicon">
                <input
                  className="edit_profile_input "
                  onChange={fileupload}
                  accept="image/*"
                  type="file"
                />
                <img
                  src={Editicon}
                  alt="editicon"
                  className="edit_icon_profile"
                />
              </span> */}
            </div>
            <div className="edit_profile_form_page ">
              <form>
                <div class="mb-3">
                  <label
                    for="exampleInputText"
                    class="form-label edit_profile_lebal"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    class="form-control edit_profile_input_area"
                    id="exampleInputText"
                    aria-describedby="emailHelp"
                    placeholder="@anderson34"
                    value={profileusername}
                    readOnly
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputtext"
                    class="form-label edit_profile_lebal"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    class="form-control edit_profile_input_area"
                    id="exampleInputtext"
                    placeholder="Anderson smith"
                    defaultValue={profilename}
                    onChange={(e) => setProfilename(e.target.value)}
                  />
                </div>

                <div class="mb-3">
                  <label
                    for="exampleFormControlTextarea1"
                    class="form-label edit_profile_lebal"
                  >
                    Bio
                  </label>
                  <textarea
                    class="form-control edit_profile_input_area"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    defaultValue={profilebio}
                    onChange={(e) => setProfilebio(e.target.value)}
                  ></textarea>
                </div>
                <div className="row">
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control edit_profile_input_area"
                      id="exampleInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Anderson@smith.com"
                      value={profileemail}
                      readOnly
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      class="form-label edit_profile_lebal"
                    >
                      Phone number
                    </label>
                    <input
                      type="number"
                      class="form-control edit_profile_input_area"
                      id="exampleInputnumber"
                      placeholder="+19999999999"
                      defaultValue={profilephone}
                      onChange={(e) => setProfilephone(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      class="form-control edit_profile_input_area"
                      id="exampleInputPassword"
                      aria-describedby="emailHelp"
                      placeholder=""
                      onChange={(e) => setProfilepassword(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      class="form-label edit_profile_lebal"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      class="form-control edit_profile_input_area"
                      id="exampleInputpassword"
                      placeholder=""
                      onChange={(e) => {
                        checkConfirnPassword(e.target.value);
                        setProfileConfirmpassword(e.target.value);
                      }}
                    />
                    {
                      errorPassword && <p style={{color: "red"}}>Confirm Password not matches the password</p>
                    }
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Facebook
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText"
                      aria-describedby="emailHelp"
                      placeholder="www.facebook.com"
                      defaultValue={profilefacebook}
                      onChange={(e) => setProfilefacebook(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      class="form-label edit_profile_lebal"
                    >
                      Twitter
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="www.twitter.com"
                      defaultValue={profiletwitter}
                      onChange={(e) => setProfiletwitter(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputText"
                      class="form-label edit_profile_lebal"
                    >
                      Instagram
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputText"
                      aria-describedby="emailHelp"
                      placeholder="www.instagram.com"
                      defaultValue={profileinstagram}
                      onChange={(e) => setProfileinstagram(e.target.value)}
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label
                      for="exampleInputtext"
                      class="form-label edit_profile_lebal"
                    >
                      Tiktok
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="www.Tiktok.com"
                      defaultValue={profiletiktok}
                      onChange={(e) => setProfiletiktok(e.target.value)}
                    />
                  </div>
                  <div class="mb-5 col-lg-6">
                    <label
                      for="exampleInputtext"
                      class="form-label edit_profile_lebal"
                    >
                      Linkedin
                    </label>
                    <input
                      type="text"
                      class="form-control edit_profile_input_area"
                      id="exampleInputtext"
                      placeholder="www.Linkedin.com"
                      defaultValue={profilelinkedin}
                      onChange={(e) => setProfilelinkedin(e.target.value)}
                    />
                  </div>
                </div>
              </form>
              <div className="text-center">
                <Button
                  className="mb-5 editprofile_savebutton"
                  onClick={updateprofile}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editprofile;
