import React, { useEffect } from "react";
import "../../Assets/Css/dashboard.css";
import { Button, Stack } from "react-bootstrap";
import Noimage from "../../Assets/images/basic.png";
import { Base_URL, COMMUNITYSMALLIMG_URL } from "../../utils/serverUrl";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setIsLoading } from '../../features/preLoaderSlice'

const Recieved = () => {
  const [joinusers, setJoinuser] = React.useState([]);
  const [username, setUserName] = React.useState("");
  const [loadingchap, setLoadingChap] = React.useState(false);
  let token = localStorage.getItem("userToken");
  let isLoggedIn = token.replace(/^"(.+)"$/, "$1");
  let userID = localStorage.getItem("userId");
  const dispatch = useDispatch()
  const recieveduser = () => {
    dispatch(setIsLoading(true))

    var config = {
      method: "post",
      url: `${Base_URL}joinrequest`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
    };

    axios(config)
      .then(function (response) {

        setUserName(response.data.name);
        setJoinuser(response.data.data);
        dispatch(setIsLoading(false))
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const deletereq = (id) => {

    var data = new FormData();
    data.append("id", id);

    var config = {
      method: "post",
      url: `${Base_URL}deletejoinrequest`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
      })
      .catch(function (error) {
        console.log(error);
      });

  };
  const statusreq = (id, req) => {

    Swal.fire({
      title: "Are you sure?",
      text: req == 2 ? "You want to  join this community ?" : " You want to reject this request",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: req == 2 ? "Accept" : " reject",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingChap(true)
        var data = new FormData();
        data.append("id", id);
        data.append("req", req);

        var config = {
          method: "post",
          url: `${Base_URL}statusrequest`,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isLoggedIn,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            recieveduser();

            setLoadingChap(false);
          })
          .catch(function (error) {
            console.log(error);

          });


      }
    });
  };
  useEffect(() => {
    recieveduser();
  }, []);
  return (

    <div className="recieved_start table-responsive">
      {loadingchap ? <>
        <div
          className="sweet-loading loder_loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

          }}
        >
          <PulseLoader
            color="#36d7b7"
            size={100}
            speedMultiplier={2}
            loading={loadingchap}
          />
        </div>
      </> :
        <table cellpadding="0" cellspacing="0" border="0" className="table table-new">
          <thead>
            <tr>
              <th>Date</th>
              <th>Communty name</th>
              <th>creator</th>
              <th>invited as</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {joinusers.map((item) =>
              (item.state == "join" ? (
                Number(item.user_id) == Number(userID)
              ) : (
                Number(item.sent_user_id) == Number(userID)
              )) ? (
                <>
                  <tr>
                    <td className="recieved_date">{moment(item.created_at).format('DD-MM-YYYY')}</td>
                    <td>
                      <div className="recieved_img ">
                        <img
                          src={item.image ? `${COMMUNITYSMALLIMG_URL}${item.image}` : `https://placehold.jp/112/16A34A/ffffff/300x300.png?text=${item.title?.[0]?.toUpperCase()}`}

                          alt=""
                          className="recieved_img_first"
                        />
                        <div>
                          <p className="recieved_img_p_first ">{item.title}</p>
                          <p className="recieved_img_p_second ">
                            @{item.community_handle}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="recieved_table_text">{item.name}</td>
                    {item.role === 2 ? (
                      <>
                        <td className="recieved_table_text">Founder</td>{" "}
                      </>
                    ) : (
                      <td className="recieved_table_text">Member</td>
                    )}

                    <td>
                      <Stack direction="horizontal">
                        <div>
                          {item.status === 1 ? (
                            <>
                              <Button
                                className="recieved_button_forth"
                                onClick={() => statusreq(item.id, 2)}
                              >
                                Accept
                              </Button>
                              <Button
                                className="ms-2 recieved_button_fifth"
                                onClick={() => statusreq(item.id, 3)}
                              >
                                Reject
                              </Button>
                            </>) : item.status === 2 ? (
                              <>
                                <Button
                                  className="recieved_button_forth blurs"
                                  onClick={() => statusreq(item.id, 2)}
                                  disabled
                                >
                                  Accepted
                                </Button><Button
                                  className="ms-2 recieved_button_fifth"
                                  onClick={() => statusreq(item.id, 3)}
                                  disabled
                                >
                                  Leave Community
                                </Button>
                              </>
                            ) : (
                            <>
                              <Button
                                className="recieved_button_fifth"
                                onClick={() => statusreq(item.id, 2)}
                                disabled
                              >
                                Accept
                              </Button><Button
                                className="ms-2 recieved_button_forth blurs "
                                onClick={() => statusreq(item.id, 3)}
                                disabled
                              >
                                Rejected
                              </Button>
                            </>
                          )}
                        </div>

                      </Stack>
                    </td>
                  </tr>
                </>
              ) : null
            )}
          </tbody>

        </table>
      }
    </div>
  );
};

export default Recieved;
