import React from "react";
import "../../Assets/Css/communitydetailpage.css";
import "../../Assets/Css/owevent.css";
import { Container, Stack, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Down, plusIcon, Up } from "../../utils/svg.file";
import ShowChapter from "../../Assets/images/show-chapter.png";
import { Link, useParams } from "react-router-dom";
import { Base_URL, CHAPTERIMG_URL } from "../../utils/serverUrl";
import axios from "axios";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Card } from "react-bootstrap";

const Community6 = ({ isSubmitting, pendingchapters, refreshList, edit_type, showJoinUserStatus, showChkjoinUser, ischapter }) => {
  let act_id = localStorage?.getItem("act_id");
  let community_id = localStorage?.getItem("community_id");
  let token = localStorage.getItem("userToken");
  let user_id = localStorage.getItem("userId");
  let isLoggedIn = token?.replace(/^"(.+)"$/, "$1");
  // const [pendingchapters, setPendingchapter] = React.useState([]);
  const [username, setUsername] = React.useState("");
  const [counterset, setCounter] = React.useState(0);
  const [upVote, setUpVote] = React.useState([]);
  const [downVote, setDownVote] = React.useState([]);
  const [isUpVote, setIsUpVote] = React.useState(false);
  const [isDownVote, setIsDownVote] = React.useState(false);

  const params = useParams();

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowBackIcon />
        </button>
      </div>
    );
  };
  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`custom-arrow ${className}`} onClick={onClick}>
        <button>
          <ArrowForwardIcon />
        </button>
      </div>
    );
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const counter = (param, params_down, id) => {
    var data = new FormData();
    data.append("community_id", community_id);
    data.append("act_id", act_id);
    data.append("status", param);
    data.append("chapter_id", id);
    data.append("counter_down", params_down);
    var config = {
      method: "post",
      url: `${Base_URL}update_chapter_counter`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        showcount();
      })
      .catch(function (error) {
        console.log(error);
      });

    setCounter(param);
  };

  const showcount = () => {
    var data = new FormData();
    data.append("act_id", act_id);

    var config = {
      method: "post",
      url: `${Base_URL}show_chapter_count`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        setUpVote(response.data.data.result_up);
        setDownVote(response.data.data.result_down);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    // pendingchapter();
    showcount();
  }, [isSubmitting]);

  const checkFollowedUser = (userList) => {
    return userList?.some(
      (el) => Number(el.user_id) === Number(user_id)
    )
  };



  const Followchapter = (id, status) => {


    var data = new FormData();
    data.append("chapter_id", id);
    data.append("user_id", user_id);
    data.append("community_id", community_id);
    data.append("status", status ? 1 : 2);

    var config = {
      method: "post",
      url: `${Base_URL}followchapter`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + isLoggedIn,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        refreshList();

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* <Container>
        {pendingchapters ? (
          <>
            {pendingchapters.map((item, index) => (
              <div className="owevent_back_last mb-4" key={index}>
                <Row>
                  <Col lg={4} md={6}>
                    <div>
                      <Link to={`/owevent/${item.id}`} state={{ editType: edit_type, showChkjoinUser: showChkjoinUser }} className="color-none">
                        <img
                          src={`${CHAPTERIMG_URL}${item.images}`}
                          className="community_5_img"
                        />
                      
                      </Link>
                    </div>
                  </Col>
                  <Col lg={8} md={6}>
                    <Stack
                      direction="horizontal"
                      className="align-items-baseline"
                    >
                      <h1 className="event_ow_head">
                        {item.chapter_name}
                        <p className="event_ow_sub_head"> @{item.user}</p>
                      </h1>

                    </Stack>
                    <span className="event_ow_text">{item.description}</span>
                  </Col>
                </Row>

              </div>
            ))}
          </>
        ) : null}
      </Container> */}
      <Slider {...settings}>
        {pendingchapters ? (
          <div>
            {pendingchapters.map((item, index) => (

              <Card
                // className="slidercard_card"
                // className={isCommunity == item.id && props.isCommunity == true ? "select_act_border" : "slidercard_card"}
                // "slidercard_card"
                key={index}
                onClick={() => {
                  // props.onClick(item.id)
                  // setIsCommunity(item.id)

                }}
              >
                <Card.Img
                  variant="top"
                  className="slider_card_img"
                  src={`${CHAPTERIMG_URL}${item.images}`}
                />
                <Card.Body>
                  <Card.Title className="slider_card_title">
                    {/* Silverfrog195 */}
                    {item.chapter_name}
                    <p> @{item.user}</p>
                  </Card.Title>
                  {/* <Card.Text className="slider_card_text">
                    @{item.user}

                  </Card.Text> */}
                  <Card.Text className="slider_card_text">
                    {item.description}

                  </Card.Text>
                  <Button variant="primary" className="slick_slide_btn">
                    {/* {item.count_act}  */}
                    Events
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : null}
      </Slider>
    </>
  );
};

export default Community6;
