import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUp from "./Components/Auth/SignUp";
import SignIn from "./Components/Auth/SignIn";
import Chat from "./Components/Chat/Chat";
import Layout from "./admin/Layout";
import Profile from "./Components/Chat/Profile";
import Editprofile from "./Components/Notification/Editprofile";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import SearchPage from "./Components/SearchPage";
import MyCommunities from "./Components/Dashboard/MyCommunities";
import CommunityDetailPage from "./Components/Community-Detail-Page/CommunityDetailPage";
import Invits from "./Components/Invits";
import CreateEdit from "./Components/create-edit-communit";
import OwEvent from "./Components/OwEvent";
import ShowChapter from "./Components/ShowChapter";
import PendingEvent from "./Components/PendingEvent";
import PendingEventEdit from "./Components/PendingEventEdit";
import EventsRemoval from "./Components/EventsRemoval";
import ViewChapter from "./Components/Chapters/ViewChapter";
import AddModal from "./Components/Chapters/AddEvents";
import Community3 from "./Components/Community-Detail-Page/Community3";
import Community4 from "./Components/Community-Detail-Page/Community4";
import Community5 from "./Components/Community-Detail-Page/Community5";
import Community6 from "./Components/Community-Detail-Page/Community6";
import Community7 from "./Components/Community-Detail-Page/Community7";
import Community8 from "./Components/Community-Detail-Page/Community8";
import Home from "./Components/home/Home";
import Login from "./admin/Login";
import Admin from "./admin/Admin";
import ChapterAdd from "./Components/create-edit-communit/modal/add-chapter";
import Userprofile from "./Components/Chat/Userprofile";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Assets/Css/responsive.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import About from "./Components/about";
import Contact from "./Components/contact";
import CommunityView from "./Components/communityView";
import socketIo from "socket.io-client";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Preview from "./Components/create-edit-communit/Preview";
import ProtectedRoutes from "./utils/services/protectedRoutes";
import Forgotpassword from "./Components/Auth/Forgotpassword";
import Otpverification from "./Components/Auth/Otpverification";
import Changepassword from "./Components/Auth/Changepassword";
import UserProtectedRoutes from "./utils/services/UserProtectedRoutes";

function App() {
  let userId = localStorage.getItem("userId");

  // redux selector
  const isLoading = useSelector((state) => state.isLoadingReducer.isLoading);

  const socket = socketIo("https://api.ovrwt.xyz:1921", {
    transports: ["websocket"],
  });
  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket connected!!! in app js", socket);
      if (userId) socket.emit("updateuseronline", userId);
    });
    socket.on("connect_error", (err) => {
      console.log("socket connect failed!!", err.message);
      socket.connect();
    });
  }, []);
  return (
    <div className="App">
      {isLoading && (
        <div className="Header_loader">
          <div class="progress">
            <div class="progress-value"></div>
          </div>
        </div>
      )}
      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path="/admin" element={<Admin />} /> */}
        <Route path="/chapteradd" element={<ChapterAdd />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/otpverification" element={<Otpverification />} />
        <Route path="/changepassword" element={<Changepassword />} />
        <Route
          path="/admin-dashboard/*"
          element={
            <ProtectedRoutes>
              <Layout />
            </ProtectedRoutes>
          }
        />
        {/* <Route path="/*" element={<h1>"page not found"</h1>} /> */}
        <Route
          path="/chat"
          exact
          element={
            <UserProtectedRoutes>
              <Chat />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/chat/:id"
          exact
          element={
            <UserProtectedRoutes>
              <Chat />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/userprofile/:id"
          element={
            <UserProtectedRoutes>
              <Userprofile />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <UserProtectedRoutes>
              <Profile />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/editprofile/:id"
          element={
            <UserProtectedRoutes>
              <Editprofile />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/dashboard"
          element={
            <UserProtectedRoutes>
              <Dashboard />
            </UserProtectedRoutes>
          }
        />
        <Route path="/search/:id" element={<SearchPage />} />
        <Route path="/mycommunities" element={<MyCommunities />} />
        <Route
          path="/preview"
          element={
            <UserProtectedRoutes>
              <Preview />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/owevent"
          element={
            <UserProtectedRoutes>
              <OwEvent />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/showchapter"
          element={
            <UserProtectedRoutes>
              <ShowChapter />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/viewchapter"
          element={
            <UserProtectedRoutes>
              <ViewChapter />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/pendingevent"
          element={
            <UserProtectedRoutes>
              <PendingEvent />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/pendingeventedit"
          element={
            <UserProtectedRoutes>
              <PendingEventEdit />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/eventsremoval"
          element={
            <UserProtectedRoutes>
              <EventsRemoval />
            </UserProtectedRoutes>
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/community-details/:id"
          element={<CommunityDetailPage />}
        />
        <Route
          path="/edit-community-details/:id/:type"
          element={
            <UserProtectedRoutes>
              <CommunityDetailPage />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/my-communities"
          element={
            <UserProtectedRoutes>
              <MyCommunities />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/invits"
          element={
            <UserProtectedRoutes>
              <Invits />
            </UserProtectedRoutes>
          }
        />
        <Route path="/create-communities" element={<CreateEdit />} />
        <Route
          path="/owevent/:id"
          element={
            <UserProtectedRoutes>
              <OwEvent />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/addevents"
          element={
            <UserProtectedRoutes>
              <AddModal />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community3"
          element={
            <UserProtectedRoutes>
              <Community3 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community4"
          element={
            <UserProtectedRoutes>
              <Community4 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community5"
          element={
            <UserProtectedRoutes>
              <Community5 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community6"
          element={
            <UserProtectedRoutes>
              <Community6 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community7"
          element={
            <UserProtectedRoutes>
              <Community7 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/community8"
          element={
            <UserProtectedRoutes>
              <Community8 />
            </UserProtectedRoutes>
          }
        />
        <Route
          path="/viewall"
          element={
            <UserProtectedRoutes>
              <CommunityView />
            </UserProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
